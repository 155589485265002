import { ForwardedRef, forwardRef } from "react";
import { Link } from "react-router-dom";

import { useGetDirectionsLongNameQuery } from "../../../../entities/Direction";
import { searchQueryURL } from "../../../../shared/lib/helpers";
import { RoutesEnum } from "../../../../shared/constants/routes";
import { LongNameDto } from "../../../../shared/ApiContract/data-contracts";
import { classNames } from "../../../../shared/lib/classNames";
import { ChevronDownS } from "../../../../shared/assets/svg";
import { Theme } from "../../types/theme";

import styles from "./HeaderDropdown.module.scss";

interface OptionsDropdownProps {
    isHover: boolean;
    directionList: LongNameDto[];
}

interface ButtonDropDown {
    className?: string;

    theme: Theme;
    isHover: boolean;
}

export const ButtonDropdown = forwardRef((props: ButtonDropDown, ref: ForwardedRef<HTMLButtonElement>) => {
    const { theme, isHover, className } = props;
    const { data: directionList, isLoading } = useGetDirectionsLongNameQuery();

    return (
        <button
            disabled={isLoading}
            ref={ref}
            className={classNames({ cls: styles.buttonDropdown, additional: [className] })}
        >
            <span>Направления</span>
            <ChevronDownS
                className={classNames({
                    cls: styles.buttonDropdown__arrowDown,
                    additional: [styles[`buttonDropdown__arrowDown_${theme}`]],
                })}
            />
            {directionList && <OptionsDropdown directionList={directionList} isHover={isHover} />}
        </button>
    );
});

function OptionsDropdown(props: Readonly<OptionsDropdownProps>) {
    const { isHover, directionList } = props;
    if (!isHover) return null;

    return (
        <div className={styles.dropdown}>
            <div>
                <ul className={styles.dropdown__options}>
                    {directionList.map((direct) => (
                        <li key={direct.id}>
                            <Link
                                className={styles.dropdown__item}
                                to={searchQueryURL(RoutesEnum.Direction, { team: direct.id })}
                            >
                                <span>{direct.longName}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
