import { Container, Title } from "../../shared/ui";

import { IOurOffices, ourOffices } from "./const/ourOffices";

import styles from "./OurOffices.module.scss";

const Offices = ({ office }: { office: IOurOffices }) => {
    return (
        <div className={styles.ourOffices__country}>
            <div className={styles.ourOffices__wrapperTitle}>
                <Title level={3}>
                    {office.country} <span className={styles.ourOffices__count}>{office.countOffices}</span>
                </Title>
                {office.description && <p>{office.description}</p>}
            </div>
            {Boolean(office.offices.length) && (
                <ul>
                    {office.offices.map((office) => (
                        <li key={office}>{office}</li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export const OurOffices = () => {
    return (
        <Container marginSize="large" className={styles.ourOffices}>
            <p className={styles.ourOffices__text}>Наши офисы</p>
            <div className={styles.ourOffices__countryWrapper}>
                {ourOffices.map((item) => (
                    <Offices key={item.id} office={item} />
                ))}
            </div>
        </Container>
    );
};
