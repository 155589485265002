import { SearchParamsVacancyEnum } from "../../../shared/constants/searchParams";

type SearchVacancy = {
    [key in SearchParamsVacancyEnum]: string | null;
};

export const searchVacancy = (searchParams: URLSearchParams) => {
    const defaultSearchVacancy = {} as SearchVacancy;

    Object.values(SearchParamsVacancyEnum).forEach((param) => {
        defaultSearchVacancy[param] = searchParams.get(param) ?? null;
    });

    return defaultSearchVacancy;
};
