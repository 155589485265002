import {
    BlogsCarousel,
    CompanyFacts,
    CompanyAwards,
    CollectionJobs,
    HomeMain,
    JoinWork,
    BannerGetSettled,
} from "../../widget";

import { getRouteBlogs } from "../../shared/constants/routes";

export const HomePage = () => {
    return (
        <>
            <HomeMain />
            <CollectionJobs />
            <CompanyAwards />
            <CompanyFacts />
            {/* NOTE BLOG Блоги скрыты по причине не хватки контента*/}
            {/*<BlogsCarousel*/}
            {/*    title={"Внутри БКС"}*/}
            {/*    description={"Duis aute irure dolor in reprehenderit in voluptate velit"}*/}
            {/*    nameButton={"Узнайте больше"}*/}
            {/*    to={getRouteBlogs()}*/}
            {/*/>*/}
            <JoinWork />
            <BannerGetSettled />
        </>
    );
};
