import { Container, Title, Button, SliceBorder } from "../../shared/ui";

import { ArrowRightNoBorder } from "../../shared/assets/svg/index";
import { imgAwardsBackground, imgTrophy } from "../../shared/assets/img/index";
import { linkAwards } from "./const/awards";
import { useMediaQuery } from "../../shared/hooks/useMediaQuery";
import { getRouteAbout } from "../../shared/constants/routes";
import { generateMediaQuery } from "../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../shared/constants/media";

import styles from "./CompanyAwards.module.scss";

export const CompanyAwards = () => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.md));

    return (
        <Container marginSize="large" className={styles.sectionAwards}>
            <div className={styles.sectionAwards__text}>
                <Title level={3}>
                    Вместе
                    <br />к вершинам
                </Title>
                <p className={styles.sectionAwards__desc}>
                    Мы работаем не ради наград. Но когда нас признают лучшими, это вдохновляет каждого.{" "}
                </p>
            </div>

            <div className={styles.sectionAwards__listWrapper}>
                <ul className={styles.sectionAwards__list}>
                    {linkAwards.map((award) => (
                        <li key={award.id}>
                            <a
                                target="_blank"
                                className={styles.sectionAwards__item}
                                href={award.link}
                                rel="noreferrer"
                            >
                                <p>{award.description}</p>
                                <ArrowRightNoBorder />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.sectionAwards__buttonWrapper}>
                {!matches && (
                    <SliceBorder
                        sizeRadius={28}
                        className={styles.sectionAwards__buttonBorder}
                        border={{
                            topRight: "outside-corner-upper",
                            topLeft: "inside",
                            bottomLeft: "outside-corner-lateral",
                        }}
                    >
                        <Button as={"link"} theme="blue" to={getRouteAbout()}>
                            Подробнее о компании
                        </Button>
                    </SliceBorder>
                )}
            </div>
            <div className={styles.sectionAwards__backgroundWrapper}>
                <img className={styles.sectionAwards__background} src={imgAwardsBackground} alt={"imgMainBackground"} />
                <img className={styles.sectionAwards__trophy} src={imgTrophy} alt={"imgMainBackground"} />
            </div>

            {matches && (
                <Button as={"link"} className={styles.sectionAwards__button} theme="blue" to={getRouteAbout()}>
                    Подробнее о компании
                </Button>
            )}
        </Container>
    );
};
