export enum MediaQueryProperty {
    MaxWidth = "max-width",
    Width = "width",
    MinWidth = "min-width",
}

export enum MediaSizeEnum {
    xxl = "1920px",
    xl = "1240px",
    lg = "1024px",
    md = "768px",
    sm = "468px",
}
