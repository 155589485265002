import React from "react";
import { LogoVacancy } from "../../shared/assets/svg";
import { vacancyBanner } from "../../shared/assets/img";

import styles from "./VacancyMain.module.scss";

export const VacancyBanner = () => {
    return (
        <div className={styles.banner}>
            <div className={styles.banner__left}>
                <LogoVacancy /> <h3>Работа в команде профессионалов</h3>
            </div>
            <div className={styles.banner__right}>
                <img src={vacancyBanner} alt="Вакансия" />{" "}
            </div>
        </div>
    );
};
