import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Control, useController } from "react-hook-form";
import { FileUpload } from "../../../../shared/ui";

import { useUploadFileMutation } from "../../api/applicantFormApi";
import { IFrom } from "../../types/Form";

interface UploadFileFormProps {
    className?: string;
    control: Control<IFrom>;
}
export const UploadFileForm = ({ control, className }: UploadFileFormProps) => {
    const [uploadFile, { data: file, isLoading, isSuccess, isError }] = useUploadFileMutation();
    const {
        field: { onChange },
        formState: { errors },
    } = useController({ name: "attachmentId", control });

    const fileUploadChange = (file: File) => {
        uploadFile({ AttachmentId: uuidv4(), File: file });
    };

    useEffect(() => {
        if (isSuccess && file?.data) {
            onChange(file.data.attachmentId);
        }
    }, [isSuccess]);

    return (
        <FileUpload
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            className={className}
            errorMessage={errors.attachmentId?.message}
            accept=".docx, .pdf"
            onFileChange={fileUploadChange}
        />
    );
};
