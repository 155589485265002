import { classNames } from "../../../../../shared/lib/classNames";
import styles from "./PersonalDataText.module.scss";

interface PersonalDataTextProps {
    className?: string;
}
export const PersonalDataText = ({ className }: PersonalDataTextProps) => {
    return (
        <div className={classNames({ cls: styles.personalDataText, additional: [className] })}>
            <p>
                Я, Субъект персональных данных, нажимая галочку напротив «Даю согласие на обработку персональных данных»
                перед направлением Оператору Анкеты и документов при устройстве на работу, через карьерный сайт
                bcs.career и/или любым иным способом, подтверждаю, что не являюсь работником Оператора, а также
                соглашаюсь:
            </p>
            <br />
            <p>
                - на обработку моих персональных данных в соответствии с условиями и способами обработки, изложенными в
                настоящем согласии;
            </p>
            <br />

            <p>- на внесение моих персональных данных в кадровый резерв Оператора;</p>
            <br />
            <p>
                - с условиями ведения кадрового резерва Оператором, сроком хранения моих персональных данных, а также
                порядком исключения меня и моих персональных данных из кадрового резерва, изложенными в разделе 6
                настоящего согласия;
            </p>
            <br />
            <p>
                - с правилами предоставления персональных данных третьих лиц, указанных в разделе 8 настоящего согласия;
            </p>
            <br />
            <p className={styles.personalDataText__title}>1. Оператор персональных данных</p>
            <br />
            <p>
                Компании, входящие в группу БКС, наименования и адреса которых указаны в Приложении 1, к настоящему
                согласию (далее — «Оператор»).
            </p>
            <br />
            <p className={styles.personalDataText__title}>2. Цель обработки персональных данных</p>
            <br />
            <p>
                Оператор вправе обрабатывать персональные данные для цели оценки Субъекта персональных данных в качестве
                кандидата на открытую Оператором вакантную позицию и рассмотрения возможности дальнейшего
                трудоустройства Субъекта персональных данных у Оператора.
            </p>
            <br />
            <p>
                Достижение указанной цели обработки персональных данных Оператором предполагает организацию следующих
                процессов и выполнение следующих задач:
            </p>
            <br />
            <p>2.1. Содействие в трудоустройстве.</p>
            <br />
            <p>
                2.2. Оценку профессиональных знаний и навыков Субъекта персональных данных, тестирование субъекта
                персональных данных.
            </p>
            <br />
            <p>2.3. Принятие решения о трудоустройстве.</p>
            <br />
            <p>2.4. Формирование кадрового резерва.</p>
            <br />
            <p>2.5. Ведение кадрового и воинского учета в случае принятия положительного решения о трудоустройстве.</p>
            <br />
            <p>
                2.6. Оформление трудового договора и совершение иных формальностей при оформлении трудовых отношений в
                случае принятия положительного решения о трудоустройстве.
            </p>
            <br />
            <p>
                2.7. Соблюдение требований применимого законодательства, включая трудовое законодательство, Гражданский
                кодекс РФ, Налоговый кодекс РФ, миграционное законодательство, и иных правил, инструкций, нормативных
                актов и процедур, внутренних нормативных актов Оператора, а также для оценки такого соблюдения,
                предотвращение конфликта интересов.
            </p>
            <br />
            <p>2.8. Получение рекомендаций от предыдущих работодателей.</p>
            <br />
            <p>
                2.9. Проверку и подтверждение достоверности данных о профессиональном опыте и трудовом стаже,
                представленных Субъектом персональных данных.
            </p>
            <br />
            <p>
                2.10. Иные законные задачи и процессы, осуществление которых совместимо с указанной целью обработки
                персональных данных.
            </p>
            <br />
            <p className={styles.personalDataText__title}>
                3. Субъект персональных данных настоящим дает согласие на обработку перечисленных ниже персональных
                данных:
            </p>
            <br />
            <p>3.1. Фамилия, имя, отчество (Ф.И.О.), сведения о прежних Ф.И.О., если применимо.</p>
            <br />
            <p>
                3.2. Контактные данные, в том числе электронная почта , номер мобильного телефона, логин в мессенджерах
            </p>
            <br />
            <p>3.3. Сведения о дате рождения.</p>
            <br />
            <p>3.4. Пол.</p>
            <br />
            <p>3.5. Сведения о месте рождения.</p>
            <br />
            <p>3.6. Сведения об адресе (регистрации и фактического проживания)</p>
            <br />
            <p>3.7. Сведения о семейном положении.</p>
            <br />
            <p>3.8. Сведения о составе семьи.</p>
            <br />
            <p>
                3.9.1. Данные документов миграционного учета, и подтверждающих разрешение на проживание/работу в РФ для
                иностранных граждан (патент, РВП, виза, ВНЖ, миграционная карта и т.п._
            </p>
            <br />
            <p>3.10. Данные военного билета.</p>
            <br />
            <p>3.11. Данные СНИЛС.</p>
            <br />
            <p>3.12. Данные ИНН.</p>
            <br />
            <p>3.13. Сведения об образовании.</p>
            <br />
            <p>3.14. Сведения о дополнительном образовании.</p>
            <br />
            <p>3.15. Сведения о профессиональных и дополнительных навыках.</p>
            <br />
            <p>3.16. Сведения о трудовой деятельности.</p>
            <br />
            <p>3.17. Сведения о результатах тестирования.</p>
            <br />
            <p>3.18. Сведения о предпочтениях, увлечениях, заслугах и достижениях (при наличии данных в резюме) .</p>
            <br />
            <p>
                3.19. Сведения о принадлежности к льготной категории (влекущей необходимость предоставления особых
                условий труда)
            </p>
            <br />
            <p>3.20. Сведения о наличии рекомендаций</p>
            <br />
            <p>3.21. Сведения о гражданстве</p>
            <br />
            <p>3.22. Сведения о зарплатных ожиданиях</p>
            <br />
            <p>3.23. Резюме и\или портфолио кандидата</p>
            <br />
            <p>3.24. Фото</p>
            <br />
            <p>
                3.25. Иные данные, предоставленные Оператору в анкете, для достижения цели обработки персональных данных
            </p>
            <br />
            <p className={styles.personalDataText__title}>
                4. Действия Оператора с персональными данными и способы обработки
            </p>
            <br />
            <p>
                Субъект персональных данных настоящим дает согласие на совершение следующих действий (операций) с
                персональными данными (как с использованием средств автоматизации, так и без них): сбор, запись,
                систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
                передачу (предоставление, доступ), блокирование, удаление, уничтожение.
            </p>
            <br />
            <p className={styles.personalDataText__title}>5. Передача персональных данных</p>
            <br />
            <p>
                5.1. Когда это необходимо для достижения цели обработки персональных данных, указанные в Приложении 1 к
                настоящему согласию Операторы (ведущие прием на работу в компании группы БКС вправе передавать
                персональные данные, обрабатываемые на основании Согласия, друг другу, а также государственным и
                судебным органам.
            </p>
            <br />
            <p className={styles.personalDataText__title}>
                6. Кадровый резерв, условия ведения, срок хранения, порядок исключения
            </p>
            <br />
            <p>
                6.1 Включение в кадровый резерв осуществляется при условии предоставления субъектом персональных данных
                согласия.
            </p>
            <br />
            <p>
                6.2. Оператор обеспечивает включение субъекта персональных данных в кадровый резерв, систематизацию,
                надлежащее хранение анкет (резюме) лиц, включенных в кадровый резерв, и их исключение из него.
            </p>
            <br />
            <p>6.3. Основаниями исключения из кадрового резерва являются:</p>
            <br />
            <p>- решение руководителя Оператора;</p>
            <br />
            <p>- личное заявление лица, состоящего в кадровом резерве;</p>
            <br />
            <p>- отзыв субъектом персональных данных соответствующего согласия на обработку персональных данных.</p>
            <br />
            <p className={styles.personalDataText__title}>7. Срок и порядок отзыва Согласия</p>
            <br />
            <p>
                7.1. Данные в кадровом резерве хранятся до ликвидации Оператора, если не будут исключены в соответствии
                с п.6.3. настоящего документа.
            </p>
            <br />
            <p>
                7.2. Данное согласие действует до момента его отзыва субъектом персональных данных в порядке,
                установленном п.7.3.
            </p>
            <br />
            <p>
                7.3. Данное Согласие может быть отозвано в любой момент путем направления запроса на отзыв согласия на
                обработку персональных данных Оператору на электронный адрес job@bcs.ru или на почтовый адрес любого из
                Операторов.
            </p>
            <br />
            <p>
                7.4. При этом по достижении цели обработки персональных данных или после отзыва Согласия Оператор может
                продолжить обработку персональных данных при наличии иных законных оснований.
            </p>
            <br />
            <p className={styles.personalDataText__title}>8. Предоставление данных третьих лиц</p>
            <br />
            <p>
                8.1. В случае, если для цели, указанной в п.2 настоящего согласия, субъект персональных данных
                предоставляет Оператору данные третьих лиц, он гарантирует, что получил согласие таких лиц на передачу и
                обработку их персональных данных со стороны Операторов на условиях, описанных в настоящем согласии.
            </p>
            <br />
            <p>
                8.2. В случае претензий таких лиц к Операторам по факту неправомерной обработки персональных данных,
                субъект обязуется урегулировать такие претензии самостоятельно и за свой счет. Кроме того, субъект
                уполномочивает Операторов предоставлять третьем лицу по его запросу информацию об источнике получения
                его персональных данных (включая его фамилию, имя, отчество и контактные данные).
            </p>
            <br />
            <p>Приложение 1 к Согласию на обработку персональных данных</p>
            <br />
            <p>Наименование и адрес Операторов персональных данных, ведущих прием кандидатов на работу</p>

            <p className={styles.personalDataText__table}>
                АО "БКС Управление благосостоянием", находящееся по адресу: 129110, ГОРОД МОСКВА, МИРА ПРОСПЕКТ, ДОМ 69,
                СТРОЕНИЕ 1
            </p>
            <p className={styles.personalDataText__table}>
                ООО "БКС - Форекс", находящееся по адресу: 129110, ГОРОД МОСКВА, МИРА ПРОСПЕКТ, ДОМ 69, СТРОЕНИЕ 1
            </p>
            <p className={styles.personalDataText__table}>
                ООО "Консультационный Центр БКС", находящееся по адресу: 129110, ГОРОД МОСКВА, МИРА ПРОСПЕКТ, ДОМ 69,
                СТРОЕНИЕ 1
            </p>
            <p className={styles.personalDataText__table}>
                ООО "ЦНИИРЭС", находящееся по адресу 129110, ГОРОД МОСКВА, ПРОСПЕКТ МИРА, 69, 1
            </p>
            <p className={styles.personalDataText__table}>
                {" "}
                ООО СК «БКС Страхование жизни», находящееся по адресу: 129110, ГОРОД МОСКВА, МИРА ПРОСПЕКТ, ДОМ 69,
                СТРОЕНИЕ 1
            </p>
            <p className={styles.personalDataText__table}>
                ООО "Юрейжн Партнерс", находящееся по адресу: 129110, РОССИЯ, Г. МОСКВА, МИРА ПР-КТ, Д. 69, СТР. 1
            </p>
            <p className={styles.personalDataText__table}>
                ООО "БКС Консалтинг", находящееся по адресу: 630099, НОВОСИБИРСКАЯ ОБЛАСТЬ, НОВОСИБИРСК ГОРОД, СОВЕТСКАЯ
                УЛИЦА, ДОМ 37
            </p>
            <p className={styles.personalDataText__table}>
                ООО "БКС Финтех", находящееся по адресу: 129110, РОССИЯ, Г. МОСКВА, Н.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ
                МЕЩАНСКИЙ, МИРА ПР-КТ, Д. 69, СТР. 1,
            </p>
            <p className={styles.personalDataText__table}>
                ООО "БКС-Технологии" находящееся по адресу: 630099, НОВОСИБИРСКАЯ ОБЛАСТЬ, НОВОСИБИРСК ГОРОД, СОВЕТСКАЯ
                УЛИЦА, 37
            </p>
            <p className={styles.personalDataText__table}>
                АО УК "БКС" находящееся по адресу: 630099, НОВОСИБИРСКАЯ ОБЛАСТЬ, НОВОСИБИРСК ГОРОД, СОВЕТСКАЯ УЛИЦА, 37
            </p>
            <p className={styles.personalDataText__table}>
                АО "БКС Банк" находящееся по адресу:129110, ГОРОД МОСКВА, МИРА ПРОСПЕКТ, ДОМ 69, СТР. 1
            </p>
            <p className={styles.personalDataText__table}>
                ООО "Компания БКС", находящееся по адресу: 630099, НОВОСИБИРСКАЯ ОБЛ., ГОРОД НОВОСИБИРСК Г.О.,
                НОВОСИБИРСК Г., НОВОСИБИРСК Г., СОВЕТСКАЯ УЛ., Д. 37
            </p>
            <p
                className={classNames({
                    cls: styles.personalDataText__table,
                    additional: [styles.personalDataText__table_end],
                })}
            >
                ООО "БКС Холдинг", находящееся по адресу: ГОРОД НОВОСИБИРСК Г.О., НОВОСИБИРСК Г., НОВОСИБИРСК Г.,
                СОВЕТСКАЯ УЛ., Д. 37
            </p>
        </div>
    );
};
