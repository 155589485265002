import { formFetch } from "../../../../shared/api/api";
import { createAppAsyncThunk } from "../../../../shared/lib/createAppAsyncThunk";
import { FileForm } from "../../types/types";

export const filesListAsync = createAppAsyncThunk<FileForm[], string>(
    "files/filesListAsync",
    async (formId, thunkAPI) => {
        try {
            const response = await formFetch.formListOfFilesDetail(formId);

            if (response.ok && !response.data.errorCode) {
                return (response.data.data as unknown as FileForm[]) ?? [];
            } else {
                return thunkAPI.rejectWithValue("error");
            }
        } catch (e) {
            return thunkAPI.rejectWithValue("error");
        }
    },
);
