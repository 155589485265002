import { classNames } from "../../../shared/lib/classNames";
import cls from "./StageItem.module.scss";
import { ReactNode } from "react";

interface StageItemProps {
    number?: string;
    time?: string;
    name?: string;
    children?: ReactNode;
}

export const StageItem = ({ number, children, name, time }: StageItemProps) => {
    return (
        <div className={cls.stageItem}>
            <div className={cls.stageItem_count}>
                <div
                    className={classNames({
                        cls: cls.stageItem_count__left,
                        mods: {
                            [cls.stageItem_count__first]: number === "1",
                            [cls.stageItem_count__second]: number === "2",
                            [cls.stageItem_count__thirdLeft]: number === "3",
                        },
                    })}
                />
                <div
                    className={classNames({
                        cls: cls.stageItem_count__right,
                        mods: {
                            [cls.stageItem_count__thirdRight]: number === "3",
                            [cls.stageItem_count__fourth]: number === "4",
                        },
                    })}
                />
                <div className={cls.stageItem_count__text}>{number}</div>
            </div>
            <div className={cls.stageItem_count__title}>
                {name}
            </div>
            {children}
        </div>
    );
};
