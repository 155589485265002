import { Provider } from "react-redux";
import { type ReactNode } from "react";
import { setupStore } from "./store";
import { type StateSchema } from "./StateSchema";

interface StoreProviderProps {
    children: ReactNode;
    initialState?: StateSchema;
}

export const StoreProvider = ({ children, initialState }: StoreProviderProps) => {
    const store = setupStore(initialState);

    return <Provider store={store}>{children}</Provider>;
};
