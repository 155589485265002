import { createBrowserRouter } from "react-router-dom";
import {
    AboutPage,
    AboutRecruitingPage,
    DirectionPage,
    HomePage,
    NotFoundPage,
    EarlyCareerPage,
    VacanciesPage,
    VacancyPage,
    BlogsPage,
    ArticlePage,
    VacancyRespondPage,
    DocumentPage,
    CandidatePage,
    SdPage,
    AcceptFormPage,
    FormCandidatePageAsync,
} from "../../pages";
import { Layout } from "../../widget";

import { RoutesEnum } from "../../shared/constants/routes";

export const router = createBrowserRouter([
    {
        id: "root",
        path: RoutesEnum.Home,
        Component: Layout,
        children: [
            {
                index: true,
                Component: HomePage,
            },
            {
                path: RoutesEnum.Direction,
                Component: DirectionPage,
            },
            {
                path: RoutesEnum.EarlyCareer,
                Component: EarlyCareerPage,
            },
            {
                path: RoutesEnum.About,
                Component: AboutPage,
            },
            {
                path: RoutesEnum.Vacancies,
                Component: VacanciesPage,
            },
            {
                path: RoutesEnum.Vacancy + "/:id",
                Component: VacancyPage,
            },
            {
                path: RoutesEnum.Vacancy + "/:id/" + RoutesEnum.VacancyRespond,
                Component: VacancyRespondPage,
            },
            {
                path: RoutesEnum.AcceptForm,
                Component: AcceptFormPage,
            },
            // NOTE BLOG Блоги скрыты по причине не хватки контента
            // {
            //     path: RoutesEnum.Blogs,
            //     Component: BlogsPage,
            // },
            // {
            //     path: RoutesEnum.Article + "/:id",
            //     Component: ArticlePage,
            // },
            { path: RoutesEnum.NotFound, Component: NotFoundPage },
        ],
    },
    {
        id: "green",
        path: RoutesEnum.EarlyCareer,
        element: <Layout theme={"green"} />,
        children: [
            {
                index: true,
                Component: EarlyCareerPage,
            },
        ],
    },
    {
        id: "AboutRecruitingPage",
        path: RoutesEnum.AboutRecruiting,
        element: <Layout theme={"gray"} />,
        children: [
            {
                index: true,
                Component: AboutRecruitingPage,
            },
        ],
    },
    { path: RoutesEnum.FormCandidate, Component: FormCandidatePageAsync },
    { path: RoutesEnum.CandidatesSibproject, Component: CandidatePage },
    { path: RoutesEnum.DocumentSibproject, Component: DocumentPage },
    { path: RoutesEnum.CandidatesBroker, Component: CandidatePage },
    { path: RoutesEnum.DocumentBroker, Component: DocumentPage },
    { path: RoutesEnum.CandidatesIb, Component: CandidatePage },
    { path: RoutesEnum.DocumentIb, Component: DocumentPage },
    { path: RoutesEnum.CandidatesRetail, Component: CandidatePage },
    { path: RoutesEnum.DocumentRetail, Component: DocumentPage },
    { path: RoutesEnum.CandidatesUkfond, Component: CandidatePage },
    { path: RoutesEnum.DocumentUkfond, Component: DocumentPage },
    { path: RoutesEnum.CandidatesBank, Component: CandidatePage },
    { path: RoutesEnum.DocumentBank, Component: DocumentPage },
    { path: RoutesEnum.CandidatesWealthy, Component: CandidatePage },
    { path: RoutesEnum.DocumentWealthy, Component: DocumentPage },
    { path: RoutesEnum.CandidatesUkbcs, Component: CandidatePage },
    { path: RoutesEnum.DocumentUkbcs, Component: DocumentPage },
    { path: RoutesEnum.CandidatesPrime, Component: CandidatePage },
    { path: RoutesEnum.DocumentPrime, Component: DocumentPage },
    { path: RoutesEnum.CandidatesForecs, Component: CandidatePage },
    { path: RoutesEnum.DocumentForecs, Component: DocumentPage },
    { path: RoutesEnum.CandidatesConsalting, Component: CandidatePage },
    { path: RoutesEnum.DocumentConsalting, Component: DocumentPage },
    { path: RoutesEnum.CandidatesHolding, Component: CandidatePage },
    { path: RoutesEnum.DocumentHolding, Component: DocumentPage },
    { path: RoutesEnum.CandidatesFintech, Component: CandidatePage },
    { path: RoutesEnum.DocumentFintech, Component: DocumentPage },
    { path: RoutesEnum.CandidatesTechnology, Component: CandidatePage },
    { path: RoutesEnum.DocumentTechnology, Component: DocumentPage },
    { path: RoutesEnum.CandidatesCompany, Component: CandidatePage },
    { path: RoutesEnum.DocumentCompany, Component: DocumentPage },
    { path: RoutesEnum.CandidatesConsultation, Component: CandidatePage },
    { path: RoutesEnum.DocumentConsultation, Component: DocumentPage },
    { path: RoutesEnum.CandidatesInsurance, Component: CandidatePage },
    { path: RoutesEnum.DocumentInsurance, Component: DocumentPage },
    { path: RoutesEnum.CandidatesLtd, Component: CandidatePage },
    { path: RoutesEnum.DocumentLtd, Component: DocumentPage },
    { path: RoutesEnum.Sd, Component: SdPage },
]);
