import { SliceBorder, Title } from "../../../../shared/ui";

import { about } from "../../consts/about";

import styles from "./AboutMainDesktop.module.scss";

export const AboutMainDesktop = () => {
    return (
        <>
            <img className={styles.aboutMain__img} src={about.img} alt={about.description} />
            <SliceBorder
                sizeRadius={32}
                className={styles.aboutMain__wrapperTitle}
                border={{
                    bottomRight: "inside",
                    topRight: "outside-corner-lateral",
                    bottomLeft: "outside-corner-upper",
                }}
            >
                <Title className={styles.aboutMain__title} level={1}>
                    Наши ценности
                </Title>
            </SliceBorder>
            <p className={styles.aboutMain__description}>{about.description}</p>
        </>
    );
};
