import { createAppAsyncThunk } from "../../../../shared/lib/createAppAsyncThunk";
import { formFetch } from "../../../../shared/api/api";
import { FormFileDeleteModel } from "../../../../shared/ApiContract/data-contracts";
import { FileResponse } from "../../types/fileResponce";
import { FileForm } from "../../types/types";

export const fileDeleteAsync = createAppAsyncThunk<FileForm[], Required<FormFileDeleteModel>>(
    "files/fileDeleteAsync",
    async (dataFile, thunkAPI) => {
        const response = await formFetch.formFileDeleteCreate(dataFile);

        try {
            const data = (await response.json()) as FileResponse;

            if (!data.errorCode) {
                return data.data ?? [];
            } else {
                return thunkAPI.rejectWithValue("error");
            }
        } catch (e) {
            return thunkAPI.rejectWithValue("error");
        }
    },
);
