import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../../../../shared/ui";
import { AccordionDirections } from "../Accordions/AccordionDirections";

import { classNames } from "../../../../shared/lib/classNames";
import {
    getRouteAbout,
    getRouteAboutRecruiting,
    getRouteEarlyCareer,
    getRouteVacancies,
} from "../../../../shared/constants/routes";
import { useScrollBlock } from "../../../../shared/hooks/useScrollBlock";

import styles from "./Burger.module.scss";

interface BurgerProps {
    className?: string;
}
export const Burger = ({ className }: BurgerProps) => {
    const location = useLocation();

    const [openedBurger, setOpenedBurger] = useState(false);

    useScrollBlock(openedBurger);

    useEffect(() => {
        setOpenedBurger(false);
    }, [location]);

    const onChangeBurger = () => {
        setOpenedBurger(!openedBurger);
    };

    return (
        <div className={classNames({ cls: styles.wrapper, additional: [className] })}>
            <div
                onClick={onChangeBurger}
                className={classNames({
                    cls: styles.burger,
                    mods: { [styles.burger_active]: openedBurger },
                })}
            >
                <p></p>
                <p></p>
            </div>
            {openedBurger && (
                <div className={styles.menu}>
                    <nav className={styles.menu__nav}>
                        <AccordionDirections />
                        <Link to={getRouteEarlyCareer()}>Начало карьеры</Link>
                        {/* NOTE BLOG Блоги скрыты по причине не хватки контента*/}
                        {/*<Link to={getRouteBlogs()}>Внутри БКС</Link>*/}
                        <Link to={getRouteAbout()}>О компании</Link>
                        <Link to={getRouteAboutRecruiting()}>Как мы нанимаем</Link>
                    </nav>
                    <Button
                        as={"link"}
                        className={styles.menu__buttom}
                        to={getRouteVacancies()}
                        theme={"transparent-white"}
                    >
                        <span>Все вакансии</span>
                    </Button>
                </div>
            )}
        </div>
    );
};
