import { SwiperSlide } from "swiper/react";
import { ReactElement, useState } from "react";
import { Container, Title, Carousel } from "../../shared/ui";
import { DirectionCard, DirectionCardSkeleton, useGetDirectionsShortListQuery } from "../../entities/Direction";

import { useMediaQuery } from "../../shared/hooks/useMediaQuery";
import { generateMediaQuery } from "../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../shared/constants/media";

import styles from "./CollectionJobs.module.scss";

export const CollectionJobs = () => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.md));
    const { data: directionList, isLoading, isSuccess } = useGetDirectionsShortListQuery();

    let content: ReactElement[] | null = null;

    if (isLoading) {
        content = [...Array(12)].map((_, index) => (
            <SwiperSlide key={index}>
                <DirectionCardSkeleton />
            </SwiperSlide>
        ));
    }

    if (isSuccess) {
        content = directionList.map((job, index) => (
            <SwiperSlide key={job.id}>
                <DirectionCard itemJob={job} index={index} />
            </SwiperSlide>
        ));
    }

    return (
        <Container className={styles.jobSwiper}>
            <Title className={styles.jobSwiper__title} level={3}>
                Работа во всех направлениях
            </Title>
            <Carousel
                slidesOffsetBefore={!matches ? 0 : 20}
                className={styles.jobSwiper__carousel}
                loop={false}
                spaceBetween={20}
                navigation="onBothSides"
                classNameNavigation={styles.jobSwiper__navigation}
                isSaveActiveSlide
            >
                {content}
            </Carousel>
        </Container>
    );
};
