import { EarlyCareerMain, QuestionnaireForm } from "../../widget";
import { Container } from "../../shared/ui/index";

export const EarlyCareerPage = () => {
    return (
        <EarlyCareerMain>
            <Container marginSize="large">
                <QuestionnaireForm theme={"other"} />
            </Container>
        </EarlyCareerMain>
    );
};
