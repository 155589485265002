import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { BooleanRadioInput } from "./BooleanRadioInput";

export type BooleanRadioInputControlProps<T extends FieldValues> = UseControllerProps<T>;

export const BooleanRadioInputControl = <T extends FieldValues>(props: BooleanRadioInputControlProps<T>) => {
    const { name, control, defaultValue, rules, shouldUnregister, ...otherProps } = props;
    const { field } = useController<T>({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });

    return <BooleanRadioInput {...field} {...otherProps} />;
};
