import { classNames } from "../../../../shared/lib/classNames";

import styles from "./DirectionList.module.scss";
import { buildSequentialArray } from "../../../../shared/lib/helpers";
import { Skeleton } from "../../../../shared/ui/index";

interface DirectionListProps {
    className?: string;
    directionList: string[];
    isLoading?: boolean;
}

export const DirectionList = ({ className, directionList, isLoading = false }: DirectionListProps) => {
    return (
        <div className={classNames({ cls: styles.directionList, additional: [className] })}>
            {isLoading
                ? buildSequentialArray(2).map((item) => <Skeleton className={styles.skeleton__item} key={item} />)
                : directionList.map((item) => (
                      <div key={item} className={styles.directionList__item}>
                          {item}
                      </div>
                  ))}
        </div>
    );
};
