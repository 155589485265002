import { AccordionBaseLayout } from "./AccordionBaseLayout";

import { searchQueryURL } from "../../../../shared/lib/helpers";
import { RoutesEnum } from "../../../../shared/constants/routes";
import { useGetDirectionsLongNameQuery } from "../../../../entities/Direction";

export const AccordionDirections = () => {
    const { data: directionList } = useGetDirectionsLongNameQuery();

    return (
        <AccordionBaseLayout
            title={"Направления"}
            options={directionList?.map((direct) => ({
                label: direct.longName!,
                link: searchQueryURL(RoutesEnum.Direction, { team: direct.id }),
            }))}
        />
    );
};
