import { imgDirection, imgPosterBlog } from "../assets/img/index";
export interface Blog {
    id: string;
    posterId: string;
    mainPhotoAttachmentId: string;
    shortDescription: string;
    title: string;
    video?: string;
    description: string;
    createDate: Date;
    author: {
        fullName: string;
        post: string;
    };
}

export const blogs: Blog[] = [
    {
        id: "article-1",
        video: "https://www.youtube.com/",
        posterId: imgPosterBlog,
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 1",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-2",
        posterId: imgPosterBlog,
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 2",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-3",
        posterId: imgPosterBlog,
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 3",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-4",
        posterId: imgPosterBlog,
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 4",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-5",
        posterId: imgPosterBlog,
        video: "https://www.youtube.com/",
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 5",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-6",
        posterId: imgPosterBlog,
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 6",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-7",
        posterId: imgPosterBlog,
        video: "https://www.youtube.com/",
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 7",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-8",
        posterId: imgPosterBlog,
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 8",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-9",
        posterId: imgPosterBlog,
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 9",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-10",
        posterId: imgPosterBlog,
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 10",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-12",
        posterId: imgPosterBlog,
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 12",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
    {
        id: "article-13",
        posterId: imgPosterBlog,
        video: "https://www.youtube.com/",
        mainPhotoAttachmentId: imgDirection,
        shortDescription: "Ut enim ad minim nostrud exercitation",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ipsum dolor sit amet consectetur adipiscing. Turpis massa tincidunt dui ut ornare. Nulla facilisi nullam vehicula ipsum a arcu. Egestas dui id ornare arcu. Auctor urna nunc id cursus metus aliquam eleifend. Quis vel eros donec ac. Pharetra sit amet aliquam id diam maecenas. Eu lobortis elementum nibh tellus molestie nunc non. Eleifend quam adipiscing vitae proin sagittis nisl.\n" +
            "\n" +
            "Nulla malesuada pellentesque elit eget. Sociis natoque penatibus et magnis dis parturient. Vitae purus faucibus ornare suspendisse sed. Lectus urna duis convallis convallis tellus. Sit amet porttitor eget dolor. Sed adipiscing diam donec adipiscing tristique risus nec. Duis at tellus at urna condimentum mattis pellentesque id. Id interdum velit laoreet id donec. Etiam dignissim diam quis enim lobortis. Luctus accumsan tortor posuere ac ut consequat semper viverra nam. Arcu ac tortor dignissim convallis aenean et tortor. Id venenatis a condimentum vitae sapien pellentesque habitant. Habitant morbi tristique senectus et netus. Lacus suspendisse faucibus interdum posuere lorem.\n" +
            "\n" +
            "Mattis enim ut tellus elementum sagittis. \n" +
            "\n" +
            "Semper viverra nam libero justo. Sodales neque sodales ut etiam. Gravida in fermentum et sollicitudin ac orci. Aliquam purus sit amet luctus. Commodo nulla facilisi nullam vehicula ipsum. Egestas erat imperdiet sed euismod. Lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Consequat nisl vel pretium lectus quam id leo in vitae. Lectus quam id leo in vitae turpis. Aliquam ultrices sagittis orci a scelerisque purus semper. Lectus magna fringilla urna porttitor rhoncus dolor purus. Ut porttitor leo a diam sollicitudin tempor. Urna condimentum mattis pellentesque id nibh tortor id aliquet lectus.\n" +
            "\n" +
            "Sit amet mauris commodo quis imperdiet massa tincidunt. Ipsum faucibus vitae aliquet nec. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula. Laoreet sit amet cursus sit. Felis eget nunc lobortis mattis aliquam faucibus. Vitae aliquet nec ullamcorper sit amet risus nullam. Hendrerit dolor magna eget est lorem. Sem fringilla ut morbi tincidunt augue interdum velit euismod in. Morbi tincidunt augue interdum velit euismod in. \n" +
            "\n" +
            "Vestibulum mattis ullamcorper velit sed\n" +
            "\n" +
            "Ullamcorper morbi tincidunt. Enim facilisis gravida neque convallis a cras semper. Commodo nulla facilisi nullam vehicula ipsum. Lectus nulla at volutpat diam ut venenatis tellus. Elementum tempus egestas sed sed risus pretium. Morbi tristique senectus et netus et. Nascetur ridiculus mus mauris vitae ultricies leo. Sit amet mauris commodo quis imperdiet.",
        title: "Заголовок статьи 13",
        createDate: new Date(),
        author: {
            fullName: "Светлана Константинова",
            post: "Исследователь клиентского опыта",
        },
    },
];
