import { ChangeEvent, InputHTMLAttributes, KeyboardEventHandler, useRef, useState } from "react";
import { Button } from "../Button/Button";

import { Check } from "../../../shared/assets/svg";

interface FileUploadProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
    onFileChange: (file: File) => void;
    errorMessage?: string;
    isLoading?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
}

export const FileUpload = ({
    onFileChange,
    errorMessage,
    isLoading,
    isError,
    isSuccess,
    ...otherProps
}: FileUploadProps) => {
    const [fileInputKey, setFileInputKey] = useState(0);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const fileCurrent = event.target.files?.[0] ?? null;
        if (fileCurrent) {
            onFileChange(fileCurrent);
        }
        setFileInputKey((prevKey) => prevKey + 1);
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleKeyPress: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.key === "Enter" || e.key === " ") {
            handleButtonClick();
        }
    };

    let content: string = "Загрузить";

    if (isLoading) {
        content = "Загрузка...";
    }

    if (isSuccess) {
        content = "Загружено";
    }

    return (
        <div>
            <input
                key={fileInputKey}
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
                {...otherProps}
            />
            <div onKeyDown={handleKeyPress} onClick={handleButtonClick}>
                <Button
                    as={"button"}
                    errorMessage={errorMessage ?? (isError ? "произошла ошибка, попробуйте ещё раз" : undefined)}
                    IconBefore={isSuccess && <Check />}
                    loading={isLoading}
                    theme={"blue"}
                >
                    {content}
                </Button>
            </div>
        </div>
    );
};
