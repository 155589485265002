/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApplicantFormModel, AttachmentResponseStatusActionResult, BooleanStatusActionResult } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Applicant<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Applicant
     * @name ApplicantFormCreate
     * @summary Получает данные с формы "Хочу у вас работать".
     * @request POST:/api/applicant/form
     */
    applicantFormCreate = (data: ApplicantFormModel, params: RequestParams = {}) =>
        this.request<BooleanStatusActionResult, any>({
            path: `/api/applicant/form`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Applicant
     * @name ApplicantUploadCreate
     * @summary Загружает вложения отдельно от формы "Хочу у вас работать" для проверки файлов через сервис проверки на вирусы БКС.
     * @request POST:/api/applicant/upload
     */
    applicantUploadCreate = (
        data: {
            /** @format uuid */
            AttachmentId?: string;
            /** @format binary */
            File?: File;
        },
        params: RequestParams = {},
    ) =>
        this.request<AttachmentResponseStatusActionResult, any>({
            path: `/api/applicant/upload`,
            method: "POST",
            body: data,
            type: ContentType.FormData,
            format: "json",
            ...params,
        });
}
