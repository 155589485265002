import React from "react";
import { BackButton, Container, Title } from "../../shared/ui";

import styles from "./AboutRecruiting.module.scss";
export const AboutRecruiting = () => {
    return (
        <Container marginSize="large" className={styles.aboutRecruiting}>
            <BackButton theme={"dark"} />
            <div className={styles.aboutRecruiting__content}>
                <Title className={styles.aboutRecruiting__title} level={2}>
                    Как стать частью команды
                </Title>
                <p className={styles.aboutRecruiting__subtitle}>
                    Здесь мы расскажем об этапах, которые ждут вас при отклике на вакансии БКС.
                </p>
            </div>
        </Container>
    );
};
