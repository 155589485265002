import { useRef } from "react";
import { Swiper } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import { Pagination } from "swiper/modules";

import "swiper/css/pagination";
import "swiper/css";
import styles from "./Carousel.module.scss";
import { CarouselProps } from "../../types";
import { classNames } from "../../../../lib/classNames";
import { Navigations } from "../Navigations/Navigations";

export const Carousel = (props: CarouselProps) => {
    const {
        children,
        navigation,
        navigationTheme,
        colorBackground,
        className,
        border,
        classNameNavigation,
        pagination,
        isSaveActiveSlide,
        ...otherProps
    } = props;

    const sliderRef = useRef<SwiperType>(null);
    const paginationHandle = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return `<span class="${className}">${pagination![index]}</span>`;
        },
    };
    return (
        <div
            className={classNames({
                cls: styles.carousel__container,
                mods: {
                    [styles.carousel__pagination]: Boolean(pagination),
                    [styles[`carousel__${navigation}`]]: Boolean(navigation),
                },
                additional: [className],
            })}
        >
            <Swiper
                initialSlide={isSaveActiveSlide ? Number(sessionStorage.getItem("carouselActiveSlide")) || 0 : 0}
                className={styles.carousel}
                slidesPerView={"auto"}
                modules={[Pagination]}
                pagination={pagination && paginationHandle}
                ref={sliderRef}
                {...otherProps}
                onSlideChange={(swiper: SwiperType) =>
                    isSaveActiveSlide && sessionStorage.setItem("carouselActiveSlide", swiper.activeIndex.toString())
                }
            >
                {children}
            </Swiper>
            {navigation && (
                <div className={classNameNavigation}>
                    <Navigations
                        navigationTheme={navigationTheme}
                        colorBackground={colorBackground}
                        className={classNameNavigation}
                        border={border}
                        navigation={navigation}
                        sliderRef={sliderRef}
                    />
                </div>
            )}
        </div>
    );
};
