import { VacancyItem } from "../VacancyItem/VacancyItem";

import { classNames } from "../../../../shared/lib/classNames";
import { JobList as IJobList } from "../../../../shared/mocks/jobs";
import { IVacancyList } from "../../../../shared/mocks/vacancy";

import styles from "./VacancyList.module.scss";
import { VacancyItemSkeleton } from "../VacancyItem/VacancyItemSekeleton";
import { buildSequentialArray } from "../../../../shared/lib/helpers";
import { VacancyDto, VacancyExpandDto } from "../../../../shared/ApiContract/data-contracts";

interface JobListProps {
    className?: string;
    jobList: IJobList[] | IVacancyList[] | VacancyDto[] | VacancyExpandDto[];
    isLoading?: boolean;
    head?: boolean;
}

export const VacancyListSkeleton = () => {
    return (
        <>
            {buildSequentialArray(5).map((item) => (
                <VacancyItemSkeleton key={item} />
            ))}
        </>
    );
};

export const VacancyList = ({ className, jobList, isLoading = false, head = false }: JobListProps) => {
    return (
        <div
            className={classNames({
                cls: styles.jobList,
                additional: [className],
            })}
        >
            {head && (
                <div className={classNames({ cls: styles.grid })}>
                    <p></p>
                    <p>Направление</p>
                    <p>Местонахождение</p>
                    <p>Режим работы</p>
                </div>
            )}
            {!isLoading ? (
                jobList.map((job) => <VacancyItem key={job.id} vacancyItem={job} />)
            ) : (
                <VacancyListSkeleton />
            )}
        </div>
    );
};
