import { isValidElement, ReactNode } from "react";
import { ErrorValidate } from "../ErrorValidate/ErrorValidate";

import { classNames } from "../../lib/classNames";

import styles from "./BaseLabel.module.scss";

export interface BaseLabelArg {
    className?: string;
    label?: string;
    errorMessage?: string;
    id?: string;
}

interface BaseLabelProps extends BaseLabelArg {
    children: ReactNode;
}
export const BaseLabel = ({ className, label, errorMessage, children, id }: BaseLabelProps) => {
    let inputId = id;

    if (isValidElement(children) && children.props.name) {
        inputId = children.props.name;
    }

    return (
        <div
            className={classNames({
                cls: styles.baseLabel,
                additional: [className],
            })}
        >
            {label && (
                <label htmlFor={inputId} className={styles.baseLabel__text}>
                    {label}
                </label>
            )}
            <div className={styles.baseLabel__wrapper}>
                {children}
                <ErrorValidate errorMessage={errorMessage} />
            </div>
        </div>
    );
};
