import React from "react";

import cls from "./Sd.module.scss";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { sd } from "../../../shared/assets/img/index";
import { useLocation } from "react-router-dom";

const Sd = () => {
    let { state } = useLocation();
    return (
        <div className={cls.wrapper}>
            <Header route={state.prevPath} />
            <div className={cls.section}>
                <div className={cls.content}>
                    <img src={sd} alt="sdDocument" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Sd;
