import { formFetch } from "../../../../shared/api/api";
import { FormDto } from "../../../../shared/ApiContract/data-contracts";
import { HttpResponse } from "../../../../shared/ApiContract/http-client";
import { createAppAsyncThunk } from "../../../../shared/lib/createAppAsyncThunk";
import { format, isValid, parse, parseISO } from "date-fns";

export const dateToServe = (date: string) => {
    if (!date) return null;
    if (date === "__.__.____") return null;
    const dd = parseISO(date);
    if (isValid(dd)) return date;
    const d = parse(date, "dd.MM.yyyy", new Date());
    return format(d, "yyyy-MM-dd");
};

const processObject = <T extends object>(obj: T): T => {
    Object.entries(obj).forEach(([key, value]) => {
        if (dateArray.includes(key) && value !== null) {
            // @ts-ignore
            obj[key] = dateToServe(value);
        }

        if (Array.isArray(value)) {
            value.forEach((subArray) => {
                if (typeof subArray === "object" && subArray !== null) {
                    processObject(subArray);
                }
            });
        } else if (typeof value === "object" && value !== null) {
            processObject(value);
        }
    });

    return obj;
};

export const dateArray = [
    "issueDate",
    "birthDay",
    "dateBrought",
    "dateMarried",
    "dateOfGraduation",
    "sinceDate",
    "tillDate",
];

export const formUpdateAsync = createAppAsyncThunk<FormDto, FormDto>(
    "form/formUpdateAsync",
    async (formData, thunkAPI) => {
        try {
            const body = processObject(JSON.parse(JSON.stringify(formData)));

            if (body.factAdressIsSameRegistr) {
                body.factAddress = body.registrationAddress;
                body.factAddressObject = body.registrationAddressObject;
            }

            if (body.isFirstMarried) {
                body.countMarried = null;
            }

            const response = await formFetch.formUpdateCreate(body);
            const data = (await response.json()) as HttpResponse<FormDto, void>;

            if (response.ok && data.data) {
                return data.data;
            }
            return thunkAPI.rejectWithValue("error");
        } catch (e) {
            return thunkAPI.rejectWithValue("error");
        }
    },
);
