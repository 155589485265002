import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Checkbox, Tooltip, DropdownControl, Dropdown } from "../../../../shared/ui";

import { CircleInfo } from "../../../../shared/assets/svg";
import { useGetDirectionsLongNameQuery } from "../../../../entities/Direction";
import { useGetVacancyCityQuery } from "../../../../entities/Vacancy";
import { FormQueryDirectionVacancy } from "../../types/form";
import { filteredFormQueryDirectionVacancy } from "../../lib/filteredFormQueryDirectionVacancy";

import styles from "./QueryDirectionVacancy.module.scss";
import { useGetTeamsQuery } from "../../../../entities/Direction/api/directionsApi";

interface SearchVacanciesProps {
    defaultValues?: FormQueryDirectionVacancy;
    onSubmit: (data: FormQueryDirectionVacancy) => void;
}

export const QueryDirectionVacancy = ({ defaultValues, onSubmit }: SearchVacanciesProps) => {
    const { data: directionList, isLoading: isLoadingDirectionList } = useGetDirectionsLongNameQuery();
    const { register, control, watch, setValue } = useForm<any>({
        defaultValues: filteredFormQueryDirectionVacancy(defaultValues),
    });
    const directionId = watch("DirectionId");
    const { data: teamsList, isLoading: isLoadingTeamList } = useGetTeamsQuery(directionId, { skip: !directionId });
    const { data: citiesList, isLoading: isLoadingCitiesList } = useGetVacancyCityQuery();

    const hybrid = watch("IsHybrid");
    const remove = watch("IsRemote");
    const data = useWatch({ control });
    useEffect(() => {
        onSubmit(data);
    }, [data]);

    useEffect(() => {
        if (hybrid) {
            setValue("IsRemote", false);
        }
    }, [hybrid]);

    useEffect(() => {
        if (remove) {
            setValue("IsHybrid", false);
        }
    }, [remove]);

    return (
        <form className={styles.queryDirectionVacancy}>
            <div className={styles.queryDirectionVacancy__selectList}>
                {isLoadingDirectionList ? (
                    <Dropdown placeholder="Направление" onChangeOption={() => {}} />
                ) : (
                    <DropdownControl
                        name={"DirectionId"}
                        direction={directionList}
                        close={() => setValue("TeamId", null)}
                        control={control}
                        placeholder={"Направление"}
                        isLoading={isLoadingDirectionList}
                        options={
                            directionList?.map((direct) => ({
                                label: direct.longName!,
                                value: direct.id!,
                            })) ?? []
                        }
                    />
                )}
                {isLoadingTeamList ? (
                    <Dropdown placeholder="Команда" onChangeOption={() => {}} />
                ) : (
                    <DropdownControl
                        name={"TeamId"}
                        team={teamsList!}
                        disabled={!directionId}
                        control={control}
                        placeholder={"Команда"}
                        isLoading={isLoadingTeamList}
                        options={
                            teamsList?.teams?.map((team) => ({
                                label: team.name!,
                                value: team.id!,
                            })) ?? []
                        }
                    />
                )}
                <DropdownControl
                    isLoading={isLoadingCitiesList}
                    name={"City"}
                    control={control}
                    placeholder={"Город"}
                    options={(citiesList || [])?.map((city) => ({
                        label: city,
                        value: city,
                    }))}
                />
            </div>
            <div className={styles.queryDirectionVacancy__cheackboxList}>
                <Checkbox {...register("IsRemote")}>Удаленная работа</Checkbox>
                <div className={styles.queryDirectionVacancy__cheackboxHybrid}>
                    <Checkbox {...register("IsHybrid")}>Гибридный вариант</Checkbox>
                </div>
            </div>
        </form>
    );
};
