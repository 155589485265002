import { Link } from "react-router-dom";
import { IconButton, Skeleton, SliceBorder } from "../../../../shared/ui";

import { generateMediaQuery, searchQueryURL, transferDanglingPrepositions } from "../../../../shared/lib/helpers";
import { ShortDescriptionDto } from "../../../../shared/ApiContract/data-contracts";
import { RoutesEnum } from "../../../../shared/constants/routes";
import { useMediaQuery } from "../../../../shared/hooks/useMediaQuery";
import { MediaQueryProperty, MediaSizeEnum } from "../../../../shared/constants/media";

import styles from "./DirectionCard.module.scss";
import { classNames } from "../../../../shared/lib/classNames";

interface JobCardProps {
    itemJob: ShortDescriptionDto;
    index: number;
}

export const DirectionCard = ({ itemJob, index }: JobCardProps) => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.sm));
    return (
        <div className={styles.job}>
            <Link to={searchQueryURL(RoutesEnum.Direction, { team: itemJob.id })}>
                <div className={styles.job__container}>
                    <p
                        className={classNames({
                            cls: styles.job__title,
                            mods: { [styles.job__title_hyphens]: !!itemJob.shortName?.includes("Административный") },
                        })}
                        lang="ru"
                        dangerouslySetInnerHTML={{ __html: transferDanglingPrepositions(itemJob.shortName!) }}
                    ></p>
                    <p
                        dangerouslySetInnerHTML={{ __html: transferDanglingPrepositions(itemJob.shortDescription!) }}
                        className={styles.job__description}
                    ></p>
                    <SliceBorder
                        className={styles.job__wrapperButton}
                        sizeRadius={20}
                        multiplierInside={!matches ? 1.2 : 1}
                        border={{
                            topRight: "outside-corner-upper",
                            bottomLeft: "outside-corner-lateral",
                            topLeft: "inside",
                        }}
                    >
                        <IconButton theme="blue" icon={"right"} />
                    </SliceBorder>
                </div>
            </Link>
        </div>
    );
};

export const DirectionCardSkeleton = () => {
    return <Skeleton className={styles.job__skeleton} />;
};
