import { PatternFormat } from "react-number-format";
import { ErrorValidate } from "../ErrorValidate/ErrorValidate";

import { PatternFormatProps } from "react-number-format/types/types";
import { classNames } from "../../../../../shared/lib/classNames";

import styles from "./NumberField.module.scss";
export interface NumberFieldProps extends Omit<PatternFormatProps, "className"> {
    className?: string;
    errorMessage?: string;
    label?: string;
}

export const NumberField = (props: NumberFieldProps) => {
    const { label, errorMessage, className, required, ...otherProps } = props;
    const cls = classNames({
        cls: styles.numberField__input,
        mods: { [styles.numberField__input_error]: Boolean(errorMessage) },
    });
    return (
        <label className={classNames({ cls: styles.numberField, additional: [className] })}>
            {label && (
                <p className={styles.numberField__label}>
                    {label} {required && <span>*</span>}
                </p>
            )}
            <PatternFormat className={cls} {...otherProps} />
            <ErrorValidate errorMessage={errorMessage} />
        </label>
    );
};
