export const lifehacks = [
    {
        title: "Подготовка",
        description:
            "Подготовьтесь к интервью — изучите информацию о компании и вакансии. Сформулируйте вопросы к интервьюеру.",
    },
    {
        title: "Открытость",
        description: "Будьте открыты и честны, расскажите о своем опыте, знаниях, победах и неудачах.",
    },
    { title: "Увереность", description: "Верьте в себя и свои силы." },
    {
        title: "Пунктуальность",
        description: "Не опаздывайте на интервью и предупреждайте заранее, если у вас изменились планы.",
    },
    // ДУБЛИКАТЫ ЧТОБЫ РАБОТАЛА БЕСКОНЕЧНАЯ КАРУСЕЛЬ
    {
        title: "Подготовка",
        description:
            "Подготовьтесь к интервью — изучите информацию о компании и вакансии. Сформулируйте вопросы к интервьюеру.",
    },
    {
        title: "Открытость",
        description: "Будьте открыты и честны, расскажите о своем опыте, знаниях, победах и неудачах.",
    },
    { title: "Увереность", description: "Верьте в себя и свои силы." },
    {
        title: "Пунктуальность",
        description: "Не опаздывайте на интервью и предупреждайте заранее, если у вас изменились планы.",
    },
];
