import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { OptionsDropdown } from "../OptionsDropdown/OptionsDropdown";
import { ButtonDropdown } from "../ButtonDropdown/ButtonDropdown";

import { classNames } from "../../../../lib/classNames";
import { DropdownProps } from "../../types";

import styles from "./Dropdown.module.scss";
import { BaseLabel } from "../../../BaseLabel/BaseLabel";

export const Dropdown = (props: DropdownProps) => {
    const {
        type = "button",
        selected,
        errorMessage,
        className,
        closeIcon = true,
        placeholder,
        onChangeOption,
        label,
        isLoading = false,
        options = [],
        close,
        disabled,
    } = props;
    const [opened, setOpened] = useState(false);
    const [currentOption, setCurrentOption] = useState<string | null | undefined>(selected);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const transOptions = options.map((option) =>
        typeof option === "string" ? { value: option, label: option } : option,
    );
    const OpenedHandle = () => setOpened((prev) => !prev);

    useEffect(() => {
        if (currentOption !== null && !options.some((el) => typeof el !== "string" && el.label === currentOption)) {
            setCurrentOption(null);
        }
    }, [currentOption, options]);

    const handleOptionClick: MouseEventHandler<HTMLLIElement> = (e) => {
        e.stopPropagation();
        const selectedValue = transOptions.find((option) => option.label === e.currentTarget.textContent);
        setOpened(false);
        setCurrentOption(selectedValue?.label);
        onChangeOption(selectedValue?.value ?? null);
        close && close();
    };

    const handleDelOption: MouseEventHandler<SVGSVGElement> = (e) => {
        e.stopPropagation();

        setOpened(false);
        setCurrentOption(null);
        onChangeOption(null);
        close && close();
    };

    return (
        <BaseLabel className={styles[`dropdown_${type}`]} errorMessage={errorMessage} label={label}>
            <div className={classNames({ cls: styles.dropdown, additional: [styles[`dropdown_${type}`], className] })}>
                <ButtonDropdown
                    isLoading={isLoading}
                    type={type}
                    ref={buttonRef}
                    opened={opened}
                    closeIcon={closeIcon}
                    selected={currentOption}
                    placeholder={placeholder}
                    onDelOption={handleDelOption}
                    onOpen={OpenedHandle}
                    disabled={disabled}
                />
                <OptionsDropdown
                    type={type}
                    opened={opened}
                    options={transOptions}
                    selected={selected}
                    triggerRef={buttonRef}
                    onClose={() => setOpened(false)}
                    onClickOption={handleOptionClick}
                />
            </div>
        </BaseLabel>
    );
};
