import { Skeleton } from "../../../../shared/ui";

import { classNames } from "../../../../shared/lib/classNames";

import styles from "./VacancyItem.module.scss";

interface VacancyItemSkeletonProps {
    className?: string;
}
export const VacancyItemSkeleton = ({ className }: VacancyItemSkeletonProps) => {
    return (
        <div
            className={classNames({
                cls: styles.vacancy,
                additional: [className, styles.skeleton],
            })}
        >
            <div>
                <Skeleton />
                <Skeleton />
            </div>
            <div>
                <Skeleton />
                <Skeleton />
            </div>
            <div>
                <Skeleton />
                <Skeleton />
            </div>
            <div>
                <Skeleton />
                <Skeleton />
            </div>
        </div>
    );
};
