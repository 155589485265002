import { ForwardedRef, forwardRef, TextareaHTMLAttributes } from "react";
import { BaseLabel, BaseLabelArg } from "../BaseLabel/BaseLabel";

import { classNames } from "../../lib/classNames";

import styles from "./TextArea.module.scss";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, BaseLabelArg {}

export const TextArea = forwardRef(
    ({ className, errorMessage, label, ...otherProps }: TextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
        return (
            <BaseLabel label={label} errorMessage={errorMessage} className={className}>
                <textarea
                    ref={ref}
                    className={classNames({
                        cls: styles.textArea,
                        mods: { [styles.textArea_error]: Boolean(errorMessage) },
                    })}
                    {...otherProps}
                />
            </BaseLabel>
        );
    },
);
