import styles from "./HomeMain.module.scss";
import { imgMainBackground } from "../../shared/assets/img/index";
import { Button, Container, SliceBorder, Title } from "../../shared/ui";
import { getRouteVacancies } from "../../shared/constants/routes";
import { useMediaQuery } from "../../shared/hooks/useMediaQuery";
import { generateMediaQuery } from "../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../shared/constants/media";
import { StickerVacancy } from "../../entities/Vacancy";
import { useGetVacancyCountQuery } from "../../entities/Vacancy/api/vacancyApi";

export const HomeMain = () => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.lg));
    const matchesMd = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.md));
    const { data: countVacancy } = useGetVacancyCountQuery();
    return (
        <Container marginSize={"standard"} className={styles.mainBanner}>
            <div className={styles.mainBanner__content}>
                <div className={styles.mainBanner__wrapperTitle}>
                    <Title className={styles.mainBanner__title} level={1}>
                        БКС —<br />
                        для тех, кому
                    </Title>
                    {!matches && (
                        <Title className={styles.mainBanner__subTitle} level={2}>
                            больше всех надо
                        </Title>
                    )}
                </div>
                <div className={styles.mainBanner__wrapperImg}>
                    <SliceBorder
                        sizeRadius={matchesMd ? 20 : 50}
                        multiplierInside={1}
                        className={styles.mainBanner__wrapperSliceImg}
                        border={{
                            topRight: "outside-corner-lateral",
                            bottomRight: "inside",
                            bottomLeft: "outside-corner-upper",
                        }}
                    >
                        {matches ? (
                            <Title className={styles.mainBanner__subTitle} level={2}>
                                больше всех надо
                            </Title>
                        ) : (
                            <div></div>
                        )}
                    </SliceBorder>
                    <img className={styles.mainBanner__img} src={imgMainBackground} alt={"imgMainBackground"} />
                    {typeof countVacancy === "number" && (
                        <StickerVacancy className={styles.mainBanner__sticker} countVacancy={countVacancy} />
                    )}
                </div>
            </div>
            <Button
                as={"link"}
                className={styles.mainBanner__buttonMobile}
                theme={"transparent-dark"}
                to={getRouteVacancies()}
            >
                Все вакансии
            </Button>
        </Container>
    );
};
