import { useEffect, useState } from "react";

const getMatches = (query: string): boolean => {
    if (typeof window !== "undefined") {
        return window.matchMedia(query).matches;
    }
    return false;
};

export function useMediaQuery(query: string): boolean {
    const [matches, setMatches] = useState<boolean>(getMatches(query));

    function handleChange() {
        setMatches(getMatches(query));
    }

    useEffect(() => {
        const matchMedia = window.matchMedia(query);
        matchMedia.addEventListener("change", handleChange);

        return () => {
            matchMedia.removeEventListener("change", handleChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return matches;
}
