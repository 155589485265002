import { Link } from "react-router-dom";

import { getRouteVacancy } from "../../../../shared/constants/routes";
import { classNames } from "../../../../shared/lib/classNames";

import styles from "./VacancyItem.module.scss";

import { VacanciesPageDto } from "../../../../shared/ApiContract/data-contracts";
import { useGetDirectionsLongNameQuery } from "../../../Direction";
import { useGetWorkTypeQuery } from "../../api/vacancyApi";

interface VacancyItemProps {
    className?: string;
    vacancyItem: VacanciesPageDto;
}
export const VacancyItem = ({ className, vacancyItem }: VacancyItemProps) => {
    const { data: directionList } = useGetDirectionsLongNameQuery();
    const { data: workType } = useGetWorkTypeQuery();

    return (
        <Link
            to={getRouteVacancy(vacancyItem.id!)}
            className={classNames({
                cls: styles.vacancy,
                additional: [styles.grid_border, className],
            })}
        >
            <p className={styles.direction}>{vacancyItem.name}</p>
            <div className={styles.teams}>
                <p>{directionList?.find((el) => el.id === vacancyItem.directionId)?.longName}</p>
            </div>
            <div className={styles.countries}>
                <p>{vacancyItem.city}</p>
            </div>
            <p className={styles.typeWork}>{workType?.find((el) => el.id === vacancyItem.workType)?.name}</p>
        </Link>
    );
};
