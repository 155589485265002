import styles from "./LoadingFull.module.scss";
import { classNames } from "../../../../../shared/lib/classNames";
import { useScrollBlock } from "../../../../../shared/hooks/useScrollBlock";

interface LoadingFullProps {
    className?: string;
    isLoading?: boolean;
}
export const LoadingFull = ({ className, isLoading = false }: LoadingFullProps) => {
    useScrollBlock(isLoading);

    if (!isLoading) return null;
    return (
        <div className={classNames({ cls: styles.loadingFull, additional: [className] })}>
            <div className={styles.loadingFull__spiner}></div>
        </div>
    );
};
