import { classNames } from "../../lib/classNames";

import styles from "./Loader.module.scss";

interface LoaderProps {
    className?: string;
}
export const Loader = ({ className }: LoaderProps) => {
    return (
        <div
            className={classNames({
                cls: styles.loader,
                additional: [className],
            })}
        ></div>
    );
};
