export enum FileCheck {
    sizeError = "FILE_SIZE_ERROR",
    nameError = "FILE_NAME_ERROR",
    typeError = "FILE_TYPE_ERROR",
    typeInvalid = "FILE_TYPE_INVALID",
    successLoaded = "FILE_SUCCESS_LOADED",
    loading = "FILE_LOADING",
    loadError = "FILE_LOAD_ERROR",
    antivirusCheck = "ANTIVIRUS_CHECK",
    antivirusCheckError = "ANTIVIRUS_CHECK_ERROR",
    checkSuccess = "CHECK_SUCCESS",
    tempStorageError = "TEMP_STORAGE_ERROR",
}

export interface FileResStatus {
    AttachmentId: string;
    fileState: FileCheck;
}
