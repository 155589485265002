import { useEffect } from "react";

export const useScrollBlock = (isBlock: boolean) => {
    useEffect(() => {
        if (isBlock) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [isBlock]);
};
