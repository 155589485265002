import * as yup from "yup";
import { IFrom } from "../types/Form";
import { yupResolver } from "@hookform/resolvers/yup";

const phoneRegex = /^\+?\d{1,3}[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{2}[-.\s]?\d{2}$/;
const yearRegex = /^(?:19|20)\d{2}$/;
const ruLanguageRegex = /^[А-ЯЁа-яё\s]+$/u;

const schema = yup.object().shape({
    fullName: yup.string().required("Необходимо заполнить").matches(ruLanguageRegex, "не валидное имя"),
    phone: yup.string().required("Необходимо заполнить").matches(phoneRegex, "Номер телефона невалиден"),
    email: yup.string().required("Необходимо заполнить").email("не корректный email"),
    university: yup.string().required("Необходимо заполнить"),
    trainingStage: yup.string().required("Необходимо заполнить"),
    yearRelease: yup.string().required("Необходимо заполнить").matches(yearRegex, "Неверный формат года"),
    attachmentId: yup.string().required("Необходимо загрузить резюме"),
    country: yup.string().required("Необходимо указать город"),

    personalData: yup.boolean().oneOf([true], "Необходимо подтвердить согласие").required(),
    receiveVacancies: yup.boolean().oneOf([true], "Необходимо подтвердить согласие").required(),

    aboutMe: yup.string().optional(),
    socialHH: yup.string().optional(),
    social: yup.string().optional(),
    socialPortfolio: yup.string().optional(),
    socialOther: yup.string().optional(),

    vacancyId: yup.string().optional(),
});

export const resolve = yupResolver<IFrom>(schema);
