import styles from "./SubmitButtons.module.scss";
import { classNames } from "../../../../../shared/lib/classNames";
import { Button } from "../Button/Button";
import { useScrollToTop } from "../../../../../shared/hooks/useScrollToTop";

interface SubmitButtonsProps {
    className?: string;
    isValid?: boolean;
    isEnd?: boolean;
    onBack?: () => void;
}
export const SubmitButtons = ({ className, onBack, isValid, isEnd = false }: SubmitButtonsProps) => {
    useScrollToTop();

    return (
        <div className={classNames({ cls: styles.submitButtons, additional: [className] })}>
            {/* {!isValid && <p className={styles.submitButtons__text}>Проверьте правильность заполнения полей</p>} */}
            <div className={styles.submitButtons__wrapper}>
                {onBack && <Button onClick={onBack}>Назад</Button>}
                <Button type={"submit"}>{isEnd ? "Завершить" : "Далее"}</Button>
            </div>
        </div>
    );
};
