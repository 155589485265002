import { administrative } from "../../shared/assets/img";
import { analytics } from "../../shared/assets/img";
import { audit } from "../../shared/assets/img";
import { safety } from "../../shared/assets/img";
import { marketing } from "../../shared/assets/img";
import { service } from "../../shared/assets/img";
import { block } from "../../shared/assets/img";
import { sales } from "../../shared/assets/img";
import { factories } from "../../shared/assets/img";
import { risks } from "../../shared/assets/img";
import { business } from "../../shared/assets/img";
import { staff } from "../../shared/assets/img";
import { projects } from "../../shared/assets/img";
import { finance } from "../../shared/assets/img";
import { support } from "../../shared/assets/img";
import { it } from "../../shared/assets/img";

export const directionBanner: any = {
    IT: it,
    "Розничный бизнес": business,
    "Обслуживание клиентов": service,
    "Продажи и развитие бизнеса": sales,
    "Маркетинг и PR": marketing,
    "Продуктовые фабрики": factories,
    "Аналитика и данные": analytics,
    "Операционный блок": block,
    "Управление процессами и проектами": projects,
    "Управление персоналом": staff,
    "Юридическая поддержка": support,
    "Административный персонал": administrative,
    "Финансы и бухгалтерия": finance,
    "Аудит и внутренний контроль": audit,
    "Риски и комплаенс": risks,
    "Безопасность бизнеса и сопровождение": safety,
};
