export const cities = [
    "Майкоп",
    "Горно-Алтайск",
    "Барнаул",
    "Бийск",
    "Рубцовск",
    "Новоалтайск",
    "Заринск",
    "Благовещенск (Амурская область)",
    "Белогорск (Амурская область)",
    "Свободный",
    "Архангельск",
    "Северодвинск",
    "Котлас",
    "Астрахань",
    "Ахтубинск",
    "Знаменск",
    "Уфа",
    "Стерлитамак",
    "Салават",
    "Нефтекамск",
    "Октябрьский",
    "Белорецк",
    "Благовещенск (Башкортостан)",
    "Белгород",
    "Старый Оскол",
    "Губкин",
    "Шебекино",
    "Новый Оскол",
    "Брянск",
    "Клинцы",
    "Улан-Удэ",
    "Северобайкальск",
    "Владимир",
    "Ковров",
    "Муром",
    "Александров",
    "Гусь-Хрустальный",
    "Киржач",
    "Волгоград",
    "Волжский",
    "Камышин",
    "Калач-на-Дону",
    "Череповец",
    "Вологда",
    "Сокол",
    "Воронеж",
    "Борисоглебск",
    "Россошь",
    "Лиски",
    "Павловск",
    "Махачкала",
    "Хасавюрт",
    "Дербент",
    "Биробиджан",
    "Чита",
    "Краснокаменск",
    "Иваново",
    "Кинешма",
    "Шуя",
    "Назрань",
    "Иркутск",
    "Братск",
    "Ангарск",
    "Усть-Илимск",
    "Нальчик",
    "Прохладный",
    "Калининград",
    "Советск (Калининградская область)",
    "Элиста",
    "Калуга",
    "Обнинск",
    "Петропавловск-Камчатский",
    "Елизово",
    "Вилючинск",
    "Черкесск",
    "Петрозаводск",
    "Кондопога",
    "Новокузнецк",
    "Кемерово",
    "Прокопьевск",
    "Ленинск-Кузнецкий",
    "Междуреченск",
    "Киселёвск",
    "Юрга",
    "Киров (Кировская область)",
    "Кирово-Чепецк",
    "Сыктывкар",
    "Ухта",
    "Воркута",
    "Печора",
    "Кострома",
    "Краснодар",
    "Сочи",
    "Новороссийск",
    "Армавир",
    "Ейск",
    "Кропоткин",
    "Туапсе",
    "Тихорецк",
    "Анапа",
    "Белореченск",
    "Геленджик",
    "Красноярск",
    "Норильск",
    "Ачинск",
    "Канск",
    "Железногорск (Красноярский край)",
    "Зеленогорск",
    "Курган",
    "Шадринск",
    "Курск",
    "Железногорск (Курская область)",
    "Курчатов",
    "Гатчина",
    "Выборг",
    "Сосновый Бор",
    "Тихвин",
    "Кириши",
    "Кингисепп",
    "Всеволожск",
    "Волхов",
    "Сертолово",
    "Луга",
    "Тосно",
    "Сланцы",
    "Кировск (Ленинградская область)",
    "Лодейное Поле",
    "Пикалёво",
    "Отрадное",
    "Подпорожье",
    "Коммунар",
    "Приозерск",
    "Никольское",
    "Бокситогорск",
    "Светогорск",
    "Сясьстрой",
    "Шлиссельбург",
    "Сиверский",
    "Волосово",
    "Ивангород",
    "Вырица",
    "Поселок им. Морозова",
    "Липецк",
    "Елец",
    "Магадан",
    "Йошкар-Ола",
    "Волжск",
    "Саранск",
    "Рузаевка",
    "Балашиха",
    "Химки",
    "Подольск",
    "Королёв",
    "Мытищи",
    "Люберцы",
    "Коломна",
    "Электросталь",
    "Одинцово",
    "Железнодорожный (Балашиха)",
    "Серпухов",
    "Орехово-Зуево",
    "Ногинск",
    "Щёлково",
    "Сергиев Посад",
    "Жуковский",
    "Красногорск",
    "Пушкино",
    "Воскресенск",
    "Домодедово",
    "Раменское",
    "Реутов",
    "Долгопрудный",
    "Клин",
    "Чехов",
    "Наро-Фоминск",
    "Лобня",
    "Егорьевск",
    "Ступино",
    "Дмитров",
    "Дубна",
    "Павловский Посад",
    "Солнечногорск",
    "Ивантеевка",
    "Климовск (Москва",
    "Видное",
    "Фрязино",
    "Лыткарино",
    "Дзержинский",
    "Кашира",
    "Протвино",
    "Троицк (Москва)",
    "Юбилейный (Москва)",
    "Истра",
    "Нахабино",
    "Краснознаменск (Московская область)",
    "Луховицы",
    "Щербинка (Москва)",
    "Шатура",
    "Ликино-Дулёво",
    "Можайск",
    "Томилино",
    "Дедовск",
    "Красноармейск (Московская область)",
    "Кубинка",
    "Озёры",
    "Зарайск",
    "Калининец",
    "Волоколамск",
    "Лосино-Петровский",
    "Старая Купавна",
    "Рошаль",
    "Электрогорск",
    "Электроугли",
    "Черноголовка",
    "Котельники",
    "Пущино",
    "Красково",
    "Мурманск",
    "Апатиты",
    "Североморск",
    "Нарьян-Мар",
    "Нижний Новгород",
    "Дзержинск",
    "Арзамас",
    "Саров",
    "Бор",
    "Кстово",
    "Павлово",
    "Выкса",
    "Балахна",
    "Великий Новгород",
    "Боровичи",
    "Старая Русса",
    "Новосибирск",
    "Бердск",
    "Куйбышев",
    "Омск",
    "Оренбург",
    "Орск",
    "Новотроицк",
    "Бузулук",
    "Орёл",
    "Ливны",
    "Мценск",
    "Пенза",
    "Кузнецк",
    "Заречный (Пензенская область)",
    "Каменка",
    "Пермь",
    "Березники",
    "Соликамск",
    "Чайковский",
    "Лысьва",
    "Кунгур",
    "Краснокамск",
    "Владивосток",
    "Находка",
    "Уссурийск",
    "Артём",
    "Псков",
    "Великие Луки",
    "Ростов-на-Дону",
    "Таганрог",
    "Шахты",
    "Новочеркасск",
    "Волгодонск",
    "Новошахтинск",
    "Батайск",
    "Каменск-Шахтинский",
    "Азов",
    "Гуково",
    "Сальск",
    "Донецк",
    "Белая Калитва",
    "Рязань",
    "Касимов",
    "Самара",
    "Тольятти",
    "Сызрань",
    "Новокуйбышевск",
    "Чапаевск",
    "Жигулёвск",
    "Саратов",
    "Энгельс",
    "Балаково",
    "Балашов",
    "Вольск",
    "Якутск",
    "Нерюнгри",
    "Мирный",
    "Южно-Сахалинск",
    "Екатеринбург",
    "Нижний Тагил",
    "Каменск-Уральский",
    "Первоуральск",
    "Серов",
    "Новоуральск",
    "Асбест",
    "Полевской",
    "Ревда",
    "Краснотурьинск",
    "Владикавказ",
    "Моздок",
    "Смоленск",
    "Вязьма",
    "Ставрополь",
    "Пятигорск",
    "Кисловодск",
    "Ессентуки",
    "Минеральные Воды",
    "Будённовск",
    "Тамбов",
    "Мичуринск",
    "Казань",
    "Набережные Челны",
    "Нижнекамск",
    "Альметьевск",
    "Зеленодольск",
    "Бугульма",
    "Тверь",
    "Ржев",
    "Томск",
    "Северск",
    "Тула",
    "Новомосковск",
    "Узловая",
    "Кызыл",
    "Тюмень",
    "Тобольск",
    "Ижевск",
    "Сарапул",
    "Глазов",
    "Воткинск",
    "Ульяновск",
    "Димитровград",
    "Хабаровск",
    "Комсомольск-на-Амуре",
    "Амурск",
    "Абакан",
    "Черногорск",
    "Сургут",
    "Нижневартовск",
    "Нефтеюганск",
    "Ханты-Мансийск",
    "Челябинск",
    "Магнитогорск",
    "Златоуст",
    "Миасс",
    "Копейск",
    "Озёрск",
    "Троицк",
    "Снежинск",
    "Сатка",
    "Грозный",
    "Урус-Мартан",
    "Чебоксары",
    "Новочебоксарск",
    "Новый Уренгой",
    "Ноябрьск",
    "Ярославль",
    "Рыбинск",
    "Переславль-Залесский",
    "Москва",
    "Санкт-Петербург",
    "Абаза",
    "Абдулино",
    "Абинск",
    "Агидель",
    "Агрыз",
    "Адыгейск",
    "Азнакаево",
    "Ак-Довурак",
    "Аксай",
    "Алагир",
    "Алапаевск",
    "Алатырь",
    "Алдан",
    "Алейск",
    "Александровск",
    "Александровск-Сахалинский",
    "Алексеевка",
    "Алексин",
    "Алзамай",
    "Анадырь",
    "Андреаполь",
    "Анжеро-Судженск",
    "Анива",
    "Апрелевка",
    "Апшеронск",
    "Арамиль",
    "Аргун",
    "Ардатов",
    "Ардон",
    "Аркадак",
    "Арсеньев",
    "Арск",
    "Артёмовск",
    "Артёмовский",
    "Асино",
    "Аткарск",
    "Аша",
    "Бабаево",
    "Бабушкин",
    "Бавлы",
    "Багратионовск",
    "Байкальск",
    "Баймак",
    "Бакал",
    "Баксан",
    "Балабаново",
    "Балей",
    "Балтийск",
    "Барабинск",
    "Барыш",
    "Бежецк",
    "Белёв",
    "Белая Холуница",
    "Белебей",
    "Белинский",
    "Белово",
    "Белозерск",
    "Белокуриха",
    "Беломорск",
    "Белоусово",
    "Белоярский",
    "Белый",
    "Березовский (Кемеровская область)",
    "Березовский (Свердловская область)",
    "Беслан",
    "Бикин",
    "Билибино",
    "Бирск",
    "Бирюсинск",
    "Бирюч",
    "Благодарный",
    "Бобров",
    "Богданович",
    "Богородицк",
    "Богородск",
    "Боготол",
    "Богучар",
    "Бодайбо",
    "Болгар",
    "Бологое",
    "Болотное",
    "Болохово",
    "Болхов",
    "Большой Камень",
    "Борзя",
    "Боровск",
    "Бородино",
    "Бронницы",
    "Бугуруслан",
    "Буинск",
    "Буй",
    "Буйнакск",
    "Бутурлиновка",
    "Валдай",
    "Валуйки",
    "Велиж",
    "Великий Устюг",
    "Вельск",
    "Венёв",
    "Верещагино",
    "Верея",
    "Верхнеуральск",
    "Верхний Тагил",
    "Верхний Уфалей",
    "Верхняя Пышма",
    "Верхняя Салда",
    "Верхняя Тура",
    "Верхотурье",
    "Верхоянск",
    "Весьегонск",
    "Ветлуга",
    "Вилюйск",
    "Вихоревка",
    "Вичуга",
    "Волгореченск",
    "Володарск",
    "Волчанск",
    "Ворсма",
    "Вуктыл",
    "Высоковск",
    "Высоцк",
    "Вытегра",
    "Вышний Волочёк",
    "Вяземский",
    "Вязники",
    "Вятские Поляны",
    "Гаврилов Посад",
    "Гаврилов-Ям",
    "Гагарин",
    "Гаджиево",
    "Гай",
    "Галич",
    "Гвардейск",
    "Гдов",
    "Георгиевск",
    "Голицыно",
    "Горбатов",
    "Горнозаводск",
    "Горняк",
    "Городец",
    "Городище",
    "Городовиковск",
    "Гороховец",
    "Горячий Ключ",
    "Грайворон",
    "Гремячинск",
    "Грязи",
    "Грязовец",
    "Губаха",
    "Губкинский",
    "Гудермес",
    "Гулькевичи",
    "Гурьевск (Калининградская область)",
    "Гурьевск (Кемеровская область)",
    "Гусев",
    "Гусиноозёрск",
    "Давлеканово",
    "Дагестанские Огни",
    "Далматово",
    "Дальнегорск",
    "Дальнереченск",
    "Данилов",
    "Данков",
    "Дегтярск",
    "Демидов",
    "Десногорск",
    "Дивногорск",
    "Дигора",
    "Дмитриев",
    "Дмитровск",
    "Дно",
    "Добрянка",
    "Долинск",
    "Донской",
    "Дорогобуж",
    "Дрезна",
    "Дубовка",
    "Дудинка",
    "Духовщина",
    "Дюртюли",
    "Дятьково",
    "Елабуга",
    "Ельня",
    "Еманжелинск",
    "Емва",
    "Енисейск",
    "Ермолино",
    "Ершов",
    "Ефремов",
    "Железноводск",
    "Железногорск-Илимский",
    "Жердевка",
    "Жиздра",
    "Жирновск",
    "Жуков",
    "Жуковка",
    "Завитинск",
    "Заводоуковск",
    "Заволжск",
    "Заволжье",
    "Задонск",
    "Заинск",
    "Закаменск",
    "Заозёрный",
    "Заозёрск",
    "Западная Двина",
    "Заполярный",
    "Заречный (Свердловская область)",
    "Звенигово",
    "Звенигород",
    "Зверево",
    "Зеленоградск",
    "Зеленокумск",
    "Зерноград",
    "Зея",
    "Зима",
    "Злынка",
    "Змеиногорск",
    "Зубцов",
    "Зуевка",
    "Ивдель",
    "Игарка",
    "Избербаш",
    "Изобильный",
    "Иланский",
    "Инза",
    "Инсар",
    "Инта",
    "Ипатово",
    "Ирбит",
    "Исилькуль",
    "Искитим",
    "Ишим",
    "Ишимбай",
    "Кадников",
    "Калач",
    "Калачинск",
    "Калининск",
    "Калтан",
    "Калязин",
    "Камбарка",
    "Каменногорск",
    "Камень-на-Оби",
    "Камешково",
    "Камызяк",
    "Камышлов",
    "Канаш",
    "Кандалакша",
    "Карабаново",
    "Карабаш",
    "Карабулак",
    "Карасук",
    "Карачаевск",
    "Карачев",
    "Каргат",
    "Каргополь",
    "Карпинск",
    "Карталы",
    "Касли",
    "Каспийск",
    "Катав-Ивановск",
    "Катайск",
    "Качканар",
    "Кашин",
    "Кедровый",
    "Кемь",
    "Кизел",
    "Кизилюрт",
    "Кизляр",
    "Кимовск",
    "Кимры",
    "Кинель",
    "Киреевск",
    "Киренск",
    "Кириллов",
    "Киров (Калужская область)",
    "Кировград",
    "Кировск (Мурманская область)",
    "Кирс",
    "Кирсанов",
    "Княгинино",
    "Ковдор",
    "Ковылкино",
    "Когалым",
    "Кодинск",
    "Козельск",
    "Козловка",
    "Козьмодемьянск",
    "Кола",
    "Кологрив",
    "Колпашево",
    "Кольчугино",
    "Комсомольск",
    "Конаково",
    "Кондрово",
    "Константиновск",
    "Кораблино",
    "Кореновск",
    "Коркино",
    "Короча",
    "Корсаков",
    "Коряжма",
    "Костерёво",
    "Костомукша",
    "Котельниково",
    "Котельнич",
    "Котово",
    "Котовск",
    "Кохма",
    "Красавино",
    "Красноармейск (Саратовская область)",
    "Красновишерск",
    "Краснозаводск",
    "Краснослободск (Волгоградская область)",
    "Краснослободск (Мордовия)",
    "Красноуральск",
    "Красноуфимск",
    "Красный Кут",
    "Красный Сулин",
    "Красный Холм",
    "Кремёнки",
    "Крымск",
    "Кувандык",
    "Кувшиново",
    "Кудымкар",
    "Кулебаки",
    "Кумертау",
    "Купино",
    "Курганинск",
    "Курильск",
    "Курлово",
    "Куровское",
    "Куртамыш",
    "Куса",
    "Кушва",
    "Кыштым",
    "Кяхта",
    "Лабинск",
    "Лабытнанги",
    "Лагань",
    "Ладушкин",
    "Лаишево",
    "Лакинск",
    "Лангепас",
    "Лахденпохья",
    "Лебедянь",
    "Лениногорск",
    "Ленинск",
    "Ленск",
    "Лермонтов",
    "Лесной",
    "Лесозаводск",
    "Лесосибирск",
    "Липки",
    "Лихославль",
    "Луза",
    "Лукоянов",
    "Лысково",
    "Льгов",
    "Любань",
    "Любим",
    "Людиново",
    "Лянтор",
    "Магас",
    "Майский",
    "Макаров",
    "Макарьев",
    "Макушино",
    "Малая Вишера",
    "Малгобек",
    "Малмыж",
    "Малоархангельск",
    "Малоярославец",
    "Мамадыш",
    "Мамоново",
    "Мантурово",
    "Мариинск",
    "Мариинский Посад",
    "Маркс",
    "Мглин",
    "Мегион",
    "Медвежьегорск",
    "Медногорск",
    "Медынь",
    "Межгорье",
    "Мезень",
    "Меленки",
    "Мелеуз",
    "Менделеевск",
    "Мензелинск",
    "Мещовск",
    "Микунь",
    "Миллерово",
    "Минусинск",
    "Миньяр",
    "Михайлов",
    "Михайловка",
    "Михайловск (Свердловская область)",
    "Михайловск (Ставропольский край)",
    "Могоча",
    "Можга",
    "Мончегорск",
    "Морозовск",
    "Моршанск",
    "Мосальск",
    "Муравленко",
    "Мураши",
    "Мыски",
    "Мышкин",
    "Навашино",
    "Наволоки",
    "Надым",
    "Назарово",
    "Называевск",
    "Нариманов",
    "Нарткала",
    "Невель",
    "Невельск",
    "Невинномысск",
    "Невьянск",
    "Нелидово",
    "Неман",
    "Нерехта",
    "Нерчинск",
    "Нестеров",
    "Нефтегорск",
    "Нефтекумск",
    "Нея",
    "Нижнеудинск",
    "Нижние Серги",
    "Нижний Ломов",
    "Нижняя Салда",
    "Нижняя Тура",
    "Николаевск",
    "Николаевск-на-Амуре",
    "Никольск (Вологодская область)",
    "Никольск (Пензенская область)",
    "Новая Ладога",
    "Новая Ляля",
    "Новоалександровск",
    "Новоаннинский",
    "Нововоронеж",
    "Новодвинск",
    "Новозыбков",
    "Новокубанск",
    "Новомичуринск",
    "Новопавловск",
    "Новоржев",
    "Новосиль",
    "Новосокольники",
    "Новоузенск",
    "Новоульяновск",
    "Новохопёрск",
    "Нолинск",
    "Нурлат",
    "Нытва",
    "Нюрба",
    "Нягань",
    "Нязепетровск",
    "Няндома",
    "Облучье",
    "Обоянь",
    "Обь",
    "Ожерелье",
    "Октябрьск",
    "Окуловка",
    "Олёкминск",
    "Оленегорск",
    "Олонец",
    "Омутнинск",
    "Онега",
    "Опочка",
    "Орлов",
    "Оса",
    "Осинники",
    "Осташков",
    "Остров",
    "Островной",
    "Острогожск",
    "Отрадный",
    "Оха",
    "Оханск",
    "Очёр",
    "Палласовка",
    "Партизанск",
    "Певек",
    "Первомайск",
    "Перевоз",
    "Пересвет",
    "Пестово",
    "Петров Вал",
    "Петровск",
    "Петровск-Забайкальский",
    "Петухово",
    "Петушки",
    "Печоры",
    "Пионерский",
    "Питкяранта",
    "Плёс",
    "Плавск",
    "Пласт",
    "Поворино",
    "Покачи",
    "Покров",
    "Покровск",
    "Полесск",
    "Полысаево",
    "Полярные Зори",
    "Полярный",
    "Поронайск",
    "Порхов",
    "Похвистнево",
    "Почеп",
    "Починок",
    "Пошехонье",
    "Правдинск",
    "Приволжск",
    "Приморск (Калининградская область)",
    "Приморск (Ленинградская область)",
    "Приморско-Ахтарск",
    "Пролетарск",
    "Пугачёв",
    "Пудож",
    "Пустошка",
    "Пучеж",
    "Пыталово",
    "Пыть-Ях",
    "Радужный (Владимирская область)",
    "Радужный (Ханты-Мансийский АО - Югра)",
    "Райчихинск",
    "Рассказово",
    "Реж",
    "Родники",
    "Рославль",
    "Ростов",
    "Ртищево",
    "Рудня",
    "Руза",
    "Рыбное",
    "Рыльск",
    "Ряжск",
    "Салаир",
    "Салехард",
    "Сасово",
    "Сафоново",
    "Саяногорск",
    "Саянск",
    "Светлогорск",
    "Светлоград",
    "Светлый",
    "Свирск",
    "Себеж",
    "Северо-Курильск",
    "Североуральск",
    "Севск",
    "Сегежа",
    "Сельцо",
    "Семёнов",
    "Семикаракорск",
    "Семилуки",
    "Сенгилей",
    "Серафимович",
    "Сергач",
    "Сердобск",
    "Сибай",
    "Сим",
    "Сковородино",
    "Скопин",
    "Славгород",
    "Славск",
    "Славянск-на-Кубани",
    "Слободской",
    "Слюдянка",
    "Снежногорск",
    "Собинка",
    "Советск (Кировская область)",
    "Советск (Тульская область)",
    "Советская Гавань",
    "Советский",
    "Солигалич",
    "Соль-Илецк",
    "Сольвычегодск",
    "Сольцы",
    "Сорочинск",
    "Сорск",
    "Сортавала",
    "Сосенский",
    "Сосновка",
    "Сосновоборск",
    "Сосногорск",
    "Спас-Деменск",
    "Спас-Клепики",
    "Спасск",
    "Спасск-Дальний",
    "Спасск-Рязанский",
    "Среднеколымск",
    "Среднеуральск",
    "Сретенск",
    "Старица",
    "Стародуб",
    "Стрежевой",
    "Строитель",
    "Струнино",
    "Суворов",
    "Суджа",
    "Судогда",
    "Суздаль",
    "Суоярви",
    "Сураж",
    "Суровикино",
    "Сурск",
    "Сусуман",
    "Сухиничи",
    "Сухой Лог",
    "Сысерть",
    "Сычёвка",
    "Тавда",
    "Тайга",
    "Тайшет",
    "Талдом",
    "Талица",
    "Тара",
    "Тарко-Сале",
    "Таруса",
    "Татарск",
    "Таштагол",
    "Теберда",
    "Тейково",
    "Темников",
    "Темрюк",
    "Терек",
    "Тетюши",
    "Тимашевск",
    "Тогучин",
    "Томари",
    "Томмот",
    "Топки",
    "Торжок",
    "Торопец",
    "Тотьма",
    "Трёхгорный",
    "Трубчевск",
    "Туймазы",
    "Тулун",
    "Туран",
    "Туринск",
    "Тутаев",
    "Тында",
    "Тырныауз",
    "Тюкалинск",
    "Уварово",
    "Углегорск",
    "Углич",
    "Удачный",
    "Удомля",
    "Ужур",
    "Унеча",
    "Урай",
    "Урень",
    "Уржум",
    "Урюпинск",
    "Усинск",
    "Усмань",
    "Усолье",
    "Усолье-Сибирское",
    "Усть-Джегута",
    "Усть-Катав",
    "Усть-Кут",
    "Усть-Лабинск",
    "Устюжна",
    "Учалы",
    "Уяр",
    "Фатеж",
    "Фокино (Брянская область)",
    "Фокино (Приморский край)",
    "Фролово",
    "Фурманов",
    "Хадыженск",
    "Харабали",
    "Харовск",
    "Хвалынск",
    "Хилок",
    "Холм",
    "Холмск",
    "Хотьково",
    "Цивильск",
    "Цимлянск",
    "Чёрмоз",
    "Чадан",
    "Чаплыгин",
    "Чебаркуль",
    "Чегем",
    "Чекалин",
    "Чердынь",
    "Черемхово",
    "Черепаново",
    "Чернушка",
    "Черняховск",
    "Чистополь",
    "Чкаловск",
    "Чудово",
    "Чулым",
    "Чусовой",
    "Чухлома",
    "Шагонар",
    "Шали",
    "Шарыпово",
    "Шарья",
    "Шахтёрск",
    "Шахунья",
    "Шацк",
    "Шелехов",
    "Шенкурск",
    "Шилка",
    "Шимановск",
    "Шиханы",
    "Шумерля",
    "Шумиха",
    "Щёкино",
    "Щигры",
    "Щучье",
    "Эртиль",
    "Югорск",
    "Южа",
    "Южно-Сухокумск",
    "Южноуральск",
    "Юрьев-Польский",
    "Юрьевец",
    "Юрюзань",
    "Юхнов",
    "Ядрин",
    "Ялуторовск",
    "Янаул",
    "Яранск",
    "Яровое",
    "Ярцево",
    "Ясногорск",
    "Ясный",
    "Яхрома",
    "Алупка",
    "Алушта",
    "Армянск",
    "Бахчисарай",
    "Белогорск (Крым",
    "Джанкой",
    "Евпатория",
    "Инкерман",
    "Иннополис",
    "Керчь",
    "Краснознаменск (Калининградская область)",
    "Красноперекопск",
    "Мирный (Архангельская область)",
    "Озерск (Калининградская область)",
    "Саки",
    "Севастополь",
    "Симферополь",
    "Старый Крым",
    "Судак",
    "Циолковский",
    "Феодосия",
    "Щелкино",
    "Ялта",
];
