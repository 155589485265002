import { UIEventHandler, useState } from "react";
import { Modal, ModalProps } from "../Modal/Modal";
import { Button } from "../Button/Button";

import styles from "./PersonalDataModal.module.scss";

interface PersonalDataAgreementModalProps extends ModalProps {
    onAccept: () => void;
}

export const PersonalDataAgreementModal = (props: PersonalDataAgreementModalProps) => {
    const { children, onClose, status, onAccept } = props;
    const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);

    const handleScroll: UIEventHandler<HTMLDivElement> = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLDivElement;
        if (scrollTop + clientHeight >= scrollHeight - 200) {
            setHasScrolledToEnd(true);
        }
    };

    const handleAccept = () => {
        onAccept();
        onClose();
    };

    return (
        <Modal status={status} onClose={onClose}>
            <div className={styles.personalDataAgreementModal}>
                <button onClick={onClose} type={"button"} className={styles.personalDataAgreementModal__close}></button>
                <h2>Согласие субъекта персональных данных на обработку персональных данных</h2>
                <div className={styles.personalDataAgreementModal__content} onScroll={handleScroll}>
                    {children}
                </div>
                <div className={styles.personalDataAgreementModal__buttonsWrapper}>
                    <Button circle={false} disabled={!hasScrolledToEnd} onClick={handleAccept}>
                        Принять
                    </Button>
                    <Button circle={false} theme={"other"} onClick={onClose}>
                        Закрыть
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
