import styles from "./BooleanRadioInput.module.scss";
import { classNames } from "../../../../../shared/lib/classNames";
import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import { Checkbox } from "../Checkbox/Checkbox";

export interface BooleanRadioInputProps
    extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "onChange" | "type"> {
    className?: string;
    value: boolean;
    onChange: (value: boolean) => void;
}

export const BooleanRadioInput = forwardRef(
    ({ className, value, onChange, ...otherProps }: BooleanRadioInputProps, ref: ForwardedRef<HTMLInputElement>) => {
        return (
            <div className={classNames({ cls: styles.booleanRadioInput, additional: [className] })}>
                <Checkbox
                    type="radio"
                    onChange={() => onChange(true)} // send value to hook form
                    checked={value}
                    {...otherProps}
                >
                    Да
                </Checkbox>
                <Checkbox
                    type="radio"
                    onChange={() => onChange(false)} // send value to hook form
                    checked={!value}
                    ref={ref}
                    {...otherProps}
                >
                    Нет
                </Checkbox>
            </div>
        );
    },
);
