import styles from "./DescriptionSkeleton.module.scss";
import { buildSequentialArray } from "../../../../lib/helpers";
import { Skeleton } from "../Skeleton/Skeleton";

interface DescriptionSkeletonProps {
    items: number;
}

export const DescriptionSkeleton = ({ items }: DescriptionSkeletonProps) => {
    return (
        <div className={styles.skeleton}>
            {buildSequentialArray(items).map((item) => (
                <Skeleton className={styles[`skeleton__item_${item + 1}`]} key={item} />
            ))}
        </div>
    );
};
