import { BackButton, Skeleton } from "../../../../shared/ui";
import { classNames } from "../../../../shared/lib/classNames";

import styles from "./VacancyTitle.module.scss";

interface VacancyTitleProps {
    className?: string;
    name: string;
    countries: string[];
    isLoading?: boolean;
}
export const VacancyTitle = ({ className, name, countries, isLoading = false }: VacancyTitleProps) => {
    return (
        <div className={classNames({ cls: styles.vacancyTitle, additional: [className] })}>
            <BackButton theme={"dark"} />
            {isLoading ? (
                <>
                    <Skeleton className={styles.skeleton__name} />
                    <Skeleton className={styles.skeleton__location} />
                </>
            ) : (
                <>
                    <p className={styles.vacancyTitle__name}>{name}</p>
                    <p className={styles.vacancyTitle__location}>{countries}</p>
                </>
            )}
        </div>
    );
};
