import styles from "./TextField.module.scss";
import { classNames } from "../../../../../shared/lib/classNames";
import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";

import { ErrorValidate } from "../ErrorValidate/ErrorValidate";

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    errorMessage?: string;
    label?: string;
}

export const TextField = forwardRef((props: TextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { label, className, errorMessage, required, ...otherProps } = props;
    const cls = classNames({
        cls: styles.textField__input,
        mods: { [styles.textField__input_error]: Boolean(errorMessage) },
    });
    return (
        <label className={classNames({ cls: styles.textField, additional: [className] })}>
            {label && (
                <p className={styles.textField__label}>
                    {label} {required && <span>*</span>}
                </p>
            )}
            <input className={cls} ref={ref} {...otherProps} />
            <ErrorValidate errorMessage={errorMessage} />
        </label>
    );
});
