import React from "react";
import { Link } from "react-router-dom";
import { Container, IconButton, SliceBorder, Title } from "../../../shared/ui";
import { LogoHeader } from "../../../shared/assets/svg";

import { bannerLna } from "../../../shared/assets/img";

import cls from "./Header.module.scss";
import { useMediaQuery } from "../../../shared/hooks/useMediaQuery";
import { generateMediaQuery } from "../../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../../shared/constants/media";

export const Header = ({ route }: { route: string }) => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.lg));

    return (
        <div className={cls.header__wrapper}>
            <Container marginSize={matches ? "standard" : undefined} className={cls.content}>
                <div className={cls.header}>
                    <div className={cls.content}>
                        <Link className={cls.link} to={`/candidates/${route}`}>
                            <LogoHeader />
                        </Link>
                    </div>
                </div>
                {matches && (
                    <Title className={cls.directionMain__title} level={3}>
                        Добро пожаловать&nbsp;в&nbsp;БКС!
                    </Title>
                )}
                <div className={cls.directionMain__content}>
                    <div className={cls.directionMain__textWrapper}>
                        {!matches && (
                            <SliceBorder
                                sizeRadius={50}
                                border={{
                                    bottomRight: "inside",
                                    topRight: "outside-corner-lateral",
                                    bottomLeft: "outside-corner-upper",
                                }}
                            >
                                <Title className={cls.directionMain__title} level={3}>
                                    Добро пожаловать&nbsp;в&nbsp;БКС!
                                </Title>
                            </SliceBorder>
                        )}
                        <div className={cls.directionMain__descriptionWrapper}>
                            <p className={cls.directionMain__description}>
                                <p>
                                    Первые рабочие дни всегда наполнены событиями,
                                    <br />
                                    впечатлениями и большим количеством новой информации.
                                    <br />
                                </p>
                                <br />
                                <p>
                                    Предлагаем ознакомиться с важной информацией заранее.
                                    <br />
                                </p>
                            </p>
                        </div>
                    </div>
                    <div className={cls.directionMain__imageWrapper}>
                        <img src={bannerLna} className={cls.directionMain__image} alt={"imgPoster"} />
                    </div>
                    {!matches && (
                        <SliceBorder
                            sizeRadius={28}
                            className={cls.directionMain__subscribeWrapper}
                            multiplierInside={1.2}
                            border={{
                                topRight: "inside",
                                topLeft: "inside",
                                bottomLeft: "outside-corner-lateral",
                                bottomRight: "outside-corner-lateral",
                            }}
                        >
                            <a href="#doc">
                                <IconButton icon={"subscribe"} text={"Ознакомиться"} textPosition={"left"} />
                            </a>
                        </SliceBorder>
                    )}
                </div>
                {matches && (
                    <div className={cls.directionMain__subscribe}>
                        <a href="#doc">
                            <IconButton icon={"subscribe"} text={"Ознакомиться"} textPosition={"left"} />
                        </a>
                    </div>
                )}
            </Container>
        </div>
    );
};
