import { Container } from "../../shared/ui";

import { stages } from "./consts/stages";

import styles from "./InvestmentStage.module.scss";

export const InvestmentStage = () => {
    return (
        <Container marginSize="large" className={styles.investmentStage}>
            <div className={styles.investmentStage__stageList}>
                {stages.map((stage) => (
                    <div key={stage.stage} className={styles.investmentStage__stageItem}>
                        <p className={styles.investmentStage__stageNumber}>{stage.stage}</p>
                        <div>
                            <p className={styles.investmentStage__stageTitle}>{stage.title}</p>
                            <p className={styles.investmentStage__stageDesc}>{stage.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </Container>
    );
};
