import { Container, Button, Title } from "../../shared/ui";

import { classNames } from "../../shared/lib/classNames";
import { imgBannerOne, imgBannerThree, imgBannerTwo } from "../../shared/assets/img";
import { getRouteAboutRecruiting, RoutesEnum } from "../../shared/constants/routes";

import { anchorURL } from "../../shared/lib/helpers";
import { AnchorsEnum } from "../../shared/constants/anchors";

import styles from "./BannerGetSettled.module.scss";
import { Link } from "react-router-dom";

export const BannerGetSettled = () => {
    return (
        <Link to={getRouteAboutRecruiting()}>
            <Container className={styles.container} marginSize="standard">
                <div className={styles.banner}>
                    <img
                        className={classNames({
                            cls: styles.banner__imgBanner,
                            additional: [styles.banner__imgBanner_one],
                        })}
                        src={imgBannerOne}
                        alt={"imgBannerOne"}
                    />
                    <img
                        className={classNames({
                            cls: styles.banner__imgBanner,
                            additional: [styles.banner__imgBanner_two],
                        })}
                        src={imgBannerTwo}
                        alt={"imgBannerTwo"}
                    />
                    <img
                        className={classNames({
                            cls: styles.banner__imgBanner,
                            additional: [styles.banner__imgBanner_three],
                        })}
                        src={imgBannerThree}
                        alt={"imgBannerThree"}
                    />

                    <Title className={styles.banner__title} level={3}>
                        Стать частью
                        <br />
                        команды
                    </Title>
                </div>
            </Container>
        </Link>
    );
};
