import { useNavigate } from "react-router-dom";
import { IconButton, IconTheme } from "../IconButton/IconButton";

interface BackButtonProps {
    theme?: IconTheme;
    className?: string;
}
export const BackButton = ({ theme, className }: BackButtonProps) => {
    const navigate = useNavigate();
    return <IconButton className={className} onClick={() => navigate(-1)} theme={theme} icon={"left"} text={"Назад"} />;
};
