import { FunctionComponent, ReactNode, SVGProps } from "react";
import { Link } from "react-router-dom";
import { SliceBorder } from "../../../SliceBorder/SliceBorder";
import { IconButton } from "../../../IconButton/IconButton";

import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { generateMediaQuery, searchQueryURL } from "../../../../lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../../../constants/media";
import { RoutesEnum } from "../../../../constants/routes";

import styles from "./StepCard.module.scss";

interface StepCardProps {
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    title: string;
    description: string | ReactNode;
    step: string;
}

export const StepCard = ({ Icon, title, step, description }: StepCardProps) => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.sm));

    return (
        <div className={styles.wrapper}>
            <p className={styles.header}>Шаг {step}.</p>
            <div className={styles.step}>
                <div className={styles.step__container}>
                    <div>
                        <Icon className={styles.step__svg} />
                        <p className={styles.step__title}>{title}</p>
                    </div>
                    <p className={styles.step__description}>{description}</p>
                    <SliceBorder
                        colorBackground={"#d8e3ed"}
                        className={styles.step__wrapperButton}
                        sizeRadius={20}
                        multiplierInside={matches ? 1 : 1.4}
                        border={{
                            topRight: "outside-corner-upper",
                            bottomLeft: "outside-corner-lateral",
                            topLeft: "inside",
                        }}
                    >
                        <Link
                            className={styles.step__link}
                            preventScrollReset
                            to={searchQueryURL(RoutesEnum.AboutRecruiting, { step })}
                        >
                            <IconButton icon={"right"} theme={"lightStrokeBlue"} />
                        </Link>
                    </SliceBorder>
                </div>
            </div>
        </div>
    );
};
