import { ReactNode } from "react";

import { classNames } from "../../lib/classNames";

import styles from "./Container.module.scss";

type MarginSize = "standard" | "large" | "";
interface ContainerProps {
    children: ReactNode;
    id?: string;
    className?: string;
    marginSize?: MarginSize;
}

export const Container = ({ children, className, marginSize = "", id }: ContainerProps) => {
    return (
        <section id={id} className={classNames({ cls: styles.container, additional: [className] })}>
            <div
                className={classNames({
                    cls: styles.container__content,
                    mods: { [styles[marginSize]]: marginSize },
                })}
            >
                {children}
            </div>
        </section>
    );
};
