import { createSlice } from "@reduxjs/toolkit";
import { type FormSchema } from "../types/formSchema";

import { formDetailAsync } from "../services/formDetailAsync";
import { formUpdateAsync } from "../services/formUpdateAsync";

export const initialState: FormSchema = { form: {}, status: "init" };

const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(formDetailAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(formDetailAsync.rejected, (state) => {
                state.status = "reject";
            })
            .addCase(formDetailAsync.fulfilled, (state, { payload }) => {
                state.status = "fulfilled";
                state.form = payload;
            })
            .addCase(formUpdateAsync.fulfilled, (state, { payload }) => {
                state.form = payload;
            });
    },
});

export const formActions = formSlice.actions;
export const formReducer = formSlice.reducer;
