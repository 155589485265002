import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Header } from "../Header/Header";
import { StageItem } from "../StageItem/StageItem";
import { Footer } from "../Footer/Footer";
import { Loader } from "../../../shared/ui";

import { PersonnelDocument, PersonnelDocuments } from "../../../shared/ApiContract/data-contracts";
import { lnaDocuments } from "../../../shared/api/api";

import cls from "./Candidate.module.scss";

const Candidate = () => {
    const [data, setData] = useState<PersonnelDocuments | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const getLnaDocuments = async () => {
        try {
            setIsLoading(true);
            const data = await lnaDocuments.lnaDocumentsGetByRoutingDetail(currentPath);
            if (data?.data?.data) {
                setData(data.data.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    const currentPath = window.location.pathname.split("/")[2];

    useEffect(() => {
        getLnaDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={cls.wrapper}>
            <Header route={currentPath} />
            <div className={cls.sectionStage}>
                <div className={cls.content}>
                    <div className={cls.stage}>
                        <h2 className={cls.stage_title}>что ожидает тебя в&nbsp;первый рабочий день*</h2>
                        <div className={cls.stage_items}>
                            <div className={cls.stage_item}>
                                <StageItem number="1" name="Начало рабочего дня">
                                    <p className={cls.stage_item__text}>
                                        Ждем тебя в&nbsp;назначенное время в зоне ресепшн.&nbsp;Возьми с собой документы для оформления, для твоего удобства мы разместили список &nbsp;
                                        <Link className={cls.item_link} to={"/sd"} state={{ prevPath: currentPath }}>
                                            здесь.
                                        </Link>
                                    </p>
                                </StageItem>
                            </div>

                            <div className={cls.stage_item}>
                                <StageItem number="2" name="Знакомство с компанией">
                                    <p className={cls.stage_item__text}>
                                        Тебя ждет рассказ о компании – в офисе в первый день или в дистанционном формате (мы пришлем приглашение). На welcome-тренинге ты узнаешь больше о нашей компании, корпоративной жизни, компенсациях и льготах для сотрудников.
                                    </p>
                                </StageItem>
                            </div>

                            <div className={cls.stage_item}>
                                <StageItem number="3" name="Оформление кадровых документов">
                                    <p className={cls.stage_item__text}>
                                        При подписании документов во всех вопросах тебе помогут специалисты отдела кадров.  Ты можешь сэкономить время и заранее ознакомиться с локальными нормативными актами компании (ЛНА) <a className={cls.item_link} href="#portal">здесь.</a>
                                    </p>
                                </StageItem>
                            </div>

                            <div className={cls.stage_item}>
                                <StageItem number="4" name="Знакомство с командой">
                                    <p className={cls.stage_item__text}>
                                        После оформления руководитель познакомит тебя с командой и расскажет о рабочем процессе.
                                    </p>
                                </StageItem>
                            </div>
                        </div>
                    </div>
                    <i>*Если согласован иной график приема, придерживайтесь договоренностей.</i>
                </div>
            </div>
            <div className={cls.section_doc} id="doc">
                <div className={cls.content}>
                    <div className={cls.documents}>
                        <h2 id="portal" className={cls.documents_title}>список нормативных документов</h2>
                        <p className={cls.documents_description}>
                            Чтобы в&nbsp;первый день не&nbsp;тратить ценное время на&nbsp;чтение нормативных документов,
                            предлагаем ознакомиться с&nbsp;ними заранее
                        </p>
                        <div className={cls.documents_list}>
                            {isLoading ? (
                                <Loader className={cls.loader} />
                            ) : (
                                data?.documents &&
                                data?.documents
                                    .sort((a: PersonnelDocument, b: PersonnelDocument) => a.number! - b.number!)
                                    .map((item: PersonnelDocument) => (
                                        <Link
                                            key={item.id}
                                            className={cls.documents_list__item}
                                            to={`/document/${currentPath}/${item.id}`}
                                        >
                                            <div>{item.name}</div>
                                        </Link>
                                    ))
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Candidate;
