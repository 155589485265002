import { Container } from "../../../../shared/ui";
import { QueryVacancy } from "../QueryVacancy/QueryVacancy";
import { QueryDirectionVacancy } from "../QueryDirectionVacancy/QueryDirectionVacancy";

import { classNames } from "../../../../shared/lib/classNames";
import { FormVacancy } from "../../types/form";

import styles from "./SearchVacancies.module.scss";

interface SearchVacanciesProps {
    className?: string;
    defaultValues?: FormVacancy;
    onSubmit: (data: FormVacancy) => void;
}

export const SearchVacancies = ({ className, defaultValues, onSubmit }: SearchVacanciesProps) => {
    return (
        <Container
            marginSize={"large"}
            className={classNames({ cls: styles.searchVacancies, additional: [className] })}
        >
            <div className={styles.searchVacancies__form}>
                <p className={styles.searchVacancies__text}>Ищите свою вакансию</p>
                <QueryVacancy onSubmit={onSubmit} defaultValues={defaultValues?.Name} />
                <p className={styles.searchVacancies__text}>или начните с направления деятельности</p>
                <QueryDirectionVacancy onSubmit={onSubmit} defaultValues={defaultValues} />
            </div>
        </Container>
    );
};
