import React, { useEffect, useState } from "react";

import cls from "./Document.module.scss";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { LnaDocuments } from "../../../shared/ApiContract/LnaDocuments";
import { PersonnelDocument, PersonnelDocumentPage } from "../../../shared/ApiContract/data-contracts";
import { Loader } from "../../../shared/ui";
import { API_URL } from "../../../shared/constants/api";

const api = new LnaDocuments({
    baseUrl: API_URL,
});
const Document = () => {
    const [documents, setDocuments] = useState<PersonnelDocument[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const currentPath = window.location.pathname.split("/")[2];
    const documentId = window.location.pathname.split("/")[3];

    const getLnaDocuments = async () => {
        try {
            setIsLoading(true);
            const data = await api.lnaDocumentsGetByRoutingDetail(currentPath);
            const documents = data.data?.data?.documents?.filter((x) => x.id === documentId);
            if (documents) {
                setDocuments(documents);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getLnaDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={cls.wrapper}>
            <Header route={currentPath} />
            <div className={cls.section}>
                <div className={cls.content}>
                    {isLoading ? (
                        <div className={cls.loader_wrapper}>
                            <Loader className={cls.loader} />
                        </div>
                    ) : (
                        documents &&
                        documents[0].documentPages
                            ?.sort(
                                (a: PersonnelDocumentPage, b: PersonnelDocumentPage) => a.pageNumber! - b.pageNumber!,
                            )
                            .map((page: PersonnelDocumentPage) => (
                                <img
                                    key={page?.file?.id}
                                    src={`${API_URL}/api/lna-documents/file/${page?.file?.id}`}
                                    onContextMenu={() => false}
                                    alt="personnelDocument"
                                />
                            ))
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Document;
