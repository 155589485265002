import React, { useState } from "react";
import { Button, Modal, TextField } from "../../../shared/ui";

import styles from "./NewsletterModal.module.scss";

interface NewsletterModalProps {
    isOpen: boolean;
    onClose: () => void;
}

function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
}

export const NewsletterModal: React.FC<NewsletterModalProps> = ({ isOpen, onClose }) => {
    const [isSubscribe, setIsSubscribe] = useState(false);
    const [isErrorValid, setIsErrorValid] = useState(false);
    const [email, setEmail] = useState("");

    const subscribeHandler = () => {
        if (isValidEmail(email)) {
            setIsSubscribe(true);
            setTimeout(() => {
                onCloseHandler();
            }, 3000);
        } else {
            setIsErrorValid(true);
        }
    };
    const onCloseHandler = () => {
        setEmail("");
        setIsErrorValid(false);
        setIsSubscribe(false);
        onClose();
    };
    const onChangeEmail = (value: string) => {
        setIsErrorValid(false);
        setEmail(value);
    };
    return (
        <Modal isOpen={isOpen}>
            {isSubscribe ? (
                <div className={styles.follow}>
                    <h2 className={styles.follow__header}>Подписка оформлена. Ждите новых вакансий.</h2>
                </div>
            ) : (
                <div className={styles.newsletter}>
                    <h2 className={styles.newsletter__header}>Рассылка БКС: лучшие вакансии — у вас в почте</h2>
                    <TextField
                        value={email}
                        onChange={(e) => onChangeEmail(e.target.value)}
                        className={styles.newsletter__input}
                        label="Ваша почта"
                        errorMessage={
                            isErrorValid
                                ? email.length > 0
                                    ? "Некорректный email"
                                    : "Необходимо заполнить поле"
                                : undefined
                        }
                    />
                    <div className={styles.newsletter__control}>
                        <Button as={"button"} theme="gray" onClick={onCloseHandler}>
                            Отменить
                        </Button>
                        <Button as={"button"} onClick={subscribeHandler} theme="blue">
                            Подписаться
                        </Button>
                    </div>
                    <p className={styles.newsletter__text}>
                        Нажимая кнопку «Подписаться», вы соглашаетесь c{" "}
                        <a
                            className={styles.newsletter__link}
                            href="https://www.afisha.ru/article/pravila-rassylki-afisharu/"
                        >
                            Правилами рассылок
                        </a>
                    </p>
                </div>
            )}
        </Modal>
    );
};
