import styles from "./TeamList.module.scss";
import { Container, Skeleton } from "../../../../shared/ui/index";
import { TeamItem, TeamItemSkeleton } from "../TeamItem/TeamItem";
import { useGetVacancyListQuery } from "../../../Vacancy";
import { useEffect, useState } from "react";
import { useGetDirectionsLongListQuery } from "../../api/directionsApi";
import declOfNum, { declOfNum2case } from "../../../../shared/lib/declOfNum";

interface DirectionListProps {
    idDirection: string | null;
}

export const TeamList = ({ idDirection }: DirectionListProps) => {
    const { data, isLoading } = useGetVacancyListQuery({ DirectionId: idDirection });
    const { data: directionMainList } = useGetDirectionsLongListQuery();
    const [parcedData, setParcedData] = useState<any>({});

    useEffect(() => {
        if (data) {
            const teams: any = {};
            data.forEach((el) => {
                if (teams[el.teamId!]) {
                    teams[el.teamId!].push(el);
                } else {
                    teams[el.teamId!] = [];
                    teams[el.teamId!].push(el);
                }
            });
            setParcedData(teams);
        }
    }, [data]);
    if (isLoading) {
        return <TeamListSkeleton />;
    }

    return (
        <Container marginSize="large" className={styles.teamList}>
            <p className={styles.teamList__text}>
                <span>{data?.length || 0}</span> {declOfNum(data?.length || 0, [" вакансия", " вакансии", " вакансий"])}{" "}
                в <span>{Object.values(parcedData).length}</span>{" "}
                {declOfNum2case(Object.values(parcedData).length, [" команде", " командах"])} по выбранному направлению
            </p>
            <div className={styles.teamList__list}>
                {Object.keys(parcedData).map((item) => (
                    <TeamItem
                        link={`/vacancies?DirectionId=${parcedData[item][0].directionId}&TeamId=${item}`}
                        name={parcedData[item] && parcedData[item][0]?.teamName}
                        count={parcedData[item].length}
                        key={item}
                        direction={
                            directionMainList?.find((el) => el.id === parcedData[item][0].directionId)?.shortName
                        }
                    />
                ))}
            </div>
        </Container>
    );
};

const TeamListSkeleton = () => {
    return (
        <Container marginSize="large" className={styles.teamList}>
            <Skeleton className={styles.skeleton}></Skeleton>
            <div className={styles.teamList__list}>
                {[...Array(5)].map((_, index) => (
                    <TeamItemSkeleton key={index} />
                ))}
            </div>
        </Container>
    );
};
