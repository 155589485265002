import { slideColorsJobs } from "../constants/colorSlideJobs";
import { RoutesEnum } from "../constants/routes";
import { SearchParamsEnum } from "../constants/searchParams";
import { AnchorsEnum } from "../constants/anchors";
import { MediaQueryProperty, MediaSizeEnum } from "../constants/media";

export function removeNullKeys(obj: Record<string, any>): Record<string, any> {
    const result: Record<string, any> = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key) && Boolean(obj[key])) {
            result[key] = obj[key];
        }
    }

    return result;
}

export function addCommaIfNeeded<T extends string>(str: T, index: number, arr: T[]) {
    if (index !== arr.length - 1) {
        return str + ",";
    } else {
        return str;
    }
}

export function byOrderColorCss(num: number) {
    return `var(${slideColorsJobs[num]})`;
}

export function searchQueryURL(url: RoutesEnum, search: Partial<Record<SearchParamsEnum, any>>): string {
    const searchParams = new URLSearchParams(removeNullKeys(search));

    return `/${url}?${searchParams}`;
}

export function anchorURL(url: RoutesEnum, anchor: AnchorsEnum): string {
    return `/${url}#${anchor}`;
}

export function formatDate(
    dateString: string | Date,
    options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "long",
        year: "numeric",
    },
) {
    const date = new Date(dateString);
    return date.toLocaleDateString("ru-RU", options);
}

export function currentFullYear(): number {
    return new Date().getFullYear();
}

export function generateMediaQuery(property: MediaQueryProperty, size: MediaSizeEnum): string {
    return `(${property}: ${size})`;
}

export function buildSequentialArray(n: number) {
    return Array.from({ length: n }, (_, index) => index);
}

export function transferDanglingPrepositions(str: string) {
    return str
        .replace(/(?<=^|\s)[\p{L}]{1,3}(?=\s)/gu, "$&&nbsp;")
        .replaceAll("&nbsp; ", "&nbsp;")
        .replaceAll("и&nbsp;сопровождение", "и сопровождение");
}
