import { ButtonHTMLAttributes, ForwardedRef, forwardRef, ReactNode } from "react";

import { classNames } from "../../../../../shared/lib/classNames";
import styles from "./Button.module.scss";

type Theme = "primary" | "other";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    theme?: Theme;
    circle?: boolean;
    className?: string;
    children: ReactNode;
    errorMessage?: string;
}
export const Button = forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
        className,
        children,
        theme = "primary",
        circle = true,
        type = "button",
        errorMessage,
        ...otherProps
    } = props;
    return (
        <button
            type={type}
            ref={ref}
            {...otherProps}
            className={classNames({
                cls: styles.button,
                mods: { [styles.button_circle]: circle },
                additional: [className, styles[`button_${theme}`]],
            })}
        >
            {children}
        </button>
    );
});
