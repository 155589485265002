import { Chat, Message, Pencil } from "../../../assets/svg/index";

export const steps = [
    {
        Icon: Pencil,
        title: "Резюме",
        description: (
            <>
                Присылайте резюме на почту <a href="mailto:job@bcs.ru">job@bcs.ru</a>.
            </>
        ),
        step: "1",
    },
    {
        Icon: Chat,
        title: "Интервью",
        description:
            "Команда рекрутеров пригласит вас на интервью, если у нас есть вакансии, соответствующие вашему опыту и знаниям.",
        step: "2",
    },
    {
        Icon: Message,
        title: "Приглашение на работу",
        description: "Если наши интересы совпадают, ждем вас в команде.",
        step: "3",
    },
];
