export enum SearchParamsOtherEnum {
    STEP = "step",
}

export enum SearchParamsVacancyEnum {
    SEARCH = "Name",
    DIRECTION = "DirectionId",
    TEAM = "TeamId",
    CITY = "City",
    REMOVE = "IsRemote",
    HYBRID = "IsHybrid",
}

export type SearchParamsEnum = SearchParamsOtherEnum & SearchParamsVacancyEnum;
