import styles from "./SocialList.module.scss";
import { SocnetHh, SocnetOk, SocnetVk, SocnetYt } from "../../assets/svg/index";
import { classNames } from "../../lib/classNames";

interface SocialListProps {
    className?: string;
}
export const SocialList = ({ className }: SocialListProps) => {
    return (
        <div className={classNames({ cls: styles.socialList, additional: [className] })}>
            <p>Следите за нами в соцсетях</p>
            <ul>
                <li>
                    <a href="https://hh.ru/employer/1833">
                        <SocnetHh />
                    </a>
                </li>
                <li>
                    <a href="https://vk.com/bcs_world_of_investments">
                        <SocnetVk />
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/@BrokerRu">
                        <SocnetYt />
                    </a>
                </li>
                <li>
                    <a href="https://ok.ru/bcs">
                        <SocnetOk />
                    </a>
                </li>
            </ul>
        </div>
    );
};
