export const linkAwards = [
    {
        id: "1",
        description: "Инвестиционная компания года",
        link: "https://www.banki.ru/news/lenta/?id=10981794",
    },
    {
        id: "2",
        description: "Лучший брокер и УК для розничного инвестора",
        link: "https://frankmedia.ru/135073",
    },
    {
        id: "4",
        description: "Компания рынка акций",
        link: "https://naufor.ru/elite",
    },
    {
        id: "5",
        description: "Лучшая брокерская компания для розничных инвесторов",
        link: "https://investfunds.ru/news/163050",
    },
    {
        id: "7",
        description: "Инвестиционные решения в юанях и стратегия года",
        link: "https://bosfera.ru/bo/v-desyatku",
    }
];
