import { imgBackgroundAbout } from "../../../shared/assets/img/index";

export const about = {
    title: {
        first: "Наши",
        second: "ценности",
    },
    img: imgBackgroundAbout,
    description: "Информационно-технический центр в технопарке Академгородка г. Новосибирск",
};
