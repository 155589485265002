import { ReactNode } from "react";
import { JSX } from "react/jsx-runtime";

import { classNames } from "../../lib/classNames";

import styles from "./Title.module.scss";

type LevelType = 1 | 2 | 3;

interface TitleProps {
    children: ReactNode;
    level: LevelType;
    className?: string;
}

export const Title = ({ children, level, className }: TitleProps) => {
    const Tag = `h${level}` as keyof JSX.IntrinsicElements;

    return (
        <div
            className={classNames({
                cls: styles.title,
                additional: [className, styles[`title_${level}`]],
            })}
        >
            {<Tag>{children}</Tag>}
        </div>
    );
};
