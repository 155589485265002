import { useSearchParams } from "react-router-dom";
import { Accordion, Container, StepLink, steps } from "../../shared/ui";

import { faq } from "./consts/faq";
import { AnchorsEnum } from "../../shared/constants/anchors";

import styles from "./FAQSteps.module.scss";

export const FAQSteps = () => {
    const [searchParams] = useSearchParams();
    const stepNumber = searchParams.get("step");
    return (
        <Container id={AnchorsEnum.FAQ} marginSize="large" className={styles.faq}>
            <div className={styles.wrapper}>
                {faq[+(stepNumber ?? 0)].map((el, index) => (
                    <div key={el.title + index}>
                        <h2 className={styles.faq__title}>{el.title}</h2>
                        {el.description && <p className={styles.faq__description}>{el.description}</p>}
                        <div className={styles.accordionsWrapper}>
                            {el.questions.map((question, index) => (
                                <Accordion
                                    className={styles.accordionsWrapper__item}
                                    key={index}
                                    title={question.title}
                                >
                                    <p className={styles.accordionsWrapper__content}>{question.description}</p>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                ))}
                {stepNumber && (
                    <div>
                        <h2 className={styles.faq__title_step}>
                            {stepNumber === "3" ? "Присоединяйтесь — будет интересно!" : "Что будет дальше?"}
                        </h2>
                        <StepLink
                            preventScrollReset={false}
                            key={stepNumber}
                            step={(+stepNumber + 1).toString()}
                            title={stepNumber === "3" ? "Посмотрите наши вакансии" : steps[+stepNumber].title}
                        />
                    </div>
                )}
            </div>
        </Container>
    );
};
