import { RefObject, useEffect, useState } from "react";

interface UseViewScrollOptions {
    elementRef: RefObject<HTMLElement>;
    offsetHeight: number;
}

export function useViewScroll({ elementRef, offsetHeight }: UseViewScrollOptions): boolean {
    const [viewScroll, setViewScroll] = useState(false);

    useEffect(() => {
        const optionsElement = elementRef.current;

        if (!optionsElement) return;

        const optionsHeight = optionsElement.offsetHeight;

        if (optionsHeight > offsetHeight) {
            setViewScroll(true);
        } else {
            setViewScroll(false);
        }
    });

    return viewScroll;
}
