import React, { ReactNode } from "react";

import { classNames } from "../../lib/classNames";
import { createPortal } from "react-dom";
import styles from "./Modal.module.scss";

interface ModalProps {
    children: ReactNode;
    isOpen: boolean;
}

export const Modal: React.FC<ModalProps> = ({ children, isOpen }) => {
    if (!isOpen) {
        document.body.style.overflow = "auto";
        return null;
    } else {
        document.body.style.overflow = "hidden";
    }

    return createPortal(
        <div className={classNames({ cls: styles.modal, mods: { [styles.modal_open]: isOpen } })}>
            <div className={styles.modal__content}>{children}</div>
        </div>,
        document.body,
    );
};
