import styles from "./JoinWork.module.scss";
import { Button, Container, Title } from "../../shared/ui";
import { VacancyList } from "../../entities/Vacancy";

import { getRouteVacancies } from "../../shared/constants/routes";

import { useGetVacancyCountQuery, useGetVacancyListQuery } from "../../entities/Vacancy/api/vacancyApi";
import declOfNum from "../../shared/lib/declOfNum";

export const JoinWork = () => {
    const { data: countVacancy } = useGetVacancyCountQuery();
    const { data: vacancyList, isLoading } = useGetVacancyListQuery();
    return (
        <Container className={styles.joinWork} marginSize="large">
            <p className={styles.joinWork__vacancy}>
                Сейчас {countVacancy || 0} {declOfNum(countVacancy || 0, [" вакансия", " вакансии", " вакансий"])}.
            </p>
            <Title className={styles.joinWork__title} level={3}>
                Присоединяйся
            </Title>
            <VacancyList className={styles.joinWork__list} head jobList={vacancyList!} isLoading={isLoading} />
            <Button as={"link"} className={styles.joinWork__button} theme="blue" to={getRouteVacancies()}>
                Найти свою вакансию
            </Button>
        </Container>
    );
};
