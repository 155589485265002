/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    BooleanStatusActionResult,
    City,
    CityIReadOnlyCollectionStatusActionResult,
    CityStatusActionResult,
    StringIReadOnlyCollectionStatusActionResult,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Cities<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags City
     * @name CitiesList
     * @request GET:/api/cities
     */
    citiesList = (params: RequestParams = {}) =>
        this.request<CityIReadOnlyCollectionStatusActionResult, any>({
            path: `/api/cities`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags City
     * @name CitiesDescriptionCitiesDetail
     * @request GET:/api/cities/description-cities/{searchString}
     */
    citiesDescriptionCitiesDetail = (searchString: string, params: RequestParams = {}) =>
        this.request<StringIReadOnlyCollectionStatusActionResult, any>({
            path: `/api/cities/description-cities/${searchString}`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags City
     * @name CitiesDetail
     * @request GET:/api/cities/{id}
     * @secure
     */
    citiesDetail = (id: string, params: RequestParams = {}) =>
        this.request<CityStatusActionResult, void>({
            path: `/api/cities/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags City
     * @name CitiesCreateCreate
     * @request POST:/api/cities/create
     * @secure
     */
    citiesCreateCreate = (data: City, params: RequestParams = {}) =>
        this.request<CityStatusActionResult, void>({
            path: `/api/cities/create`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags City
     * @name CitiesUpdateCreate
     * @request POST:/api/cities/update
     * @secure
     */
    citiesUpdateCreate = (data: City, params: RequestParams = {}) =>
        this.request<CityStatusActionResult, void>({
            path: `/api/cities/update`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags City
     * @name CitiesDeleteCreate
     * @request POST:/api/cities/{id}/delete
     * @secure
     */
    citiesDeleteCreate = (id: string, params: RequestParams = {}) =>
        this.request<BooleanStatusActionResult, void>({
            path: `/api/cities/${id}/delete`,
            method: "POST",
            secure: true,
            format: "json",
            ...params,
        });
}
