export const projectBCK = {
    name: "Проекты БКС:",
    items: [
        {
            label: "БКС Мир Инвестиций",
            link: "https://bcs.ru/",
        },
        {
            label: "БКС Банк",
            link: "https://bcs-bank.com/",
        },
        {
            label: "БКС Экспресс",
            link: "https://bcs-express.ru/",
        },
        {
            label: "БКС Форекс",
            link: "https://bcs-forex.ru/",
        },
        {
            label: "БКС Страхование жизни",
            link: "https://bcslife.ru/",
        },
        {
            label: "Fintarget",
            link: "https://fintarget.ru/",
        },
    ],
};
