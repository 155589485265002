import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { NumberField, NumberFieldProps } from "./NumberField";

export type NumberFieldControlProps<T extends FieldValues> = UseControllerProps<T> &
    Omit<NumberFieldProps, "value" | "defaultValue">;

export const NumberFieldControl = <T extends FieldValues>(props: NumberFieldControlProps<T>) => {
    const { name, control, defaultValue, rules, shouldUnregister, onChange, ...otherProps } = props;
    const {
        field: { ref, ...field },
        fieldState,
    } = useController<T>({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });

    return <NumberField errorMessage={fieldState.error?.message} {...field} {...otherProps} />;
};
