import { CSSProperties } from "react";
import { Border } from "../types/types";

export interface GenerationStylesReturn {
    borderInside: CSSProperties;
    borderOutside: CSSProperties[];
}

export const generationStyles = (
    border: Border,
    size: number,
    colorBackground: string,
    multiplierInside: number,
): GenerationStylesReturn => {
    const borderInside: CSSProperties = { backgroundColor: colorBackground };
    const borderOutside: CSSProperties[] = [];

    switch (border.topLeft) {
        case "inside":
            borderInside.borderTopLeftRadius = size * multiplierInside + "px";
            break;
        case "outside-corner-lateral":
            borderOutside.push({
                top: 0,
                left: -size + "px",
                transform: "rotate(90deg)",
                fill: colorBackground,
            });
            break;
        case "outside-corner-upper":
            borderOutside.push({
                top: -size + "px",
                left: 0,
                transform: "rotate(270deg)",
                fill: colorBackground,
            });
            break;
    }

    switch (border.topRight) {
        case "inside":
            borderInside.borderTopRightRadius = size * multiplierInside + "px";
            break;
        case "outside-corner-lateral":
            borderOutside.push({
                top: 0,
                right: -size + "px",
                fill: colorBackground,
            });
            break;
        case "outside-corner-upper":
            borderOutside.push({
                top: -size + 1 + "px",
                right: "-1px",
                transform: "rotate(180deg)",
                fill: colorBackground,
            });
            break;
    }

    switch (border.bottomRight) {
        case "inside":
            borderInside.borderBottomRightRadius = size * multiplierInside + "px";
            break;
        case "outside-corner-lateral":
            borderOutside.push({
                bottom: "-1px",
                right: -size + "px",
                transform: "rotate(270deg)",
                fill: colorBackground,
            });
            break;
        case "outside-corner-upper":
            borderOutside.push({
                bottom: -size + "px",
                right: "-1px",
                transform: "rotate(90deg)",
                fill: colorBackground,
            });
            break;
    }

    switch (border.bottomLeft) {
        case "inside":
            borderInside.borderBottomLeftRadius = size * multiplierInside + "px";
            break;
        case "outside-corner-lateral":
            borderOutside.push({
                bottom: "-1px",
                left: -size + 1 + "px",
                transform: "rotate(180deg)",
                fill: colorBackground,
            });
            break;
        case "outside-corner-upper":
            borderOutside.push({
                bottom: -size + "px",
                left: 0,
                fill: colorBackground,
            });
            break;
    }

    return { borderInside, borderOutside };
};
