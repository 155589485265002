import { Link } from "react-router-dom";
import { Container, Button, SocialList } from "../../../../shared/ui";
import { AccordionBaseLayout } from "../Accordions/AccordionBaseLayout";
import { AccordionDirections } from "../Accordions/AccordionDirections";

import { getRouteAbout, getRouteEarlyCareer, getRouteVacancies, RoutesEnum } from "../../../../shared/constants/routes";
import { LogoFooter } from "../../../../shared/assets/svg";
import { classNames } from "../../../../shared/lib/classNames";
import { Theme } from "../../types/theme";
import { currentFullYear, generateMediaQuery } from "../../../../shared/lib/helpers";
import { useMediaQuery } from "../../../../shared/hooks/useMediaQuery";
import { projectBCK } from "../../consts/projectBCK";

import { insideBCK } from "../../consts/insideBKC";
import { howWeHire } from "../../consts/howWeHire";

import { directions } from "../../consts/directions";
import { AssetLink } from "../../types/assetLink";
import { MediaQueryProperty, MediaSizeEnum } from "../../../../shared/constants/media";

import styles from "./Footer.module.scss";

interface FooterProps {
    theme?: Theme;
}

interface LinkList {
    link: AssetLink;
}

const LinkList = ({ link }: LinkList) => {
    return (
        <ul>
            <li>
                <Link className={styles.footer__link} to={link.link}>
                    {link.name}
                </Link>
            </li>
            {link.items.map((item) => (
                <li key={item.label}>
                    <Link className={styles.footer__link} to={item.link}>
                        {item.label}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export const Footer = ({ theme = "standard" }: FooterProps) => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.lg));

    return (
        <footer>
            <Container
                marginSize={!matches ? "standard" : undefined}
                className={classNames({ additional: [styles[`container_${theme}`]] })}
            >
                <div className={styles.footer}>
                    <div className={styles.footer__firstSection}>
                        <Link className={styles.footer__link} to={RoutesEnum.Home}>
                            <LogoFooter className={styles.footer__logo} />
                        </Link>
                        <Button
                            as={"link"}
                            className={styles.footer__button}
                            to={getRouteVacancies()}
                            theme="transparent-white"
                            size="s"
                        >
                            <span>Все вакансии</span>
                        </Button>
                    </div>
                    <div className={styles.footer__navigationCareer}>
                        <div className={styles.footer__careerLinks}>
                            {!matches ? (
                                <>
                                    <Link className={styles.footer__link} to={getRouteEarlyCareer()}>
                                        Начало карьеры
                                    </Link>

                                    {/* NOTE BLOG Блоги скрыты по причине не хватки контента*/}
                                    {/*<LinkList link={insideBCK} />*/}
                                    <Link className={styles.footer__link} to={getRouteAbout()}>
                                        О компании
                                    </Link>
                                    <LinkList link={howWeHire} />
                                </>
                            ) : (
                                <>
                                    <AccordionDirections />
                                    <Link className={styles.footer__link} to={getRouteEarlyCareer()}>
                                        Начало карьеры
                                    </Link>
                                    {/* NOTE BLOG Блоги скрыты по причине не хватки контента*/}
                                    {/*<AccordionBaseLayout options={insideBCK.items} title={insideBCK.name} />*/}
                                    <Link className={styles.footer__link} to={getRouteAbout()}>
                                        О компании
                                    </Link>
                                    <AccordionBaseLayout title={howWeHire.name} options={howWeHire.items} />
                                </>
                            )}
                        </div>
                        {!matches && <SocialList />}
                    </div>
                    <div className={styles.footer__navigationProject}>
                        <p>{projectBCK.name}</p>
                        <ul>
                            {projectBCK.items.map((project) => (
                                <li key={project.label}>
                                    <a className={styles.footer__link} href={project.link}>
                                        {project.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {matches && <SocialList />}
                    <div className={styles.footer__disclaimer}>
                        <p>С информацией об ООО «Компания БКС» можно ознакомиться по ссылке.</p>
                        <p>
                            Информация на данной странице сайта не является индивидуальной инвестиционной рекомендацией,
                            и финансовые инструменты либо операции, упомянутые на данной странице сайта, могут не
                            соответствовать вашему инвестиционному профилю, финансовому положению, опыту инвестиций,
                            инвестиционным целям.
                        </p>
                        <p>
                            Данная информация не является индивидуальной инвестиционной рекомендацией, и финансовые
                            инструменты либо операции, упомянутые в данном материале, могут не соответствовать вашему
                            инвестиционному профилю, финансовому положению, опыту инвестиций, инвестиционным целям.
                            Определение соответствия финансового инструмента либо операции инвестиционным целям,
                            инвестиционному горизонту и толерантности к риску является задачей инвестора. ООО «Компания
                            БКС» не несет ответственности за возможные убытки инвестора в случае совершения операций
                            либо инвестирования в финансовые инструменты, упомянутые в настоящем материале, и не
                            рекомендует использовать указанную информацию в качестве единственного источника информации
                            при принятии инвестиционного решения.
                        </p>
                        <p>
                            Данные являются биржевой информацией, обладателем (собственником) которой является ПАО
                            Московская Биржа. Распространение, трансляция или иное предоставление биржевой информации
                            третьим лицам возможно исключительно в порядке и на условиях, предусмотренных порядком
                            использования биржевой информации, предоставляемой ПАО Московская Биржа.
                        </p>
                    </div>
                    <div className={styles.footer__copyrights}>
                        <p>ООО «Компания БКС»© 1995 — {currentFullYear()}</p>
                        <p>Любое использование материалов сайта без разрешения запрещено</p>
                    </div>
                </div>
            </Container>
        </footer>
    );
};
