import { SwiperSlide } from "swiper/react";
import { Container, SliceBorder, Title, Carousel } from "../../shared/ui";

import { historyCompany } from "./const/historyCompany";
import { useMediaQuery } from "../../shared/hooks/useMediaQuery";
import { generateMediaQuery } from "../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../shared/constants/media";

import styles from "./CompanyHistory.module.scss";

export const CompanyHistory = () => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.md));

    return (
        <Container marginSize="large" className={styles.companyHistory}>
            <Title className={styles.companyHistory__title} level={2}>
                История
                <br />— мост в будущее
            </Title>
            <Carousel
                autoHeight={true}
                className={styles.companyHistory__carousel}
                slidesPerView={1}
                pagination={historyCompany.map((item) => item.year)}
                navigation={!matches ? "onSides" : undefined}
                loop={false}
            >
                {historyCompany.map((item) => (
                    <SwiperSlide className={styles.companyHistory__item} key={item.id}>
                        <div className={styles.companyHistory__wrapperItem}>
                            <SliceBorder
                                className={styles.companyHistory__wrapperYear}
                                sizeRadius={20}
                                border={{
                                    bottomRight: "outside-corner-upper",
                                    bottomLeft: "inside",
                                    topLeft: "outside-corner-lateral",
                                }}
                            >
                                <Title className={styles.companyHistory__year} level={3}>
                                    {item.year}
                                </Title>
                            </SliceBorder>
                            <img alt={item.year} className={styles.companyHistory__img} src={item.image} />
                        </div>
                        <p className={styles.companyHistory__description}>{item.description}</p>
                    </SwiperSlide>
                ))}
            </Carousel>
        </Container>
    );
};
