import { Direction } from "../ApiContract/Direction";
import { API_URL } from "../constants/api";
import { Applicant } from "../ApiContract/Applicant";
import { Cities } from "../ApiContract/Cities";
import { Form } from "../ApiContract/Form";
import { LnaDocuments } from "../ApiContract/LnaDocuments";

export const directionFetch = new Direction({ baseUrl: API_URL });
export const applicantFetch = new Applicant({ baseUrl: API_URL });
export const citiesFetch = new Cities({ baseUrl: API_URL });
export const formFetch = new Form({ baseUrl: API_URL });
export const lnaDocuments = new LnaDocuments({ baseUrl: API_URL });
