import React from "react";
import cls from "./Footer.module.scss";

export const Footer = () => {
    return (
        <div className={cls.footer}>
            <div className={cls.content}>
                <div>
                    Остались вопросы? Пиши на <a href="mailto:friends@msk.bcs.ru">friends@msk.bcs.ru</a>
                </div>
                <br />
                <div>© 1995 — 2023 . ООО «Компания БКС».</div>
                <div>Любое использование материалов сайта без разрешения запрещено.</div>
            </div>
        </div>
    );
};
