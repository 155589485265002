import { ButtonHTMLAttributes, ReactNode } from "react";

import { classNames } from "../../lib/classNames";
import { ArrowLeft, ArrowRight, Share, Subscribe } from "../../../shared/assets/svg";

import styles from "./IconButton.module.scss";

export type IconTheme = "white" | "dark" | "light" | "lightStrokeBlue" | "blue";
type Icon = "left" | "right" | "share" | "subscribe";

interface NavigationButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    icon: Icon;
    theme?: IconTheme;
    text?: string;
    textPosition?: "right" | "left";
}

const icons: Record<Icon, ReactNode> = {
    left: <ArrowLeft />,
    right: <ArrowRight />,
    subscribe: <Subscribe />,
    share: <Share />,
};

export const IconButton = ({
    className,
    icon,
    onClick,
    theme = "dark",
    text,
    textPosition = "right",
}: NavigationButtonProps) => {
    return (
        <button
            onClick={onClick}
            className={classNames({
                cls: styles.iconButton,
                mods: { [styles.iconButton_left]: textPosition === "left" },
                additional: [className, styles[`iconButton_${theme}`]],
            })}
        >
            {icons[icon]}
            {text && <span>{text}</span>}
        </button>
    );
};
