import { ReactNode, useEffect, useState } from "react";
import { classNames } from "../../lib/classNames";

import styles from "./CopyToClipboard.module.scss";

interface CopyToClipboardProps {
    className?: string;
    copiedValue: string;
    title?: string;
    duration?: number;
    children: ReactNode;
}
export const CopyToClipboard = ({ className, copiedValue, children, title, duration = 1000 }: CopyToClipboardProps) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(copiedValue);
            setTooltipVisible(true);
        } catch (error) {
            console.warn("Copy failed", error);
            setTooltipVisible(false);
        }
    };

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (tooltipVisible) {
            timeoutId = setTimeout(() => {
                setTooltipVisible(false);
            }, duration);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [tooltipVisible]);

    return (
        <div onClick={handleCopy} className={classNames({ cls: styles.copyToClipboard, additional: [className] })}>
            {tooltipVisible && <div className={styles.tooltip}>{title ?? "Скопировано!"}</div>}
            {children}
        </div>
    );
};
