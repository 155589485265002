import { useSearchParams } from "react-router-dom";
import { SearchVacancies, BannerGetSettled, VacanciesList } from "../../widget";
import { useGetVacancyListQuery } from "../../entities/Vacancy";
import { removeNullKeys } from "../../shared/lib/helpers";
import { searchVacancy } from "./lib/searchParamsVacancy";

export const VacanciesPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: vacancyList, isLoading } = useGetVacancyListQuery(searchVacancy(searchParams));

    return (
        <>
            <SearchVacancies
                defaultValues={searchVacancy(searchParams)}
                onSubmit={(data) => setSearchParams(removeNullKeys(data))}
            />
            <VacanciesList isLoading={isLoading} vacancyList={vacancyList} />
            <BannerGetSettled />
        </>
    );
};
