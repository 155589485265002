import { FormQueryDirectionVacancy, FormVacancy } from "../types/form";
import { SearchParamsVacancyEnum } from "../../../shared/constants/searchParams";

export const filteredFormQueryDirectionVacancy = (form?: FormVacancy) => {
    if (!form) return;

    return Object.keys(form)
        .filter((key) => key !== SearchParamsVacancyEnum.SEARCH)
        .reduce((obj, key) => {
            obj[key as keyof FormQueryDirectionVacancy] = form[key as keyof FormQueryDirectionVacancy];
            return obj;
        }, {} as FormQueryDirectionVacancy);
};
