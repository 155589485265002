export const isProd = window.location.hostname === "bcs.career";
const isDevelop = window.location.hostname === "localhost";

let apiUrl: string;

if (isProd) {
    apiUrl = "https://api-career-back.bcs.ru";
} else if (isDevelop) {
    apiUrl = "https://localhost:5006";
} else {
    apiUrl = "https://api-bcs-career.okd.t-global.bcs";
}

export const API_URL: string = apiUrl;

export const fileCheckEventHub = `${apiUrl}/fileCheckEventHub`;

export const API_ADDRESS: string = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
