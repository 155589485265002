import React, { ForwardedRef, forwardRef } from "react";

import { classNames } from "../../../../lib/classNames";

import { ButtonDropdownProps } from "../../types";
import { ChevronDown, Close, Loading } from "../../../../../shared/assets/svg";
import styles from "./ButtonDropdown.module.scss";

export const ButtonDropdown = forwardRef((props: ButtonDropdownProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { type, placeholder, isLoading, closeIcon, opened, name, selected, onOpen, onDelOption, disabled } = props;

    return (
        <button
            id={name}
            type="button"
            ref={ref}
            disabled={isLoading || disabled}
            className={classNames({ cls: styles.button, additional: [styles[`button_${type}`]] })}
            onClick={onOpen}
        >
            <div
                className={classNames({ cls: styles.button__content, additional: [styles[`button__content_${type}`]] })}
            >
                <p
                    className={classNames({
                        cls: styles.button__text,
                        additional: [styles[`button__text_${type}`]],
                    })}
                >
                    {disabled ? placeholder : selected ?? placeholder}
                </p>
                <div className={styles.button__icons}>
                    {closeIcon && selected && (
                        <Close
                            className={classNames({
                                cls: styles.button__close,
                                additional: [styles[`button__close_${type}`]],
                            })}
                            onClick={onDelOption}
                        />
                    )}
                    {isLoading && <Loading className={styles.button__loadingSvg} />}
                    <ChevronDown
                        className={classNames({
                            cls: styles.button__vector,
                            additional: [opened && styles.button__vector_open],
                        })}
                    />
                </div>
            </div>
        </button>
    );
});
