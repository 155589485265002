import React from "react";
import { SwiperSlide } from "swiper/react";
import { useSearchParams } from "react-router-dom";
import { Carousel, Container, StepCard, StepLink, steps } from "../../../../shared/ui";

import { useMediaQuery } from "../../../../shared/hooks/useMediaQuery";
import { MediaQueryProperty, MediaSizeEnum } from "../../../../shared/constants/media";
import { generateMediaQuery } from "../../../../shared/lib/helpers";
import { AnchorsEnum } from "../../../../shared/constants/anchors";

import styles from "./RecruitingSteps.module.scss";

export const RecruitingSteps = () => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.md));
    const [searchParams] = useSearchParams();
    const stepNumber = searchParams.get("step");

    return (
        <Container
            id={AnchorsEnum.THREE_STEPS}
            marginSize={!matches ? "large" : undefined}
            className={styles.recruitingSteps}
        >
            <Carousel
                slidesOffsetAfter={matches ? 20 : 0}
                slidesOffsetBefore={matches ? 20 : 0}
                spaceBetween={14}
                className={styles.wrapper}
            >
                {steps.map((step) => (
                    <SwiperSlide key={step.step}>
                        {stepNumber ? (
                            <StepLink isActive={stepNumber === step.step} step={step.step} title={step.title} />
                        ) : (
                            <StepCard key={step.step} {...step} />
                        )}
                    </SwiperSlide>
                ))}
            </Carousel>
        </Container>
    );
};
