import { rtkApi } from "../../../shared/api/rtkApi";
import {
    StringIReadOnlyCollectionStatusActionResult,
    VacancyDtoIReadOnlyCollectionStatusActionResult,
    CreateVacancyDtoStatusActionResult,
    VacancyExpandDto,
    WorkTypeDtoListStatusActionResult,
    WorkTypeDto,
    Int32StatusActionResult,
    VacancySampleDto,
    VacancySampleDtoIReadOnlyCollectionStatusActionResult,
} from "../../../shared/ApiContract/data-contracts";
import { SearchParamsVacancyEnum } from "../../../shared/constants/searchParams";
import { removeNullKeys } from "../../../shared/lib/helpers";

const vacancyApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getVacancy: build.query<VacancyExpandDto | null, string>({
            query: (id) => ({
                url: `vacancy/${id}`,
            }),
            transformResponse: (response: CreateVacancyDtoStatusActionResult) => response.data ?? null,
        }),
        getVacancyList: build.query<VacancyExpandDto[], Partial<Record<SearchParamsVacancyEnum, any>> | void>({
            query: (params) => ({
                url: `vacancy/vacancies?${new URLSearchParams(removeNullKeys(params ?? {}))}`,
            }),
            transformResponse: (response: VacancyDtoIReadOnlyCollectionStatusActionResult) => response.data!,
        }),

        getVacancyCity: build.query<string[] | null, void>({
            query: () => ({
                url: `vacancy/cities`,
            }),
            transformResponse: (response: StringIReadOnlyCollectionStatusActionResult) => response.data!,
        }),
        getWorkType: build.query<WorkTypeDto[] | null, void>({
            query: () => ({
                url: `vacancy/workType`,
            }),
            transformResponse: (response: WorkTypeDtoListStatusActionResult) => response.data!,
        }),
        getVacancyCount: build.query<number | undefined, void>({
            query: () => ({
                url: `vacancy/count`,
            }),
            transformResponse: (response: Int32StatusActionResult) => response.data!,
        }),
        getSamples: build.query<VacancySampleDto[] | null, void>({
            query: () => ({
                url: `vacancy/samples`,
            }),
            transformResponse: (response: VacancySampleDtoIReadOnlyCollectionStatusActionResult) => response.data!,
        }),
    }),
});

export const {
    useGetVacancyCityQuery,
    useGetVacancyQuery,
    useGetVacancyListQuery,
    useGetWorkTypeQuery,
    useGetVacancyCountQuery,
    useGetSamplesQuery,
} = vacancyApi;
