import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import { BaseLabel, BaseLabelArg } from "../BaseLabel/BaseLabel";

import { classNames } from "../../lib/classNames";

import styles from "./TextField.module.scss";

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement>, BaseLabelArg {}
export const TextField = forwardRef((props: TextFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { type, label, errorMessage, className, value, ...otherProps } = props;

    return (
        <BaseLabel label={label} errorMessage={errorMessage} className={className}>
            <input
                id={otherProps.name}
                ref={ref}
                type={type}
                value={value}
                className={classNames({
                    cls: styles.textField,
                    mods: { [styles.textField_error]: Boolean(errorMessage) },
                })}
                {...otherProps}
            />
        </BaseLabel>
    );
});
