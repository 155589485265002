import { Container, Title } from "../../shared/ui";

import { classNames } from "../../shared/lib/classNames";
import { imgHoldsPhone } from "../../shared/assets/img";
import { Globe } from "../../shared/assets/svg";

import styles from "./WhatWeCreate.module.scss";

export const WhatWeCreate = () => {
    return (
        <Container className={styles.companyFacts} marginSize="large">
            <Title className={styles.companyFacts__title} level={2}>
                Что мы создаем
            </Title>
            <div className={styles.grid}>
                <div
                    className={classNames({
                        cls: styles.block,
                        additional: [styles.block_first],
                    })}
                >
                    <Title className={styles.block__title} level={2}>
                        1.5
                    </Title>
                    <p>миллиона пользователей приложения</p>
                </div>
                <div
                    className={classNames({
                        cls: styles.block,
                        additional: [styles.block_second],
                    })}
                >
                    <Title className={styles.block__title} level={2}>
                        1000+
                    </Title>
                    <p>инвестиционных идей за&nbsp;2023&nbsp;год</p>
                </div>
                <div
                    className={classNames({
                        cls: styles.block,
                        additional: [styles.block_third],
                    })}
                >
                    <Globe />
                    <Title className={styles.block__title} level={2}>
                        100+
                    </Title>
                    <p>структурных продуктов</p>
                </div>
                <div
                    className={classNames({
                        cls: styles.block,
                        additional: [styles.block_fourth],
                    })}
                >
                    <div className={styles.block__img}>
                        <img src={imgHoldsPhone} alt="держит телефон" />
                    </div>

                    <Title className={styles.block__title} level={2}>
                        1
                    </Title>
                    <p>триллион рублей объем торгов</p>
                </div>
            </div>
        </Container>
    );
};
