import { RefObject, useEffect, useRef } from "react";

function useEventListener<KH extends keyof HTMLElementEventMap, T extends HTMLElement | void = void>(
    eventName: KH,
    handler: (event: HTMLElementEventMap[KH] | Event) => void,
    element?: RefObject<T>,
) {
    // Create a ref that stores handler
    const savedHandler = useRef(handler);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const targetElement: T | Window = element?.current ?? window;

        if (!targetElement?.addEventListener) return;

        const listener: typeof handler = (event) => savedHandler.current(event);

        targetElement.addEventListener(eventName, listener);

        // Remove event listener on cleanup
        return () => {
            targetElement.removeEventListener(eventName, listener);
        };
    }, [eventName, element]);
}

export { useEventListener };
