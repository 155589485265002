import { rtkApi } from "../../../shared/api/rtkApi";
import {
    DirectionDto,
    LongDescriptionDto,
    LongNameDto,
    ShortDescriptionDto,
} from "../../../shared/ApiContract/data-contracts";

const directionsApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getDirectionsLongName: build.query<LongNameDto[], void>({
            query: () => ({
                url: `direction/long-names`,
            }),
        }),
        getDirectionsShortList: build.query<ShortDescriptionDto[], void>({
            query: () => ({
                url: `direction/short-descriptions`,
            }),
        }),
        getDirectionsLongList: build.query<LongDescriptionDto[], void>({
            query: () => ({
                url: `direction/long-descriptions`,
            }),
        }),
        getTeams: build.query<DirectionDto | null, string>({
            query: (id) => ({
                url: `direction/${id}`,
            }),
        }),
    }),
});

export const {
    useGetDirectionsLongNameQuery,
    useGetDirectionsShortListQuery,
    useGetDirectionsLongListQuery,
    useGetTeamsQuery,
} = directionsApi;
