import { useState } from "react";
import { Button, Container, IconButton, Skeleton } from "../../shared/ui";
import { NewsletterModal } from "../../features/NewsletterModal";
import { VacancyList, VacancyListSkeleton } from "../../entities/Vacancy";

import { classNames } from "../../shared/lib/classNames";
import { VacancyDto } from "../../shared/ApiContract/data-contracts";
import { buildSequentialArray } from "../../shared/lib/helpers";

import styles from "./VacanciesList.module.scss";

import { IVacancyListRes } from "../../shared/mocks/vacancy";
import { useGetDirectionsLongNameQuery } from "../../entities/Direction";
import { useSearchParams } from "react-router-dom";
import declOfNum from "../../shared/lib/declOfNum";

interface VacanciesListProps {
    className?: string;
    isLoading?: boolean;
    vacancyList: VacancyDto[] | null | undefined;
}
export const VacanciesList = ({ className, vacancyList, isLoading = false }: VacanciesListProps) => {
    const { data: directionList } = useGetDirectionsLongNameQuery();

    const [openedModal, setOpenedModal] = useState<boolean>(false);

    let [searchParams, setSearchParams] = useSearchParams();
    const TeamId = searchParams.get("TeamId");

    const vacancyListRes = (vacancyList || []).reduce((acc: IVacancyListRes[], vac) => {
        const name = directionList?.find((el) => el.id === vac.directionId)?.longName || "";

        const existingEntry = acc.find((entry) => entry.name === name);

        if (existingEntry) {
            existingEntry.vacancy.push(vac);
        } else {
            const newEntry: IVacancyListRes = {
                name,
                vacancy: [vac],
            };

            acc.push(newEntry);
        }

        return acc;
    }, []);

    return (
        <Container marginSize={"large"} className={classNames({ cls: styles.vacanciesList, additional: [className] })}>
            {isLoading ? (
                <VacanciesListSkeleton />
            ) : (
                <>
                    {(!TeamId || vacancyListRes.length === 0) && (
                        <p className={styles.vacanciesList__count}>
                            {vacancyList?.length || 0}{" "}
                            {declOfNum(vacancyList?.length || 0, [" вакансия", " вакансии", " вакансий"])} по всем
                            направлениям
                        </p>
                    )}
                    <ul className={styles.vacanciesList__list}>
                        {vacancyListRes.length ? (
                            vacancyListRes.map((item) => (
                                <li key={item.name} className={styles.vacanciesList__directionList}>
                                    <p className={styles.vacanciesList__title}>
                                        {item.name} <span>{item.vacancy.length}</span>
                                    </p>
                                    <VacancyList
                                        isLoading={isLoading}
                                        jobList={TeamId ? item.vacancy : item.vacancy.slice(0, 5)}
                                    />
                                    {!TeamId && (
                                        <IconButton
                                            onClick={() =>
                                                setSearchParams({
                                                    DirectionId: item.vacancy[0].directionId!,
                                                    TeamId: item.vacancy[0].teamId!,
                                                })
                                            }
                                            icon={"right"}
                                            text={"Все вакансии"}
                                            textPosition={"left"}
                                        />
                                    )}
                                </li>
                            ))
                        ) : (
                            <div className={styles.vacanciesList__empty}>
                                <p>Хотите получать уведомления о новых вакансиях?</p>
                                <Button
                                    as={"button"}
                                    onClick={() => setOpenedModal(true)}
                                    className={styles.vacanciesList__subscribe}
                                    theme={"blue"}
                                >
                                    Подписаться на рассылку
                                </Button>
                            </div>
                        )}
                    </ul>
                    <NewsletterModal isOpen={openedModal} onClose={() => setOpenedModal(false)} />
                </>
            )}
        </Container>
    );
};

const VacanciesListSkeleton = () => {
    return (
        <>
            <Skeleton className={styles.skeleton__count} />
            <ul className={styles.vacanciesList__list}>
                {buildSequentialArray(2).map((item) => (
                    <li key={item} className={styles.vacanciesList__directionList}>
                        <Skeleton className={styles.skeleton__title} />
                        <VacancyListSkeleton />
                    </li>
                ))}
            </ul>
        </>
    );
};
