import { Link, useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Button, Container } from "../../../../shared/ui";
import { ButtonDropdown } from "../HeaderDropdown/HeaderDropdown";
import { Burger } from "../Burger/Burger";

import { useHover } from "../../../../shared/hooks/useHover";
import {
    getRouteAbout,
    getRouteAboutRecruiting,
    getRouteBlogs,
    getRouteEarlyCareer,
    getRouteVacancies,
    RoutesEnum,
} from "../../../../shared/constants/routes";
import { classNames } from "../../../../shared/lib/classNames";
import { LogoFooter, LogoHeader } from "../../../../shared/assets/svg";
import { Theme } from "../../types/theme";

import styles from "./Header.module.scss";

interface HeaderProps {
    theme?: Theme;
}

export const Header = ({ theme = "standard" }: HeaderProps) => {
    const location = useLocation();
    const refButton = useRef<HTMLButtonElement>(null);
    const [hoverButton, setHover] = useHover(refButton);

    useEffect(() => {
        setHover(false);
    }, [location, setHover]);

    return (
        <header>
            <Container marginSize="standard">
                <div
                    className={classNames({
                        cls: styles.header,
                        additional: [styles[`header_${theme}`]],
                    })}
                >
                    <div className={styles.header__containerNav}>
                        <Link to={RoutesEnum.Home}>
                            <LogoHeader className={styles.header__logo} />
                        </Link>
                        <nav>
                            <ButtonDropdown
                                className={styles.header__link}
                                theme={theme}
                                isHover={hoverButton}
                                ref={refButton}
                            />
                            <Link className={styles.header__link} to={getRouteEarlyCareer()}>
                                Начало карьеры
                            </Link>
                            {/* NOTE BLOG Блоги скрыты по причине не хватки контента*/}
                            {/*<Link className={styles.header__link} to={getRouteBlogs()}>*/}
                            {/*    Внутри БКС*/}
                            {/*</Link>*/}
                            <Link className={styles.header__link} to={getRouteAbout()}>
                                О компании
                            </Link>
                            <Link className={styles.header__link} to={getRouteAboutRecruiting()}>
                                Как стать частью команды
                            </Link>
                        </nav>
                    </div>
                    <Button
                        size="s"
                        as={"link"}
                        to={getRouteVacancies()}
                        className={styles.header__button}
                        theme="transparent-dark"
                    >
                        <span>Все вакансии</span>
                    </Button>
                    <Burger />
                </div>
            </Container>
        </header>
    );
};
