import axios from "axios";

import { createAppAsyncThunk } from "../../../../shared/lib/createAppAsyncThunk";
import { AttachmentModel } from "../../../../shared/ApiContract/data-contracts";
import { API_URL } from "../../../../shared/constants/api";
import { FileResponse } from "../../types/fileResponce";
import { FileForm } from "../../types/types";

interface FilesUploadAsync {
    files: File[];
    id: string;
    formAttachments: Required<AttachmentModel[]>;
}

export const filesUploadAsync = createAppAsyncThunk<FileForm[], FilesUploadAsync>(
    "files/filesUploadAsync",
    async ({ files, id, formAttachments }, thunkAPI) => {
        try {
            let formData = new FormData();

            files.forEach((file) => {
                formData.append("files", file);
            });
            formData.append("id", JSON.stringify(id));
            formData.append(
                "FormAttachments",
                JSON.stringify({
                    id,
                    attachments: formAttachments,
                }),
            );

            const response = await axios<FileResponse>({
                url: `${API_URL}/api/form/upload`,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                method: "POST",
                data: formData,
            });

            if (!response.data.errorCode) {
                return response.data.data ?? [];
            } else {
                return thunkAPI.rejectWithValue(response.statusText);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue("error");
        }
    },
);
