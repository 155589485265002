/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    AttachedFileIReadOnlyCollectionStatusActionResult,
    AttachmentModel,
    BooleanStatusActionResult,
    CreateFormByRobotModel,
    CreateFormModel,
    CreatePresentationByRobotModel,
    FormDto,
    FormDtoIReadOnlyCollectionStatusActionResult,
    FormDtoStatusActionResult,
    FormFileDeleteModel,
    GenerateSmsCodeModel,
    SendConfirmationDocumentLetterModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Form<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Form
     * @name FormDetail
     * @request GET:/api/form/{id}
     */
    formDetail = (id: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/form/${id}`,
            method: "GET",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormAllCompletedList
     * @request GET:/api/form/all-completed
     * @secure
     */
    formAllCompletedList = (
        query?: {
            id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<FormDtoIReadOnlyCollectionStatusActionResult, void>({
            path: `/api/form/all-completed`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormAllFormsList
     * @request GET:/api/form/all-forms
     * @secure
     */
    formAllFormsList = (
        query?: {
            VacancyId?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<FormDtoIReadOnlyCollectionStatusActionResult, void>({
            path: `/api/form/all-forms`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormCreateCreate
     * @request POST:/api/form/create
     * @secure
     */
    formCreateCreate = (data: CreateFormModel, params: RequestParams = {}) =>
        this.request<FormDtoStatusActionResult, void>({
            path: `/api/form/create`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormCreateByRobotCreate
     * @request POST:/api/form/createByRobot
     * @secure
     */
    formCreateByRobotCreate = (data: CreateFormByRobotModel, params: RequestParams = {}) =>
        this.request<string, void>({
            path: `/api/form/createByRobot`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormCreatePresentationCreate
     * @request POST:/api/form/createPresentation
     * @secure
     */
    formCreatePresentationCreate = (data: CreateFormModel, params: RequestParams = {}) =>
        this.request<FormDtoStatusActionResult, void>({
            path: `/api/form/createPresentation`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormCreatePresentationByRobotCreate
     * @request POST:/api/form/createPresentationByRobot
     * @secure
     */
    formCreatePresentationByRobotCreate = (data: CreatePresentationByRobotModel, params: RequestParams = {}) =>
        this.request<string, void>({
            path: `/api/form/createPresentationByRobot`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormSendConfirmationDocumentLetterCreate
     * @request POST:/api/form/sendConfirmationDocumentLetter
     * @secure
     */
    formSendConfirmationDocumentLetterCreate = (
        data: SendConfirmationDocumentLetterModel,
        params: RequestParams = {},
    ) =>
        this.request<string, void>({
            path: `/api/form/sendConfirmationDocumentLetter`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormUpdateCreate
     * @request POST:/api/form/update
     */
    formUpdateCreate = (data: FormDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/form/update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormReturnToCandidateCreate
     * @request POST:/api/form/{id}/return-to-candidate
     * @secure
     */
    formReturnToCandidateCreate = (id: string, params: RequestParams = {}) =>
        this.request<BooleanStatusActionResult, void>({
            path: `/api/form/${id}/return-to-candidate`,
            method: "POST",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormDeleteCreate
     * @request POST:/api/form/{id}/delete
     * @secure
     */
    formDeleteCreate = (id: string, params: RequestParams = {}) =>
        this.request<BooleanStatusActionResult, void>({
            path: `/api/form/${id}/delete`,
            method: "POST",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormUploadCreate
     * @summary Загружает в хранилище файлы, прикрепляемые к форме. Проверяет эти файлы на вирусы.
     * @request POST:/api/form/upload
     */
    formUploadCreate = (
        data: {
            files?: File[];
            id?: string;
            "FormAttachments.Id"?: string;
            "FormAttachments.attachments"?: AttachmentModel[];
        },
        params: RequestParams = {},
    ) =>
        this.request<void, any>({
            path: `/api/form/upload`,
            method: "POST",
            body: data,
            type: ContentType.FormData,
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormFileDeleteCreate
     * @summary Удаляет прикрепленный к форме файл. Возвращает список прикрепленных файлов после удаления.
     * @request POST:/api/form/file/delete
     */
    formFileDeleteCreate = (data: FormFileDeleteModel, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/form/file/delete`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormGenerateSmsCodeCreate
     * @request POST:/api/form/generate-sms-code
     */
    formGenerateSmsCodeCreate = (data: GenerateSmsCodeModel, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/form/generate-sms-code`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormCheckSmsCodeCreate
     * @request POST:/api/form/{id}/{smsCode}/check-sms-code
     */
    formCheckSmsCodeCreate = (id: string, smsCode: number, params: RequestParams = {}) =>
        this.request<BooleanStatusActionResult, any>({
            path: `/api/form/${id}/${smsCode}/check-sms-code`,
            method: "POST",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormListOfFilesDetail
     * @summary Возвращает список вложений, прикрепленных к форме
     * @request GET:/api/form/{id}/list-of-files
     */
    formListOfFilesDetail = (id: string, params: RequestParams = {}) =>
        this.request<AttachedFileIReadOnlyCollectionStatusActionResult, any>({
            path: `/api/form/${id}/list-of-files`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormDocumentsDetail
     * @summary Возвращает в виде общего архива сведения из анкеты в формате docx и вложения.
     * @request GET:/api/form/{formId}/documents
     * @secure
     */
    formDocumentsDetail = (formId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/form/${formId}/documents`,
            method: "GET",
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Form
     * @name FormDeleteFormCreate
     * @request POST:/api/form/delete-form
     * @secure
     */
    formDeleteFormCreate = (data: CreateFormModel, params: RequestParams = {}) =>
        this.request<FormDtoStatusActionResult, void>({
            path: `/api/form/delete-form`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
