import { rtkApi } from "../../../shared/api/rtkApi";
import { AttachmentResponseStatusActionResult } from "../../../shared/ApiContract/data-contracts";
import { IFrom } from "../types/Form";

const applicantFormApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        sendForm: build.mutation<AttachmentResponseStatusActionResult, IFrom>({
            query: (form) => ({
                method: "POST",
                url: `applicant/form`,
                body: form,
            }),
        }),
        uploadFile: build.mutation<AttachmentResponseStatusActionResult, { AttachmentId: string; File: File }>({
            query: ({ AttachmentId, File }) => {
                const bodyFormData = new FormData();
                bodyFormData.append("AttachmentId", AttachmentId);
                bodyFormData.append("File", File);

                return {
                    method: "POST",
                    url: `applicant/upload`,
                    formData: true,
                    body: bodyFormData,
                };
            },
        }),
    }),
});

export const { useUploadFileMutation, useSendFormMutation } = applicantFormApi;
