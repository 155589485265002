import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { filesUploadAsync } from "../services/filesUploadAsync";
import { fileDeleteAsync } from "../services/fileDeleteAsync";
import { FilesSchema } from "../types/filesSchema";
import { filesListAsync } from "../services/filesListAsync";
import { FileCheck, FileResStatus } from "../../types/checkFile";

export const initialState: FilesSchema = { attachedFiles: [], attachmentIds: [], status: "init" };

const filesSlice = createSlice({
    name: "files",
    initialState,
    reducers: {
        updateStatus: (state, { payload }: PayloadAction<FileResStatus>) => {
            const file = state.attachedFiles.find((element) => element.id === payload.AttachmentId);

            if (file) {
                file.fileState = payload.fileState;

                if (payload.fileState === FileCheck.checkSuccess) {
                    state.attachmentIds.push(file.id!);
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(filesUploadAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(filesUploadAsync.rejected, (state) => {
                state.status = "reject";
            })
            .addCase(filesUploadAsync.fulfilled, (state, { payload }) => {
                state.attachedFiles = payload;
                state.status = "fulfilled";
            })
            .addCase(filesListAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(filesListAsync.rejected, (state) => {
                state.status = "reject";
            })
            .addCase(filesListAsync.fulfilled, (state, { payload }) => {
                state.attachmentIds = payload.map((file) => file.id!);
                state.attachedFiles = payload;
                state.status = "fulfilled";
            })
            .addCase(fileDeleteAsync.fulfilled, (state, { payload }) => {
                state.attachmentIds = payload.map((file) => file.id!);
                state.attachedFiles = payload;
            });
    },
});

export const filesActions = filesSlice.actions;
export const filesReducer = filesSlice.reducer;
