import { SubmitErrorHandler, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { TextField, TextArea, Button, Checkbox } from "../../../../shared/ui";

import { resolve } from "../../lib/validation";
import { IFrom } from "../../types/Form";
import { defaultValues } from "../../consts/defaultValues";
import { useSendFormMutation } from "../../api/applicantFormApi";

import { classNames } from "../../../../shared/lib/classNames";
import styles from "./QuestionnaireForm.module.scss";
import { UploadFileForm } from "../UploadFileForm/UploadFileForm";
import { Navigate } from "react-router-dom";
import { getAcceptForm } from "../../../../shared/constants/routes";
import { Autocomplete } from "../../../../pages/FormCandidatePage/ui/components";
import { cities } from "../../../../shared/constants/cities";
import { ResAddress } from "../../../../pages/FormCandidatePage/api/addressSearch";
import { useState } from "react";
import { PersonalDataAgreementModal } from "../PersonalDataModal/PersonalDataModal";
import { PersonalDataText } from "../PersonalDataText/PersonalDataText";

interface QuestionnaireFormProps {
    className?: string;
    theme?: "white" | "other";
    onSubmit?: (data: IFrom) => void;
    initValues?: Partial<IFrom>;
    disabledForm?: boolean;
}

export const QuestionnaireForm = ({
    className,
    theme,
    onSubmit,
    disabledForm = false,
    initValues = defaultValues,
}: QuestionnaireFormProps) => {
    const [isOpenPersonalDataModal, setIsOpenPersonalDataModal] = useState<boolean>(false);

    const [sendForm, { isLoading: loadingSendForm, isError, isSuccess }] = useSendFormMutation();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<IFrom>({
        defaultValues: { ...defaultValues, ...initValues },
        resolver: resolve,
    });
    const getSuggestions = (value: string | null) => {
        if (value === null || value === "") {
            setValue("country", "");
        } else {
            setValue("country", value);
        }
    };
    const onSelectHandle = (select: ResAddress) => {
        setValue("country", select.value);
    };
    const city =
        useWatch({
            name: "country",
            control,
        }) || "";

    const personalData =
        useWatch({
            name: "personalData",
            control,
        }) || false;
    const onSubmitHandle: SubmitHandler<IFrom> = async (data) => {
        await sendForm(data).unwrap();

        if (onSubmit) {
            onSubmit(data);
        }
    };

    const handleAcceptPersonalData = () => {
        setValue("personalData", true);
    };

    const onError: SubmitErrorHandler<IFrom> = (data) => console.log(data);

    if (isSuccess) {
        return <Navigate to={getAcceptForm()} />;
    }

    return (
        <>
            <div className={classNames({ cls: styles.container, additional: [className] })}>
                <form onSubmit={handleSubmit(onSubmitHandle, onError)} className={styles.form}>
                    <div>
                        <p className={styles.form__title}>Добавьте информацию о себе</p>
                        <p
                            className={classNames({
                                cls: styles.form__required,
                                additional: [styles[`form__required_${theme}`]],
                            })}
                        >
                            Поля, отмеченные символом * обязательны для заполнения
                        </p>
                        <div className={styles.form__wrapper}>
                            <TextField
                                label={"Ваше имя и фамилия *"}
                                className={styles.form__textField}
                                errorMessage={errors.fullName?.message}
                                {...register("fullName")}
                            />
                            <TextField
                                label={"Номер телефона *"}
                                className={styles.form__textField}
                                errorMessage={errors.phone?.message}
                                {...register("phone")}
                            />
                            <TextField
                                label={"E-mail *"}
                                className={styles.form__textField}
                                errorMessage={errors.email?.message}
                                {...register("email")}
                            />
                            <TextField
                                label={"Название вуза *"}
                                className={styles.form__textField}
                                errorMessage={errors.university?.message}
                                {...register("university")}
                            />
                            <TextField
                                label={"Степень обучения * (например, бакалавр"}
                                className={styles.form__textField}
                                errorMessage={errors.trainingStage?.message}
                                {...register("trainingStage")}
                            />
                            <TextField
                                label={"Год выпуска *"}
                                className={styles.form__textField}
                                errorMessage={errors.yearRelease?.message}
                                {...register("yearRelease")}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={styles.form__row}>
                            <p>Резюме/CV *</p>
                            <UploadFileForm control={control} />
                            <span>Допустимый формат резюме .docx или .pdf. Размер не более 5 МБ</span>
                        </div>
                    </div>
                    <div>
                        <p className={styles.form__title}>Ссылки</p>
                        <div className={styles.form__wrapper}>
                            <TextField className={styles.form__textField} {...register("social")} label={"Соцсеть"} />
                            <TextField className={styles.form__textField} {...register("socialHH")} label={"hh.ru"} />
                            <TextField
                                className={styles.form__textField}
                                {...register("socialPortfolio")}
                                label={"Портфолио"}
                            />
                            <TextField
                                className={styles.form__textField}
                                {...register("socialOther")}
                                label={"Другие ссылки"}
                            />
                        </div>
                    </div>
                    <div>
                        <p className={styles.form__title}>Расскажите нам какие направления вам интересны</p>
                        <TextArea
                            className={styles.form__textField}
                            errorMessage={errors.aboutMe?.message}
                            rows={10}
                            placeholder={"Добавьте любую полезную информацию о себе"}
                            {...register("aboutMe")}
                        />
                    </div>
                    <div>
                        <p className={styles.form__title}>Mестоположение</p>
                        <div className={styles.form__wrapper}>
                            <Autocomplete
                                value={city}
                                onChangeInput={getSuggestions}
                                onSelectOption={onSelectHandle}
                                options={cities
                                    .filter(
                                        (el) =>
                                            city.length > 0 &&
                                            (el || "").toLocaleLowerCase().startsWith((city || "").toLocaleLowerCase()),
                                    )
                                    .slice(0, 5)
                                    .map((el) => ({ value: el }))}
                                label={"Где вы находитесь?"}
                                placeholder={"Город"}
                                errorMessage={errors.country?.message}
                                className={styles.form__textField_cities}
                                textFieldClassName={styles.form__textField_citiesTextField}
                            />
                        </div>
                    </div>
                    <div
                        className={classNames({
                            cls: styles.form__wrapper,
                            additional: [styles.form__wrapper_checkbox],
                        })}
                    >
                        <Checkbox errorMessage={errors.receiveVacancies?.message} {...register("receiveVacancies")}>
                            Я согласен(-на) на то, чтобы компания БКС контактировала со мной в случае появления вакансий
                        </Checkbox>
                        <Checkbox
                            errorMessage={errors.personalData?.message}
                            checked={personalData}
                            onChange={() => setIsOpenPersonalDataModal(true)}
                        >
                            <span>
                                Я согласен(-на) на обработку <span className={styles.link}>Персональных данных</span>
                            </span>
                        </Checkbox>
                    </div>
                    <Button
                        loading={loadingSendForm}
                        isDisabled={disabledForm}
                        errorMessage={isError ? "произошла ошибка, попробуйте ещё раз" : undefined}
                        as={"button"}
                        className={styles.form__submit}
                        theme="blue"
                        type="submit"
                    >
                        Отправить
                    </Button>
                </form>
            </div>
            <PersonalDataAgreementModal
                onAccept={handleAcceptPersonalData}
                status={isOpenPersonalDataModal}
                onClose={() => setIsOpenPersonalDataModal(false)}
            >
                <PersonalDataText />
            </PersonalDataAgreementModal>
        </>
    );
};
