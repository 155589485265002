import { ForwardedRef, forwardRef, InputHTMLAttributes, ReactNode } from "react";
import { ErrorValidate } from "../ErrorValidate/ErrorValidate";

import { classNames } from "../../lib/classNames";

import styles from "./Checkbox.module.scss";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    children: ReactNode;
    errorMessage?: string;
}
export const Checkbox = forwardRef(
    ({ className, children, errorMessage, ...otherProps }: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
        return (
            <label className={classNames({ cls: styles.checkbox, additional: [className] })}>
                <div className={styles.checkbox__wrapper}>
                    <input type="checkbox" ref={ref} className={styles.checkbox__input} {...otherProps} />
                    {children}
                </div>
                <ErrorValidate errorMessage={errorMessage} />
            </label>
        );
    },
);
