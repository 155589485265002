import { Navigate, useParams } from "react-router-dom";
import { BannerGetSettled, VacancyMain } from "../../widget";

import { RoutesEnum } from "../../shared/constants/routes";

export const VacancyPage = () => {
    const { id } = useParams<"id">();

    if (!id) {
        return <Navigate to={RoutesEnum.NotFound} />;
    }

    return (
        <>
            <VacancyMain id={id} />
            <BannerGetSettled />
        </>
    );
};
