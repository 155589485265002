import { Button, Container, Title } from "../../shared/ui";
import { classNames } from "../../shared/lib/classNames";

import styles from "./CompanyFacts.module.scss";

export const CompanyFacts = () => {
    return (
        <Container className={styles.companyFacts} marginSize="large">
            <Title className={styles.companyFacts__title} level={3}>
                БКС в цифрах
            </Title>
            <div className={styles.grid}>
                <div
                    className={classNames({
                        cls: styles.block,
                        additional: [styles.block_first],
                    })}
                >
                    <Title className={styles.block__title} level={2}>
                        6300
                    </Title>
                    <p>сотрудников</p>
                </div>
                <div
                    className={classNames({
                        cls: styles.block,
                        additional: [styles.block_second],
                    })}
                >
                    <Title className={styles.block__title} level={2}>
                        710
                    </Title>
                    <p>работают более 10 лет</p>
                </div>
                <div
                    className={classNames({
                        cls: styles.block,
                        additional: [styles.block_third],
                    })}
                >
                    <p>более</p>
                    <Title className={styles.block__title} level={2}>
                        43
                    </Title>
                    <p>городов</p>
                </div>
                <div
                    className={classNames({
                        cls: styles.block,
                        additional: [styles.block_fourth],
                    })}
                >
                    <Title className={styles.block__title} level={2}>
                        1000
                    </Title>
                    <p>экпертов и консультантов</p>
                </div>
            </div>
        </Container>
    );
};
