import { ReactNode, useState } from "react";
import { ArrowDownNoBorder } from "../../../shared/assets/svg";
import { classNames } from "../../lib/classNames";

import styles from "./Accordion.module.scss";

interface AccordionProps {
    children: ReactNode;
    className?: string;
    title: string | ReactNode;
    icon?: ReactNode;
}
export const Accordion = ({ children, title, icon, className }: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={classNames({ cls: styles.accordion, additional: [className] })}>
            <button type={"button"} onClick={() => setIsOpen((state) => !state)} className={styles.accordion__header}>
                <p className={styles.accordion__title}>{title}</p>
                <div
                    className={classNames({
                        cls: styles.accordion__vector,
                        additional: [isOpen && styles.accordion__vector_open],
                    })}
                >
                    {icon ?? <ArrowDownNoBorder />}
                </div>
            </button>
            {isOpen && <div className={styles.accordion__content}>{children}</div>}
        </div>
    );
};
