import React from "react";

import styles from "./NotFound.module.scss";
import { Button, Container } from "../../shared/ui";
import { Bear } from "../../shared/assets/svg";
import { getRouteVacancies, RoutesEnum } from "../../shared/constants/routes";

export const NotFound = () => {
    return (
        <Container marginSize={"standard"} className={styles.notFound}>
            <div className={styles.notFound__wrapper}>
                <Bear className={styles.notFound__icon} />
                <h2 className={styles.notFound__header}>У-у-у-п-с! По этой ссылке ничего не нашлось.</h2>
                <div className={styles.notFound__control}>
                    <Button as={"link"} className={styles.notFound__button} theme="blue" to={RoutesEnum.Home}>
                        Вернуться на главную
                    </Button>
                    <Button as={"link"} className={styles.notFound__button} theme="blue" to={getRouteVacancies()}>
                        Смотреть вакансии
                    </Button>
                </div>
            </div>
        </Container>
    );
};
