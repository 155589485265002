import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import { Dropdown } from "../Dropdown/Dropdown";
import { DropdownProps } from "../../types";
import { DirectionDto, LongNameDto } from "../../../../ApiContract/data-contracts";

export type DropdownControlProps<T extends FieldValues> = UseControllerProps<T> &
    Omit<DropdownProps, "onChangeOption"> & { direction?: LongNameDto[]; team?: DirectionDto; close?: () => void };

export const DropdownControl = <T extends FieldValues>(props: DropdownControlProps<T>) => {
    const { name, control, defaultValue, rules, shouldUnregister, options, direction, team, close, ...otherProps } =
        props;
    const {
        field: { onChange, value },
        fieldState,
    } = useController<T>({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const newValue = direction
        ? direction.find((el) => el.id === value)?.longName
        : team
        ? team?.teams?.find((el) => el.id === value)?.name
        : value;
    return (
        <Dropdown
            selected={newValue}
            onChangeOption={onChange}
            errorMessage={fieldState.error?.message}
            options={options}
            close={close}
            {...otherProps}
        />
    );
};
