import { Container, Button } from "../../shared/ui";
import { getRouteAbout } from "../../shared/constants/routes";

import styles from "./AcceptForm.module.scss";

export const AcceptFormPage = () => {
    return (
        <Container>
            <div className={styles.acceptForm__content}>
                <p>Ваша анкета отправлена.</p>
                <p>Наши специалисты свяжутся с вами в ближайшее время.</p>
                <Button as={"link"} theme={"blue"} to={getRouteAbout()}>
                    Узнать о БКС больше
                </Button>
            </div>
        </Container>
    );
};
