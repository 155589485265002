import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Button, Container, DescriptionSkeleton, ShareButton } from "../../shared/ui";
import { useGetVacancyQuery, VacancyTitle } from "../../entities/Vacancy";
import { DirectionList, useGetDirectionsShortListQuery } from "../../entities/Direction";

import { classNames } from "../../shared/lib/classNames";
import { getVacancyRespond, RoutesEnum } from "../../shared/constants/routes";
import { useMediaQuery } from "../../shared/hooks/useMediaQuery";
import { generateMediaQuery } from "../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../shared/constants/media";
import { useGetSamplesQuery } from "../../entities/Vacancy/api/vacancyApi";
import { VacancySampleDto } from "../../shared/ApiContract/data-contracts";

import "./quill.snow.css";
import styles from "./VacancyMain.module.scss";
import { VacancyBanner } from "./VacancyBanner";
import { VacancyFooter } from "./VacancyFooter";

interface VacancyMainProps {
    className?: string;
    id: string;
}

export const VacancyMain = ({ className, id }: VacancyMainProps) => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.sm));
    const { data: vacancy, isLoading } = useGetVacancyQuery(id);
    const { data: directionList } = useGetDirectionsShortListQuery();
    const { data: samplesList } = useGetSamplesQuery();
    const [sample, setSample] = useState<VacancySampleDto | null>(null);

    useEffect(() => {
        if (vacancy?.sampleId && samplesList) {
            setSample(samplesList.find((sample) => sample.id === vacancy.sampleId) || null);
        }
    }, [vacancy, samplesList]);

    if (!vacancy && !isLoading) {
        return <Navigate to={RoutesEnum.NotFound} />;
    }

    return (
        <Container marginSize={"large"} className={classNames({ cls: styles.vacancyMain, additional: [className] })}>
            <VacancyTitle isLoading={isLoading} name={vacancy?.name!} countries={[vacancy?.city!]} />
            <div className={styles.vacancyMain__content}>
                <DirectionList
                    isLoading={isLoading}
                    directionList={[directionList?.find((el) => el.id === vacancy?.directionId)?.shortName!]}
                />
                {isLoading ? (
                    <DescriptionSkeleton items={30} />
                ) : (
                    <div>
                        <VacancyBanner />
                        <div className={`${styles.vacancyMain__desc} ql-editor`}>
                            <div className={styles.vacancyMain__desc_sample}>
                                {sample && sample.description?.map((descriprion) => <p>{descriprion}</p>)}
                            </div>
                            <div id="editor" dangerouslySetInnerHTML={{ __html: vacancy?.description! }}></div>
                        </div>
                        <p className={styles.vacancyMain__subTitle}>
                            ООО «Компания БКС» работает под брендом БКС Мир Инвестиций
                        </p>
                        <VacancyFooter />
                    </div>
                )}
                <div className={styles.vacancyMain__buttonList}>
                    <Button
                        className={styles.vacancyMain__share}
                        isDisabled={isLoading}
                        as={"link"}
                        theme={"blue"}
                        to={getVacancyRespond(vacancy?.id!)}
                    >
                        Откликнуться
                    </Button>
                    <ShareButton className={styles.vacancyMain__share} theme={matches ? "button" : "icon"} />
                </div>
            </div>
        </Container>
    );
};
