import { SliceBorder, Title } from "../../../../shared/ui";

import { about } from "../../consts/about";

import styles from "./AboutMainMobile.module.scss";

export const AboutMainMobile = () => {
    return (
        <>
            <Title className={styles.aboutMain__title} level={1}>
                {about.title.first}
            </Title>
            <div className={styles.aboutMain__wrapperImg}>
                <img className={styles.aboutMain__img} src={about.img} alt={about.description} />
                <SliceBorder
                    sizeRadius={20}
                    className={styles.aboutMain__wrapperTitle}
                    border={{
                        bottomRight: "inside",
                        topRight: "outside-corner-lateral",
                        bottomLeft: "outside-corner-upper",
                    }}
                >
                    <Title className={styles.aboutMain__title} level={1}>
                        {about.title.second}
                    </Title>
                </SliceBorder>
            </div>

            <p className={styles.aboutMain__description}>{about.description}</p>
        </>
    );
};
