import { type CombinedState, combineReducers, configureStore, type PreloadedState } from "@reduxjs/toolkit";
import { type StateSchema } from "./StateSchema";
import { filesReducer, formReducer } from "../../pages/FormCandidatePage";
import { rtkApi } from "../../shared/api/rtkApi";
import { isProd } from "../../shared/constants/api";

export const setupStore = (initialState?: PreloadedState<StateSchema>) => {
    const rootReducer = combineReducers<StateSchema>({
        form: formReducer,
        files: filesReducer,
        [rtkApi.reducerPath]: rtkApi.reducer,
    });

    return configureStore({
        reducer: rootReducer,
        devTools: !isProd,
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkApi.middleware),
    });
};

export type Store = ReturnType<typeof setupStore>;

export type AppStore = CombinedState<StateSchema>;
export type AppDispatch = Store["dispatch"];
