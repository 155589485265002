import styles from "./LifehackCard.module.scss";

interface LifehackCardProps {
    title: string;
    description: string;
}

export const LifehackCard = ({ title, description }: LifehackCardProps) => {
    return (
        <div className={styles.lifehack}>
            <h3 className={styles.lifehack__title}>{title}</h3>
            <p className={styles.lifehack__description}>{description}</p>
        </div>
    );
};
