import { ButtonIcon } from "../ButtonIcon/ButtonIcon";

import { classNames } from "../../../../../shared/lib/classNames";
import styles from "./AddSubtractButtons.module.scss";

interface AddSubtractButtonsProps {
    className?: string;
    onInc: () => void;
    onDec: () => void;
    isFirstItem?: boolean;
}
export const AddSubtractButtons = ({ className, onInc, onDec, isFirstItem = false }: AddSubtractButtonsProps) => {
    return (
        <div className={classNames({ cls: styles.addSubtractButtons, additional: [className] })}>
            <ButtonIcon icon={"plus"} onClick={onInc} />
            {!isFirstItem && <ButtonIcon icon={"minus"} onClick={onDec} />}
        </div>
    );
};
