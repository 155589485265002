import { SwiperSlide } from "swiper/react";
import { Container, SliceBorder, Title, Carousel } from "../../shared/ui";
import { useMediaQuery } from "../../shared/hooks/useMediaQuery";
import { generateMediaQuery } from "../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../shared/constants/media";
import { carouselProjectCompany } from "./consts/carouselProjectCompany";

import styles from "./WorldCompany.module.scss";

export const WorldCompany = () => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.md));

    return (
        <Container className={styles.worldCompany}>
            <div className={styles.worldCompany__wrapper}>
                <SliceBorder
                    sizeRadius={!matches ? 50 : 20}
                    multiplierInside={!matches ? 1.2 : 1.4}
                    border={{
                        bottomRight: "inside",
                        topRight: "outside-corner-lateral",
                        bottomLeft: "outside-corner-upper",
                    }}
                >
                    <Title className={styles.worldCompany__title} level={2}>
                        Мир БКС
                    </Title>
                </SliceBorder>
                <div className={styles.worldCompany__descriptionWrapper}>
                    <p className={styles.worldCompany__description}></p>
                </div>
            </div>
            <Carousel
                slidesOffsetBefore={false}
                slidesPerView={1}
                breakpoints={{
                    768: {
                        slidesPerView: 2,
                        slidesOffsetBefore: 0,
                    },
                    1024: {
                        slidesPerView: 3,
                        slidesOffsetBefore: 0,
                    },
                }}
                className={styles.carousel}
                navigationTheme={matches ? "light" : "dark"}
                spaceBetween={60}
                loop={false}
                classNameNavigation={styles.carousel__navigation}
                navigation={matches ? "onSidesDown" : "down"}
            >
                {carouselProjectCompany.map((company) => (
                    <SwiperSlide className={styles.carousel__item} key={company.id}>
                        <company.svg />
                        <p className={styles.carousel__desc}>{company.description}</p>
                    </SwiperSlide>
                ))}
            </Carousel>
        </Container>
    );
};
