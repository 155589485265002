import {
    LogoBankInvest,
    LogoCapital,
    LogoCertifying,
    LogoExpress,
    LogoFintarget,
    LogoForex,
    LogoInsurance,
    LogoInvest,
    LogoManager,
    LogoUltima,
    LogoWelfare,
} from "../../../shared/assets/svg/LogoProjects";
import { LogoFooter } from "../../../shared/assets/svg";

export const carouselProjectCompany = [
    {
        id: 1,
        svg: LogoInvest,
        description: "Услуги розничного брокерского и инвестиционного бизнеса",
    },
    {
        id: 2,
        svg: LogoUltima,
        description: "Подразделение по обслуживанию состоятельных клиентов",
    },
    {
        id: 3,
        svg: LogoInsurance,
        description: "Услуги инвестиционного стахования жизни",
    },
    {
        id: 4,
        svg: LogoBankInvest,
        description: "Цифровой банк для розничных инвесторов",
    },
    {
        id: 6,
        svg: LogoCertifying,
        description: "Поддержка электронного документооборота",
    },
    {
        id: 7,
        svg: LogoCapital,
        description: "Инвестиционные решения для клиентов и партнёров",
    },
    {
        id: 8,
        svg: LogoManager,
        description: "Финансовые и бизнес-услуги по управлению активами",
    },
    {
        id: 9,
        svg: LogoFooter,
        description: "Структурные продукты с защитой капитала",
    },
    {
        id: 10,
        svg: LogoWelfare,
        description: "Управление средствами пенсионных накоплений ПФР и капиталом крупных частных инвесторов",
    },
    {
        id: 11,
        svg: LogoFooter,
        description: "Консультационно-брокерские услуги институциональным клиентам",
    },
    {
        id: 12,
        svg: LogoForex,
        description: "Инвестиции на рынке Forex",
    },
    {
        id: 13,
        svg: LogoFooter,
        description:
            "Зарегистрированная и лицензированная на Кипре инвестиционная компания. Предоставляет доступ к финансовым инструментам крупнейших мировых бирж.",
    },
    {
        id: 14,
        svg: LogoFooter,
        description:
            "Лицензированная инвестиционная компания, зарегистрированная в Международном финансовом центе Дубая (DIFC).",
    },
    {
        id: 15,
        svg: LogoFooter,
        description: "Услуги розничного брокерского бизнеса на рынке ЮАР",
    },
    {
        id: 16,
        svg: LogoExpress,
        description: "Аналитическое интернет-издание для инвесторов",
    },
    {
        id: 17,
        svg: LogoFintarget,
        description: "Магазин инвестиционных стратегий",
    },
];
