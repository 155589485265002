import { Navigate, useSearchParams } from "react-router-dom";
import { BannerGetSettled, DirectionMain } from "../../widget";
import { TeamList } from "../../entities/Direction";

import { RoutesEnum } from "../../shared/constants/routes";

export const DirectionPage = () => {
    const [searchParams] = useSearchParams();

    if (!searchParams.has("team")) {
        return <Navigate to={RoutesEnum.NotFound} />;
    }

    return (
        <>
            <DirectionMain directionId={searchParams.get("team")} />
            <TeamList idDirection={searchParams.get("team")} />
            <BannerGetSettled />
        </>
    );
};
