import { Link } from "react-router-dom";
import { getRouteVacancies } from "../../../shared/constants/routes";

export const faq = [
    [
        {
            title: "Остались вопросы?",
            description: "Отвечаем на самые популярные вопросы.",
            questions: [
                {
                    title: "Что делать, если нет подходящей мне вакансии?",
                    description: (
                        <p>
                            Отправьте резюме на почту <a href="mailto:job@bcs.ru">job@bcs.ru</a> , указав интересные вам
                            вакансии или направления работы. Команда подбора обязательно свяжется с вами, как только
                            появится подходящее для вас предложение.
                        </p>
                    ),
                },
                {
                    title: "Сколько этапов интервью нужно проходить?",
                    description: (
                        <p>
                            Для каждой вакансии количество интервью зависит от уровня должности и сложности роли. В
                            некоторых случаях кроме интервью кандидаты проходят тестовые задания. Обо всех этапах вам
                            подробно расскажет рекрутер.
                        </p>
                    ),
                },
                {
                    title: "Могу ли я рассчитывать на сотрудничество, если у меня нет опыта?",
                    description: (
                        <p>
                            В компании часто бывают вакансии для начинающих специалистов, поэтому отсутствие опыта — не
                            препятствие для трудоустройства.
                        </p>
                    ),
                },
                {
                    title: "Какой формат работы предусмотрен?",
                    description: (
                        <p>
                            В компании разные форматы работы: очный, гибридный и дистанционный. Выбор формата зависит от
                            роли, функций и стандартов, принятых в конкретном подразделении. Формат работы обычно
                            указывается в описании вакансии.
                        </p>
                    ),
                },
                {
                    title: "Мне нужно соблюдать какие-то требования при оформлении резюме?",
                    description: (
                        <p>
                            Нет, вы можете подать резюме в свободной форме. При этом для нас важно, чтобы резюме
                            содержало:
                            <ul>
                                <li>ФИО</li>
                                <li>Контактные данные</li>
                                <li>Образование</li>
                                <li>Опыт работы</li>
                                <li>Ваши профессиональные достижения</li>
                            </ul>
                        </p>
                    ),
                },
                {
                    title: "Могу ли я подать резюме на иностранном языке?",
                    description: <p>Команда подбора принимает резюме на русском и английском языках.</p>,
                },
                {
                    title: (
                        <>
                            Могу ли я узнать, на каком этапе рассмотрения находится мое резюме после отправки на почту{" "}
                            <a href="mailto:job@bcs.ru">job@bcs.ru?</a>{" "}
                        </>
                    ),
                    description: (
                        <p>
                            К сожалению, такой возможности нет. Рекрутер свяжется с вами, если есть вакансия, которая
                            подходит вам по опыту и знаниям.
                        </p>
                    ),
                },
                {
                    title: "Могу ли я повторно отправить резюме через какое-то время?",
                    description: <p>Да, можете.</p>,
                },
                {
                    title: "Могу ли я позвонить рекрутеру для уточнения деталей по моему резюме?",

                    description: (
                        <p>
                            Контакт с рекрутером возможен только в том случае, если с вами связались коллеги из команды
                            подбора и предложили рассмотреть конкретную вакансию.
                        </p>
                    ),
                },

                {
                    title: "Могу ли я связаться с будущими коллегами из команды, в которой я хочу работать?",

                    description: (
                        <p>
                            В некоторых случаях у вас будет возможность пообщаться с командой в формате групповой
                            видеовстречи или очно. Вы сможете познакомиться с будущими коллегами, подробнее узнать, как
                            распределяются задачи и как вы будете взаимодействовать. О такой возможности вам сообщит
                            рекрутер.
                        </p>
                    ),
                },

                {
                    title: "Я хочу порекомендовать другу вакансию в компании. Как это лучше сделать?",

                    description: (
                        <p>
                            Порекомендуйте другу прислать резюме нам на почту
                            <a href="mailto:job@bcs.ru"> job@bcs.ru</a>.
                        </p>
                    ),
                },
            ],
        },
    ],
    [
        {
            title: "Работа в БКС — с чего все начинается?",
            description: "",
            questions: [
                {
                    title: "Работа в БКС — с чего все начинается?",
                    description: (
                        <p>
                            Наши вакансии размещены в разделе <Link to={getRouteVacancies()}>«Все вакансии»</Link> .
                            Откликайтесь, если рабочие задачи вам интересны, а ваш опыт соответствует перечисленным
                            требованиям. Если вы заинтересовались сразу несколькими вакансиями, смело откликайтесь на
                            каждую из них.
                        </p>
                    ),
                },
            ],
        },
    ],
    [
        {
            title: "Знакомство кандидата и компании",
            description: "",
            questions: [
                {
                    title: "Что будет дальше?",
                    description: (
                        <p>
                            Если ваш опыт соответствует нашей вакансии, рекрутер свяжется с вами, используя контактные
                            данные, указанные в резюме. Вы сможете предварительно обсудить вакансию и договориться о
                            следующем этапе интервью.
                        </p>
                    ),
                },
                {
                    title: "Сколько будет этапов интервью?",
                    description: (
                        <p>
                            Нас с вами ждут несколько этапов:
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                1. Интервью по телефону или по видеосвязи с рекрутером.
                            </span>
                            <br />
                            На этом этапе вы сможете подробно рассказать о себе, своем опыте и ожиданиях от работы.
                            Рекрутер расскажет вам о вакансии, особенностях работы, подробно ответит на ваши вопросы.
                            При взаимном интересе будет организована встреча с руководителем.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                2. Интервью с руководителем (по видеосвязи или очно).
                            </span>
                            <br /> Этот этап обычно занимает около часа. На встрече с руководителем вы более подробно
                            узнаете о специфике работы, команде и ее целях, получите ответы на вопросы и сможете
                            рассказать о себе.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>
                                3. Интервью с вышестоящим руководителем или топ-менеджером (как правило, очно).
                            </span>
                            <br /> Для некоторых должностей предполагается встреча с вышестоящим руководителем или
                            топ-менеджером. При необходимости ее организует рекрутер с учетом ваших пожеланий по дню и
                            времени встречи.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>4. Встреча с командой (по видеосвязи или очно). </span>
                            <br /> В некоторых случаях у вас будет возможность пообщаться с командой в формате групповой
                            видеовстречи или очно. Вы сможете познакомиться с будущими коллегами, подробнее узнать, как
                            распределяются задачи и как вы будете взаимодействовать.
                            <br />
                            <br />
                            <span style={{ fontWeight: 600 }}>5. Тестовое задание (если требуется). </span>
                            <br />
                            Такие задания даются не всегда, но если вакансия предполагает этот этап, рекрутер вас
                            сориентирует по срокам и ожидаемому результату.
                            <br />
                            <br />
                            Всю необходимую информацию по этапам вы будете получать от рекрутера. Также к нему можно
                            обратиться, если у вас возникнут дополнительные вопросы.
                        </p>
                    ),
                },
            ],
        },
    ],
    [
        {
            title: "Приглашение в команду БКС",
            description: "",
            questions: [
                {
                    title: "Как узнать о результатах интервью?",
                    description: (
                        <p>
                            Итак, все запланированные этапы пройдены. Если ваши знания и опыт соответствуют ожиданиям по
                            вакансии и должность вам интересна, рекрутер сообщит вам о положительном решении и обсудит
                            предварительные условия вашего выхода на работу. После этого нам потребуется еще несколько
                            дней (как правило, не более трех) для подготовки официального предложения. По готовности
                            рекрутер направит его вам по электронной почте. Вам останется только согласовать дату
                            первого рабочего дня.
                        </p>
                    ),
                },
                {
                    title: "Что будет до трудоустройства",
                    description: (
                        <p>
                            Рекрутер сообщит вам, какие документы нужно подготовить и как будет проходить ваше
                            оформление: куда и во сколько нужно приехать или как обменяться документами дистанционно.
                            Также вы получите приветственное письмо, которое позволит больше узнать о нашей команде и
                            культуре компании.
                        </p>
                    ),
                },
                {
                    title: "Что будет в первые дни работы",
                    description: (
                        <p>
                            Мы очень вас ждем! Поэтому сделаем все возможное для вашего комфортного старта и погружения
                            в новую рабочую атмосферу: поможем подготовить документы (независимо от того, в каком
                            формате будет проходить ваше оформление), проведем приветственный тренинг (очно или онлайн),
                            поделимся нужной и полезной для нового сотрудника информацией в приветственном письме. По
                            всем вопросам на любом этапе вы всегда можете обратиться к рекрутеру, с которым
                            взаимодействуете.
                        </p>
                    ),
                },
            ],
        },
    ],
];
