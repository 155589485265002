/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    BooleanStatusActionResult,
    PersonnelDocumentIReadOnlyCollectionStatusActionResult,
    PersonnelDocumentPageIReadOnlyCollectionStatusActionResult,
    PersonnelDocumentReorderViewModel,
    PersonnelDocumentStatusActionResult,
    PersonnelDocumentViewModel,
    PersonnelDocuments,
    PersonnelDocumentsIReadOnlyCollectionStatusActionResult,
    PersonnelDocumentsStatusActionResult,
    PersonnelPagesReorderViewModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class LnaDocuments<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsList
     * @request GET:/api/lna-documents
     * @secure
     */
    lnaDocumentsList = (params: RequestParams = {}) =>
        this.request<PersonnelDocumentsIReadOnlyCollectionStatusActionResult, void>({
            path: `/api/lna-documents`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsDetail
     * @request GET:/api/lna-documents/{id}
     */
    lnaDocumentsDetail = (id: string, params: RequestParams = {}) =>
        this.request<PersonnelDocumentsStatusActionResult, any>({
            path: `/api/lna-documents/${id}`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsGetByRoutingDetail
     * @request GET:/api/lna-documents/get-by-routing/{routing}
     */
    lnaDocumentsGetByRoutingDetail = (routing: string, params: RequestParams = {}) =>
        this.request<PersonnelDocumentsStatusActionResult, any>({
            path: `/api/lna-documents/get-by-routing/${routing}`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsUpsertPersonnelDocumentsCreate
     * @request POST:/api/lna-documents/upsert-personnel-documents
     * @secure
     */
    lnaDocumentsUpsertPersonnelDocumentsCreate = (data: PersonnelDocuments, params: RequestParams = {}) =>
        this.request<PersonnelDocumentsStatusActionResult, void>({
            path: `/api/lna-documents/upsert-personnel-documents`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsModeratedCreate
     * @request POST:/api/lna-documents/{id}/moderated
     * @secure
     */
    lnaDocumentsModeratedCreate = (id: string, params: RequestParams = {}) =>
        this.request<PersonnelDocumentsStatusActionResult, void>({
            path: `/api/lna-documents/${id}/moderated`,
            method: "POST",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsArchiveCreate
     * @request POST:/api/lna-documents/{id}/archive
     * @secure
     */
    lnaDocumentsArchiveCreate = (id: string, params: RequestParams = {}) =>
        this.request<PersonnelDocumentsStatusActionResult, void>({
            path: `/api/lna-documents/${id}/archive`,
            method: "POST",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsDocumentsDetail
     * @request GET:/api/lna-documents/{id}/documents
     */
    lnaDocumentsDocumentsDetail = (id: string, params: RequestParams = {}) =>
        this.request<PersonnelDocumentIReadOnlyCollectionStatusActionResult, any>({
            path: `/api/lna-documents/${id}/documents`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsDocumentsDetail2
     * @request GET:/api/lna-documents/{id}/documents/{documentId}
     * @originalName lnaDocumentsDocumentsDetail
     * @duplicate
     */
    lnaDocumentsDocumentsDetail2 = (id: string, documentId: string, params: RequestParams = {}) =>
        this.request<PersonnelDocumentStatusActionResult, any>({
            path: `/api/lna-documents/${id}/documents/${documentId}`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsUpsertDocumentCreate
     * @request POST:/api/lna-documents/upsert-document
     * @secure
     */
    lnaDocumentsUpsertDocumentCreate = (data: PersonnelDocumentViewModel, params: RequestParams = {}) =>
        this.request<PersonnelDocumentStatusActionResult, void>({
            path: `/api/lna-documents/upsert-document`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsReorderDocumentsCreate
     * @request POST:/api/lna-documents/reorder-documents
     * @secure
     */
    lnaDocumentsReorderDocumentsCreate = (data: PersonnelDocumentReorderViewModel, params: RequestParams = {}) =>
        this.request<BooleanStatusActionResult, void>({
            path: `/api/lna-documents/reorder-documents`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsDeleteDocumentDelete
     * @request DELETE:/api/lna-documents/{id}/delete-document/{documentId}
     * @secure
     */
    lnaDocumentsDeleteDocumentDelete = (id: string, documentId: string, params: RequestParams = {}) =>
        this.request<BooleanStatusActionResult, void>({
            path: `/api/lna-documents/${id}/delete-document/${documentId}`,
            method: "DELETE",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsUploadFileCreate
     * @request POST:/api/lna-documents/upload-file
     * @secure
     */
    lnaDocumentsUploadFileCreate = (
        data: {
            Id?: string;
            DocumentId?: string;
            PageId?: string;
            /** @format binary */
            File?: File;
        },
        params: RequestParams = {},
    ) =>
        this.request<BooleanStatusActionResult, void>({
            path: `/api/lna-documents/upload-file`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsDocumentDeletePageDelete
     * @request DELETE:/api/lna-documents/{id}/document/{documentId}/delete-page/{pageId}
     * @secure
     */
    lnaDocumentsDocumentDeletePageDelete = (
        id: string,
        documentId: string,
        pageId: string,
        params: RequestParams = {},
    ) =>
        this.request<BooleanStatusActionResult, void>({
            path: `/api/lna-documents/${id}/document/${documentId}/delete-page/${pageId}`,
            method: "DELETE",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsDocumentsReorderPagesCreate
     * @request POST:/api/lna-documents/documents/reorder-pages
     * @secure
     */
    lnaDocumentsDocumentsReorderPagesCreate = (data: PersonnelPagesReorderViewModel, params: RequestParams = {}) =>
        this.request<BooleanStatusActionResult, void>({
            path: `/api/lna-documents/documents/reorder-pages`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsDocumentsPagesDetail
     * @request GET:/api/lna-documents/{id}/documents/{documentId}/pages
     */
    lnaDocumentsDocumentsPagesDetail = (id: string, documentId: string, params: RequestParams = {}) =>
        this.request<PersonnelDocumentPageIReadOnlyCollectionStatusActionResult, any>({
            path: `/api/lna-documents/${id}/documents/${documentId}/pages`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags LnaDocuments
     * @name LnaDocumentsFileDetail
     * @request GET:/api/lna-documents/file/{fileId}
     */
    lnaDocumentsFileDetail = (fileId: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/lna-documents/file/${fileId}`,
            method: "GET",
            ...params,
        });
}
