import { ForwardedRef, forwardRef, InputHTMLAttributes, ReactNode } from "react";
import { ErrorValidate } from "../ErrorValidate/ErrorValidate";

import { classNames } from "../../../../../shared/lib/classNames";

import styles from "./Checkbox.module.scss";

interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
    type?: "radio" | "checkbox";
    className?: string;
    children: ReactNode;
    errorMessage?: string;
}
export const Checkbox = forwardRef(
    (
        { className, children, type = "checkbox", errorMessage, ...otherProps }: CheckboxProps,
        ref: ForwardedRef<HTMLInputElement>,
    ) => {
        return (
            <label className={classNames({ cls: styles.checkbox, additional: [className] })}>
                <div className={styles.checkbox__wrapper}>
                    <input
                        type={type}
                        ref={ref}
                        className={classNames({
                            cls: styles.checkbox__input,
                            mods: { [styles.checkbox__input_radio]: type === "radio" },
                        })}
                        {...otherProps}
                    />
                    {children}
                </div>
                <ErrorValidate errorMessage={errorMessage} />
            </label>
        );
    },
);
