import { useCallback } from "react";
import { IconButton } from "../../../IconButton/IconButton";
import { SliceBorder } from "../../../SliceBorder/SliceBorder";

import { classNames } from "../../../../lib/classNames";
import { Border } from "../../../SliceBorder/types/types";
import { NavigationsProps } from "../../types";

import styles from "./Navigations.module.scss";

const defaultBorder: Border = {
    topRight: "inside",
    topLeft: "inside",
    bottomLeft: "outside-corner-lateral",
    bottomRight: "outside-corner-lateral",
};

export const Navigations = (props: NavigationsProps) => {
    const {
        navigation,
        sliderRef,
        navigationTheme = "dark",
        colorBackground,
        className,
        border = defaultBorder,
    } = props;

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, [sliderRef]);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, [sliderRef]);

    switch (navigation) {
        case "down":
            return (
                <SliceBorder
                    colorBackground={colorBackground}
                    sizeRadius={28}
                    border={border}
                    className={classNames({ cls: styles.navDown, additional: [className] })}
                >
                    <IconButton
                        theme={navigationTheme}
                        className={styles[navigationTheme]}
                        onClick={handlePrev}
                        icon="left"
                    />
                    <IconButton
                        theme={navigationTheme}
                        className={styles[navigationTheme]}
                        onClick={handleNext}
                        icon="right"
                    />
                </SliceBorder>
            );

        case "onSides": {
            return (
                <>
                    <IconButton
                        icon="left"
                        theme={navigationTheme}
                        className={classNames({
                            cls: styles.navOnSides,
                            additional: [styles.navOnSides_left],
                        })}
                        onClick={handlePrev}
                    />
                    <IconButton
                        icon="right"
                        theme={navigationTheme}
                        className={classNames({
                            cls: styles.navOnSides,
                            additional: [styles.navOnSides_right],
                        })}
                        onClick={handleNext}
                    />
                </>
            );
        }
        case "onBothSides": {
            return (
                <>
                    <IconButton
                        icon="left"
                        theme={navigationTheme}
                        className={classNames({
                            cls: styles.navOnBothSides,
                            additional: [styles.navOnBothSides_left],
                        })}
                        onClick={handlePrev}
                    />
                    <IconButton
                        icon="right"
                        theme={navigationTheme}
                        className={classNames({
                            cls: styles.navOnBothSides,
                            additional: [styles.navOnBothSides_right],
                        })}
                        onClick={handleNext}
                    />
                </>
            );
        }
        case "onSidesDown": {
            return (
                <div className={classNames({ cls: styles.navOnSidesDown, additional: [className] })}>
                    <IconButton
                        icon="left"
                        theme={navigationTheme}
                        className={classNames({
                            additional: [styles.navOnSides_left],
                        })}
                        onClick={handlePrev}
                    />
                    <IconButton
                        icon="right"
                        theme={navigationTheme}
                        className={classNames({
                            additional: [styles.navOnSides_right],
                        })}
                        onClick={handleNext}
                    />
                </div>
            );
        }
        default:
            return null;
    }
};
