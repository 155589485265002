import styles from "./Autocomplete.module.scss";
import { classNames } from "../../../../../shared/lib/classNames";
import { useCombobox } from "downshift";
import { TextField, TextFieldProps } from "../TextField/TextField";

interface Options {
    value: string;
}

export interface AutocompleteProps extends Omit<TextFieldProps, "value"> {
    options: Options[];
    value?: string | null;
    onSelectOption: (option: any) => void;
    onChangeInput: (value: string) => void;
    textFieldClassName?: string;
}
export const Autocomplete = ({
    className,
    value,
    onChangeInput,
    label,
    onSelectOption,
    options,
    errorMessage,
    textFieldClassName,
    ...otherProps
}: AutocompleteProps) => {
    const { isOpen, getMenuProps, getInputProps, getItemProps } = useCombobox({
        items: options,
        itemToString: (item) => item?.value ?? "",
        inputValue: value ?? "",
        onInputValueChange: (item) => onChangeInput(item.inputValue ?? ""),
        onSelectedItemChange: (item) => onSelectOption(item.selectedItem),
    });

    return (
        <div className={classNames({ cls: styles.autocomplete, additional: [className] })}>
            <div className={styles.autocomplete__input}>
                <TextField
                    className={textFieldClassName}
                    {...otherProps}
                    errorMessage={errorMessage}
                    label={label}
                    {...getInputProps()}
                />
            </div>

            <ul className={styles.autocomplete__dropdown} {...getMenuProps()}>
                {isOpen
                    ? options.map((item, index) => (
                          <li
                              className={styles.autocomplete__item}
                              key={`${item.value}${index}`}
                              {...getItemProps({
                                  item,
                                  index,
                              })}
                          >
                              {item.value}
                          </li>
                      ))
                    : null}
            </ul>
        </div>
    );
};
