import { getRouteAboutRecruiting, RoutesEnum } from "../../../shared/constants/routes";
import { AssetLink } from "../types/assetLink";
import { anchorURL } from "../../../shared/lib/helpers";
import { AnchorsEnum } from "../../../shared/constants/anchors";

export const howWeHire: AssetLink = {
    name: "Как стать частью команды",
    link: getRouteAboutRecruiting(),
    items: [
        {
            label: "3 шага",
            link: anchorURL(RoutesEnum.AboutRecruiting, AnchorsEnum.THREE_STEPS),
        },
        {
            label: "Вопросы и ответы",
            link: anchorURL(RoutesEnum.AboutRecruiting, AnchorsEnum.FAQ),
        },
        {
            label: "Лайфхаки",
            link: anchorURL(RoutesEnum.AboutRecruiting, AnchorsEnum.LIFEHACKS),
        },
    ],
};
