import { useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";

import { useGetVacancyQuery, VacancyTitle } from "../../entities/Vacancy";
import { QuestionnaireForm } from "../../widget";
import { Container } from "../../shared/ui";
import { RoutesEnum } from "../../shared/constants/routes";

export const VacancyRespondPage = () => {
    const { id } = useParams<"id">();
    const { data: vacancy, isLoading } = useGetVacancyQuery(id!);

    if (!id || !vacancy) {
        return <Navigate to={RoutesEnum.NotFound} />;
    }

    return (
        <Container marginSize={"large"}>
            <VacancyTitle isLoading={isLoading} name={vacancy.name!} countries={[vacancy.city!]} />
            <QuestionnaireForm disabledForm={isLoading} initValues={{ vacancyId: id }} />
        </Container>
    );
};
