import styles from "./Row.module.scss";
import { classNames } from "../../../../../shared/lib/classNames";
import { ReactNode } from "react";

interface RowProps {
    className?: string;
    children: ReactNode;
}
export const Row = ({ className, children }: RowProps) => {
    return <div className={classNames({ cls: styles.row, additional: [className] })}>{children}</div>;
};
