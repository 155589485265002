import { formFetch } from "../../../../shared/api/api";
import { FormDto } from "../../../../shared/ApiContract/data-contracts";
import { HttpResponse } from "../../../../shared/ApiContract/http-client";
import { createAppAsyncThunk } from "../../../../shared/lib/createAppAsyncThunk";

export const formDetailAsync = createAppAsyncThunk<FormDto, string>(
    "form/formDetailAsync",
    async (formData, thunkAPI) => {
        try {
            const response = await formFetch.formDetail(formData);
            const data = (await response.json()) as HttpResponse<FormDto, void>;

            if (response.ok && data.data) {
                return data.data;
            } else {
                return thunkAPI.rejectWithValue("error");
            }
        } catch (e) {
            return thunkAPI.rejectWithValue("error");
        }
    },
);
