import { ReactNode } from "react";
import { Container, SliceBorder, Title } from "../../shared/ui";

import { classNames } from "../../shared/lib/classNames";
import { useMediaQuery } from "../../shared/hooks/useMediaQuery";
import { generateMediaQuery } from "../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../shared/constants/media";

import styles from "./EarlyCareerMain.module.scss";

interface EarlyCareerMainProps {
    className?: string;
    children: ReactNode;
}
export const EarlyCareerMain = ({ className, children }: EarlyCareerMainProps) => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.sm));

    return (
        <Container className={classNames({ cls: styles.earlyCareerMain, additional: [className] })}>
            <div className={styles.earlyCareerMain__wrapper}>
                <SliceBorder
                    sizeRadius={matches ? 20 : 30}
                    border={{
                        topRight: "outside-corner-lateral",
                        bottomRight: "inside",
                        bottomLeft: "outside-corner-upper",
                    }}
                >
                    <Title className={styles.earlyCareerMain__title} level={1}>
                        Начало <br /> карьеры в БКС
                    </Title>
                </SliceBorder>
                <p className={styles.earlyCareerMain__description}>
                    Если вы только начинаете карьеру, смело оставляйте свои данные в форме ниже. У нас часто бывают
                    вакансии, не требующие опыта работы. Вы многому сможете научиться под руководством наших
                    профессионалов.
                </p>
            </div>
            {children}
        </Container>
    );
};
