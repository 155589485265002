import { Container } from "../../../../shared/ui";
import { AboutMainDesktop } from "../AboutMainDesktop/AboutMainDesktop";
import { AboutMainMobile } from "../AboutMainMobile/AboutMainMobile";

import { useMediaQuery } from "../../../../shared/hooks/useMediaQuery";
import { generateMediaQuery } from "../../../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../../../shared/constants/media";

import styles from "./AboutMain.module.scss";

export const AboutMain = () => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.md));

    return (
        <Container marginSize={matches ? "standard" : undefined} className={styles.aboutMain}>
            {!matches ? <AboutMainDesktop /> : <AboutMainMobile />}
        </Container>
    );
};
