import {
    AboutMain,
    InvestmentStage,
    WorldCompany,
    OurOffices,
    CompanyHistory,
    WhatWeCreate,
    BannerGetSettled,
} from "../../widget";

export const AboutPage = () => {
    return (
        <>
            <AboutMain />
            <InvestmentStage />
            <WhatWeCreate />
            <CompanyHistory />
            <WorldCompany />
            <OurOffices />
            <BannerGetSettled />
        </>
    );
};
