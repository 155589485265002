import { Link } from "react-router-dom";
import { DirectionList } from "../DirectionList/DirectionList";
import { IconButton, Skeleton, SliceBorder } from "../../../../shared/ui";

import { useMediaQuery } from "../../../../shared/hooks/useMediaQuery";
import { generateMediaQuery } from "../../../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../../../shared/constants/media";

import styles from "./TeamItem.module.scss";

export const TeamItem = ({ name, count, direction, link }: any) => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.sm));

    return (
        <div className={styles.teamItem}>
            <div className={styles.teamItem__content}>
                <p className={styles.teamItem__text}>
                    {name} <span>({count})</span>
                </p>
                <DirectionList directionList={[direction]} />
                <SliceBorder
                    sizeRadius={22}
                    multiplierInside={matches ? 1 : 1.3}
                    className={styles.teamItem__wrapperLink}
                    border={{
                        topRight: "outside-corner-upper",
                        topLeft: "inside",
                        bottomLeft: "outside-corner-lateral",
                    }}
                >
                    <Link to={link}>
                        <IconButton icon={"right"} />
                    </Link>
                </SliceBorder>
            </div>
        </div>
    );
};

export const TeamItemSkeleton = () => {
    return <Skeleton className={styles.skeleton}></Skeleton>;
};
