import { useRef } from "react";

import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { classNames } from "../../../../lib/classNames";
import { OptionDropdownProps, OptionItemProps } from "../../types";

import styles from "./OptionsDropdown.module.scss";
import { useViewScroll } from "../../lib/hook/useViewScroll";

export const OptionItemDropdown = ({ type, option, onClickOption, selected }: OptionItemProps) => {
    const cln = classNames({
        cls: styles.options__item,
        additional: [option.label === selected && styles.options__item_current, styles[`options__item_${type}`]],
    });

    return (
        <li className={cln} onClick={onClickOption}>
            {option.label}
        </li>
    );
};

export const OptionsDropdown = (props: OptionDropdownProps) => {
    const { type, opened, onClose, triggerRef, selected, options, onClickOption } = props;
    const tooltipRef = useRef<HTMLDivElement>(null);
    const viewScroll = useViewScroll({ elementRef: tooltipRef, offsetHeight: 380 });

    useOutsideClick({
        elementRef: tooltipRef,
        triggerRef,
        onOutsideClick: onClose,
        enabled: opened,
    });

    if (!opened) return null;

    return (
        <div
            ref={tooltipRef}
            className={classNames({
                cls: styles.options,
                mods: { [styles.options_viewScroll]: viewScroll },
                additional: [styles[`options_${type}`]],
            })}
        >
            <ul>
                {options.map((option) => (
                    <OptionItemDropdown
                        key={option.value}
                        type={type}
                        option={option}
                        onClickOption={onClickOption}
                        selected={selected}
                    />
                ))}
            </ul>
        </div>
    );
};
