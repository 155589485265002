// noinspection ES6PreferShortImport

import { useSearchParams } from "react-router-dom";
import { AboutRecruiting, RecruitingSteps, FAQSteps, LifehacksBcs } from "../../widget";
import { useMediaQuery } from "../../shared/hooks/useMediaQuery";
import { generateMediaQuery } from "../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../shared/constants/media";

export const AboutRecruitingPage = () => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.md));
    const [searchParams] = useSearchParams();
    const stepNumber = searchParams.get("step");

    return (
        <>
            <AboutRecruiting />
            <RecruitingSteps />
            <FAQSteps />
            {!stepNumber && !matches && <LifehacksBcs />}
        </>
    );
};
