import styles from "./Modal.module.scss";
import { classNames } from "../../../../../shared/lib/classNames";
import { type MouseEvent, type ReactNode, useCallback, useEffect } from "react";

export interface ModalProps {
    className?: string;
    children: ReactNode;
    status: boolean;
    onClose: () => void;
}

export const Modal = ({ children, ...props }: ModalProps) => {
    return props.status ? <ContentModal {...props}>{children}</ContentModal> : null;
};
const ContentModal = ({ className, children, onClose }: ModalProps) => {
    const onClickContent = (e: MouseEvent) => {
        e.stopPropagation();
    };

    const onKeyDown = useCallback((e: KeyboardEvent) => {
        if (e.key === "Escape") {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        document.body.style.overflow = "hidden";

        return () => {
            window.removeEventListener("keydown", onKeyDown);
            document.body.style.overflow = "";
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onKeyDown]);

    return (
        <div
            onClick={onClose}
            className={classNames({
                cls: styles.overlay,
                additional: [className],
            })}
        >
            <div
                onClick={onClickContent}
                className={classNames({
                    cls: styles.content,
                    additional: [className],
                })}
            >
                {children}
            </div>
        </div>
    );
};
