import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef, ReactNode } from "react";
import { ErrorValidate } from "../ErrorValidate/ErrorValidate";
import { Link, LinkProps } from "react-router-dom";

import { Loading } from "../../../shared/assets/svg";
import { classNames } from "../../lib/classNames";

import styles from "./Button.module.scss";

type ButtonTheme = "blue" | "transparent-white" | "transparent-dark" | "gray";

interface ButtonBaseProps {
    className?: string;
    theme?: ButtonTheme;
    children: ReactNode;
    loading?: boolean;
    size?: "m" | "s";
    IconBefore?: ReactNode;
    errorMessage?: string;
    isDisabled?: boolean;
}

type ButtonProps = ButtonBaseProps &
    (
        | (ButtonHTMLAttributes<HTMLButtonElement> & {
              as: "button";
          })
        | (LinkProps & {
              as: "link";
          })
    );

export const Button = forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
        className,
        theme = "blue",
        isDisabled = false,
        IconBefore,
        children,
        loading = false,
        errorMessage,
        size = "m",
        ...otherProps
    } = props;

    const cls = classNames({
        cls: styles.button,
        mods: { [styles["button_disabled"]]: isDisabled || loading },
        additional: [styles["button_" + theme], className, styles["button_" + loading], [styles["button_" + size]]],
    });

    const content = (
        <>
            {loading ? <Loading className={styles.button__loadingSvg} /> : IconBefore}
            {children}
        </>
    );

    if (otherProps.as === "link") {
        return (
            <div>
                <Link className={cls} {...otherProps}>
                    {content}
                </Link>
                <ErrorValidate errorMessage={errorMessage} />
            </div>
        );
    }

    const { type, ...propsButton } = otherProps;
    return (
        <div>
            <button type={type ?? "button"} disabled={loading || isDisabled} ref={ref} className={cls} {...propsButton}>
                {content}
            </button>
            <ErrorValidate errorMessage={errorMessage} />
        </div>
    );
});
