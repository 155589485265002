import { Link } from "react-router-dom";
import { Accordion } from "../../../../shared/ui";

import { classNames } from "../../../../shared/lib/classNames";
import { ChevronDownS } from "../../../../shared/assets/svg";
import { Item } from "../../types/item";

import styles from "./Accordions.module.scss";

interface AccordionBaseLayoutProps {
    className?: string;
    title: string;
    options: Item[] | undefined;
}
export const AccordionBaseLayout = ({ className, options, title }: AccordionBaseLayoutProps) => {
    return (
        <Accordion
            className={classNames({ cls: styles.accordionDirections, additional: [className] })}
            icon={<ChevronDownS className={styles.accordionDirections__arrowDown} />}
            title={title}
        >
            <ul className={styles.accordionDirections__list}>
                {options?.map((item) => (
                    <li key={item.label}>
                        <Link to={item.link}>
                            <span>{item.label}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </Accordion>
    );
};
