import { IconButton } from "../IconButton/IconButton";
import { CopyToClipboard } from "../CopyToClipboard/CopyToClipboard";

import { Button } from "../Button/Button";
import { ShareNoBorder } from "../../../shared/assets/svg/index";

interface ShareButtonProps {
    className?: string;
    theme?: "button" | "icon";
}
export const ShareButton = ({ className, theme = "button" }: ShareButtonProps) => {
    return (
        <CopyToClipboard copiedValue={window.location.href}>
            {theme === "icon" ? (
                <IconButton className={className} icon={"share"} text={"Поделиться"} />
            ) : (
                <Button as={"button"} className={className} theme={"transparent-dark"} IconBefore={<ShareNoBorder />}>
                    Поделиться
                </Button>
            )}
        </CopyToClipboard>
    );
};
