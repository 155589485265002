import { SwiperSlide } from "swiper/react";
import { Container, SliceBorder, Title, Carousel } from "../../../../shared/ui";
import { LifehackCard } from "../LifehackCard/LifehackCard";

import { lifehacks } from "../../consts/lifehacks";
import { AnchorsEnum } from "../../../../shared/constants/anchors";

import styles from "./LifehacksBcs.module.scss";

export const LifehacksBcs = () => {
    return (
        <Container id={AnchorsEnum.LIFEHACKS} className={styles.lifehacks}>
            <div className={styles.lifehacks__wrapper}>
                <SliceBorder
                    colorBackground={"#d8e3ed"}
                    className={styles.lifehacks__sliceBorder}
                    sizeRadius={50}
                    border={{
                        bottomRight: "inside",
                        topRight: "outside-corner-lateral",
                        bottomLeft: "outside-corner-upper",
                    }}
                >
                    <Title className={styles.lifehacks__title} level={2}>
                        Лайфхаки БКС
                    </Title>
                </SliceBorder>
                <div className={styles.lifehacks__descriptionWrapper}>
                    <p className={styles.lifehacks__description}>
                        Советы от наших коллег, как успешно пройти интервью и быстро адаптироваться в команде.
                    </p>
                </div>
            </div>
            <Carousel
                slidesOffsetBefore={116}
                navigationTheme={"white"}
                colorBackground={"#d8e3ed"}
                spaceBetween={30}
                navigation="down"
                loop={false}
            >
                {lifehacks.map((el, index) => (
                    <SwiperSlide key={index}>
                        <LifehackCard title={el.title} description={el.description} />
                    </SwiperSlide>
                ))}
            </Carousel>
        </Container>
    );
};
