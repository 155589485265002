/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    DirectionDto,
    LongDescriptionDto,
    LongNameDto,
    ShortDescriptionDto,
    UpdatePriorityDto,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Direction<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Direction
     * @name DirectionLongNamesList
     * @summary Дропдаун главной страницы и страницы направлений, список в админке
     * @request GET:/api/direction/long-names
     */
    directionLongNamesList = (params: RequestParams = {}) =>
        this.request<LongNameDto[], any>({
            path: `/api/direction/long-names`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Direction
     * @name DirectionShortDescriptionsList
     * @summary Свипер главной страницы
     * @request GET:/api/direction/short-descriptions
     */
    directionShortDescriptionsList = (params: RequestParams = {}) =>
        this.request<ShortDescriptionDto[], any>({
            path: `/api/direction/short-descriptions`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Direction
     * @name DirectionLongDescriptionsList
     * @summary Описание направлений на странице направлений
     * @request GET:/api/direction/long-descriptions
     */
    directionLongDescriptionsList = (params: RequestParams = {}) =>
        this.request<LongDescriptionDto[], any>({
            path: `/api/direction/long-descriptions`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Direction
     * @name DirectionDetail
     * @summary Направление по Id с командами для админки
     * @request GET:/api/direction/{id}
     */
    directionDetail = (id: string, params: RequestParams = {}) =>
        this.request<DirectionDto, any>({
            path: `/api/direction/${id}`,
            method: "GET",
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Direction
     * @name DirectionDelete
     * @summary Удаление направления в админке
     * @request DELETE:/api/direction/{id}
     * @secure
     */
    directionDelete = (id: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/direction/${id}`,
            method: "DELETE",
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Direction
     * @name DirectionCreate
     * @summary Создание и редактирование направления в админке
     * @request POST:/api/direction
     * @secure
     */
    directionCreate = (data: DirectionDto, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/direction`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Direction
     * @name DirectionUpdatePriorityCreate
     * @summary Обновление приоритета в админке
     * @request POST:/api/direction/update-priority
     * @secure
     */
    directionUpdatePriorityCreate = (data: UpdatePriorityDto[], params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/direction/update-priority`,
            method: "POST",
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
}
