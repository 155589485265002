export enum RoutesEnum {
    Home = "/",
    Direction = "direction",
    EarlyCareer = "early_career",
    About = "about",
    Vacancies = "vacancies",
    Vacancy = "vacancy",
    VacancyRespond = "respond",
    Blogs = "blogs",
    Article = "article",
    AcceptForm = "accept_form",
    NotFound = "*",
    AboutRecruiting = "about_recruiting",
    FormCandidate = "form",
    CandidatePage = "/candidates/:id",
    DocumentPage = "/document/:id/:id",
    SdPage = "/sd/:id",
    CandidatesSibproject = "/candidates/sibproject",
    DocumentSibproject = "/document/sibproject/:id",
    CandidatesUkfond = "/candidates/ukfond",
    DocumentUkfond = "/document/ukfond/:id",
    CandidatesBroker = "/candidates/broker",
    DocumentBroker = "/document/broker/:id",
    CandidatesIb = "/candidates/ib",
    DocumentIb = "/document/ib/:id",
    CandidatesRetail = "/candidates/retail",
    DocumentRetail = "/document/retail/:id",
    CandidatesBank = "/candidates/bank",
    DocumentBank = "/document/bank/:id",
    CandidatesWealthy = "/candidates/wealthy",
    DocumentWealthy = "/document/wealthy/:id",
    CandidatesUkbcs = "/candidates/ukbcs",
    DocumentUkbcs = "/document/ukbcs/:id",
    CandidatesPrime = "/candidates/prime",
    DocumentPrime = "/document/prime/:id",
    CandidatesForecs = "/candidates/forecs",
    DocumentForecs = "/document/forecs/:id",
    CandidatesConsalting = "/candidates/consalting",
    DocumentConsalting = "/document/consalting/:id",
    CandidatesHolding = "/candidates/holding",
    DocumentHolding = "/document/holding/:id",
    CandidatesFintech = "/candidates/fintech",
    DocumentFintech = "/document/fintech/:id",
    CandidatesTechnology = "/candidates/technology",
    DocumentTechnology = "/document/technology/:id",
    CandidatesCompany = "/candidates/company",
    DocumentCompany = "/document/company/:id",
    CandidatesConsultation = "/candidates/consultation",
    DocumentConsultation = "/document/consultation/:id",
    CandidatesInsurance = "/candidates/insurance",
    DocumentInsurance = "/document/insurance/:id",
    CandidatesLtd = "/candidates/ltd",
    DocumentLtd = "/document/ltd/:id",
    Sd = "/sd",
}

export const getRouteArticle = (id: string) => `/${RoutesEnum.Article}/${id}`;
export const getRouteVacancy = (id: string) => `/${RoutesEnum.Vacancy}/${id}`;
export const getVacancyRespond = (id: string) => `/${RoutesEnum.Vacancy}/${id}/${RoutesEnum.VacancyRespond}`;
export const getRouteDirection = () => `/${RoutesEnum.Direction}`;
export const getAcceptForm = () => `/${RoutesEnum.AcceptForm}`;
export const getRouteBlogs = () => `/${RoutesEnum.Blogs}`;
export const getRouteAboutRecruiting = () => `/${RoutesEnum.AboutRecruiting}`;
export const getRouteEarlyCareer = () => `/${RoutesEnum.EarlyCareer}`;
export const getRouteAbout = () => `/${RoutesEnum.About}`;

export const getRouteVacancies = () => `/${RoutesEnum.Vacancies}`;
