import styles from "./FormBlock.module.scss";
import { classNames } from "../../../../../shared/lib/classNames";
import { ReactNode } from "react";

interface FormBlockProps {
    className?: string;
    title?: string;
    children: ReactNode;
    spaceButtons?: boolean;
    textAlignTitle?: "start" | "center";
    description?: ReactNode | string;
}
export const FormBlock = ({
    className,
    title,
    children,
    description,
    textAlignTitle = "start",
    spaceButtons = false,
}: FormBlockProps) => {
    return (
        <div className={classNames({ cls: styles.formBlock, additional: [className] })}>
            {title && (
                <p
                    className={classNames({
                        cls: styles.formBlock__title,
                        mods: { [styles.formBlock__spaceButtons]: spaceButtons },
                        additional: [styles[`formBlock__title_${textAlignTitle}`]],
                    })}
                >
                    {title}
                </p>
            )}
            {description && <p className={styles.formBlock__description}>{description}</p>}
            <div className={styles.formBlock__container}>{children}</div>
        </div>
    );
};
