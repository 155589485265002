import { classNames } from "../../lib/classNames";
import { generationStyles } from "./lib/generationStyles";
import { Border } from "./types/types";

import styles from "./SliceBorder.module.scss";
import { CSSProperties, ReactNode } from "react";

export interface SliceBorderProps {
    children: ReactNode;
    className?: string;
    border: Border;
    sizeRadius?: number;
    colorBackground?: string;
    multiplierInside?: number;
}

export interface SvgGenerateProps {
    style: CSSProperties;
    sizeRadius: number;
}

const SvgGenerate = ({ style, sizeRadius }: SvgGenerateProps) => {
    return (
        <svg className={styles.svg} width={sizeRadius} height={sizeRadius} style={style}>
            <path
                d={`M${sizeRadius},0H0v${sizeRadius}C0,${sizeRadius / 2.2},${sizeRadius / 2.2},0,${sizeRadius},0z`}
            ></path>
        </svg>
    );
};

export const SliceBorder = ({
    children,
    className,
    border,
    multiplierInside = 1,
    colorBackground = "#FFFFFF",
    sizeRadius = 32,
}: SliceBorderProps) => {
    const stylesBorder = generationStyles(border, sizeRadius, colorBackground, multiplierInside);

    return (
        <div
            className={classNames({
                cls: styles.wrapper,
                additional: [className],
            })}
            style={stylesBorder.borderInside}
        >
            {stylesBorder.borderOutside.map((value, i) => (
                <SvgGenerate sizeRadius={sizeRadius} key={i} style={value} />
            ))}
            {children}
        </div>
    );
};
