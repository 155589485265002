export interface IOurOffices {
    id: string;
    country: string;
    countOffices: number;
    description: string | null;
    offices: string[];
}

export const ourOffices: IOurOffices[] = [
    {
        id: "1",
        country: "Россия",
        countOffices: 43,
        description: null,
        offices: [
            "Москва",
            "Новосибирск",
            "Санкт-Петербург",
            "Калининград",
            "Тула",
            "Липецк",
            "Воронеж",
            "Ростов-на-Дону",
            "Таганрог",
            "Ставрополь",
            "Краснодар",
            "Сочи",
            "Ярославль",
            "Череповец",
            "Нижний Новгород",
            "Казань",
            "Ульяновск",
            "Пенза",
            "Тольятти",
            "Самара",
            "Саратов",
            "Волгоград",
            "Астрахань",
            "Сургут",
            "Тюмень",
            "Пермь",
            "Екатеринбург",
            "Ижевск",
            "Набережные Челны",
            "Альметьевск",
            "Челябинск",
            "Уфа",
            "Стерлитамак",
            "Оренбург",
            "Омск",
            "Томск",
            "Барнаул",
            "Кемерово",
            "Новокузнецк",
            "Красноярск",
            "Иркутск",
            "Владивосток",
            "Калуга",
        ],
    },
    {
        id: "2",
        country: "Кипр",
        countOffices: 1,
        description: null,
        offices: [],
    },
    {
        id: "3",
        country: "ОАЭ",
        countOffices: 1,
        description: null,
        offices: [],
    },
];
