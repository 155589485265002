import { Link } from "react-router-dom";

import { classNames } from "../../../../lib/classNames";
import { getRouteVacancies, RoutesEnum } from "../../../../constants/routes";
import { searchQueryURL } from "../../../../lib/helpers";

import styles from "./StepLink.module.scss";

interface StepLinkProps {
    title: string;
    step: string | null;
    isActive?: boolean;
    preventScrollReset?: boolean;
}

export const StepLink = ({ title, step, isActive = false, preventScrollReset = true }: StepLinkProps) => {
    return (
        <Link
            preventScrollReset={preventScrollReset}
            className={classNames({ cls: styles.step, mods: { [styles.step_active]: isActive } })}
            to={step === "4" ? getRouteVacancies() : searchQueryURL(RoutesEnum.AboutRecruiting, { step })}
        >
            {step === "4" ? <div /> : <p className={styles.step__number}>Шаг {step}.</p>}
            <p className={styles.step__title}>{title}</p>
        </Link>
    );
};
