import { useForm } from "react-hook-form";
import { Button, TextField } from "../../../../shared/ui";

import { FormQueryVacancy } from "../../types/form";

import styles from "./QueryVacancy.module.scss";

interface SearchVacanciesProps {
    defaultValues?: FormQueryVacancy;
    onSubmit: (data: FormQueryVacancy) => void;
}
export const QueryVacancy = ({ defaultValues, onSubmit }: SearchVacanciesProps) => {
    const { register, handleSubmit } = useForm({ defaultValues });

    return (
        <form className={styles.queryVacancy} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.queryVacancy__searchWrapper}>
                <TextField
                    {...register("Name")}
                    className={styles.queryVacancy__input}
                    placeholder={"Введите название вакансии"}
                />
                <Button type={"submit"} as={"button"} theme={"blue"}>
                    Найти
                </Button>
            </div>
        </form>
    );
};
