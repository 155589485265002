import { classNames } from "../../../../../shared/lib/classNames";
import { CloseForm, Minus, Plus } from "../../../../../shared/assets/svg/index";

import styles from "./ButtonIcon.module.scss";
import { ButtonHTMLAttributes, ReactNode } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    icon: "minus" | "plus" | "delete";
}
export const ButtonIcon = (props: ButtonProps) => {
    const { className, icon, type = "button", ...otherProps } = props;

    let svg: ReactNode;

    switch (icon) {
        case "minus": {
            svg = <Minus />;
            break;
        }
        case "plus": {
            svg = <Plus />;
            break;
        }
        case "delete": {
            svg = <CloseForm />;
            break;
        }
    }

    return (
        <button
            type={type}
            className={classNames({
                cls: styles.buttonIcon,
                additional: [className, styles[`buttonIcon_${icon}`]],
            })}
            {...otherProps}
        >
            {svg}
        </button>
    );
};
