import React, { ReactElement, useMemo, useState } from "react";
import { Container, IconButton, Skeleton, SliceBorder, Title } from "../../shared/ui";
import { NewsletterModal } from "../../features/NewsletterModal";

import { LongDescriptionDto } from "../../shared/ApiContract/data-contracts";
import { classNames } from "../../shared/lib/classNames";
import { imgDirection } from "../../shared/assets/img";
import { useMediaQuery } from "../../shared/hooks/useMediaQuery";
import { useGetDirectionsLongListQuery } from "../../entities/Direction";
import { generateMediaQuery } from "../../shared/lib/helpers";
import { MediaQueryProperty, MediaSizeEnum } from "../../shared/constants/media";

import styles from "./DirectionMain.module.scss";
import { directionBanner } from "./DirectionBanner";

interface DirectionMainProps {
    className?: string;
    directionId: string | null | undefined;
}

export const DirectionMain = ({ className, directionId }: DirectionMainProps) => {
    const { data: directionMainList, isSuccess, isLoading } = useGetDirectionsLongListQuery();

    const directionMain = useMemo(() => {
        return directionMainList?.find((direct) => direct.id === directionId) ?? null;
    }, [directionId, directionMainList]);

    let content: ReactElement | null = null;

    if (isLoading) {
        content = <DirectionMainSkeleton />;
    }

    if (isSuccess) {
        content = <DirectionMainContent directionMain={directionMain} />;
    }

    return (
        <Container className={classNames({ cls: styles.directionMain, additional: [className] })}>{content}</Container>
    );
};

const DirectionMainContent = ({ directionMain }: { directionMain: LongDescriptionDto | null }) => {
    const matches = useMediaQuery(generateMediaQuery(MediaQueryProperty.MaxWidth, MediaSizeEnum.lg));
    const [openedModal, setOpenedModal] = useState<boolean>(false);

    return (
        directionMain && (
            <>
                {matches && (
                    <Title className={styles.directionMain__title} level={3}>
                        {directionMain.shortName}
                    </Title>
                )}
                <div className={styles.directionMain__content}>
                    <div className={styles.directionMain__textWrapper}>
                        {!matches && (
                            <SliceBorder
                                sizeRadius={50}
                                border={{
                                    bottomRight: "inside",
                                    topRight: "outside-corner-lateral",
                                    bottomLeft: "outside-corner-upper",
                                }}
                            >
                                <Title className={styles.directionMain__title} level={3}>
                                    {directionMain.shortName}
                                </Title>
                            </SliceBorder>
                        )}
                        <div className={styles.directionMain__descriptionWrapper}>
                            <p className={styles.directionMain__descriptionShort}>
                                {directionMain.titleLongDescription}
                            </p>
                            {/* <p className={styles.directionMain__description}>{directionMain.longDescription}</p> */}
                        </div>
                    </div>
                    <div className={styles.directionMain__imageWrapper}>
                        <img
                            src={directionMain.shortName ? directionBanner[directionMain.shortName] : imgDirection}
                            className={styles.directionMain__image}
                            alt={"imgPoster"}
                        />
                    </div>

                    {!matches && (
                        <SliceBorder
                            sizeRadius={28}
                            className={styles.directionMain__subscribeWrapper}
                            multiplierInside={1.2}
                            border={{
                                topRight: "inside",
                                topLeft: "inside",
                                bottomLeft: "outside-corner-lateral",
                                bottomRight: "outside-corner-lateral",
                            }}
                        >
                            <IconButton
                                icon={"subscribe"}
                                text={"Узнавать о новых вакансиях"}
                                textPosition={"left"}
                                onClick={() => setOpenedModal(true)}
                            />
                        </SliceBorder>
                    )}
                </div>
                {matches && (
                    <IconButton
                        className={styles.directionMain__subscribe}
                        icon={"subscribe"}
                        text={"Узнавать о новых вакансиях"}
                        textPosition={"left"}
                        onClick={() => setOpenedModal(true)}
                    />
                )}
                <NewsletterModal isOpen={openedModal} onClose={() => setOpenedModal(false)} />
            </>
        )
    );
};

const DirectionMainSkeleton = () => {
    return (
        <Skeleton className={classNames({ cls: styles.directionMain__infoWrapper, additional: [styles.skeleton] })} />
    );
};
