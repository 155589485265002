import { Link } from "react-router-dom";
import { Pentagon } from "../../../../shared/assets/svg";
import declOfNum from "../../../../shared/lib/declOfNum";

import styles from "./StickerVacancy.module.scss";
import { getRouteVacancies } from "../../../../shared/constants/routes";

interface StickerVacancyProps {
    className?: string;
    countVacancy?: number;
}

export const StickerVacancy = ({ countVacancy = 0, className }: StickerVacancyProps) => {
    return (
        <Link to={getRouteVacancies()} className={className}>
            <div className={styles.stickerVacancy}>
                <Pentagon />
                <p>
                    Сейчас
                    <br /> <span>{countVacancy}</span>
                    <br />
                    {declOfNum(countVacancy, [" вакансия", " вакансии", " вакансий"])}
                </p>
            </div>
        </Link>
    );
};
