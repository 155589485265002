import {
    imgHistory1995,
    imgHistory1998,
    imgHistory2000,
    imgHistory2006,
    imgHistory2013,
    imgHistory2019,
    imgHistory2020,
    imgHistory2021,
    imgHistory2022,
    imgHistory2023,
} from "../../../shared/assets/img";

export const historyCompany = [
    {
        id: "0",
        year: "1995",
        image: imgHistory1995,
        description: "Основание БКС в Новосибирске",
    },
    {
        id: "1",
        year: "1998",
        image: imgHistory1998,
        description: "БКС становится членом секции фондового рынка ММВБ и участником РТС",
    },
    {
        id: "2",
        year: "2000",
        image: imgHistory2000,
        description: "Открыт первый офис БКС в Москве\n" + "\n" + "Появилась услуга интернет-трейдинга",
    },
    {
        id: "3",
        year: "2006",
        image: imgHistory2006,
        description:
            "БКС создает собственный банк\n" + "\n" + "БКС начинает совершать сделки\n" + "на международных рынках",
    },
    {
        id: "4",
        year: "2013",
        image: imgHistory2013,
        description:
            "BCS Prime становится членом Лондонской фондовой биржи\n" +
            "\n" +
            "БКС — абсолютный лидер брокерского рынка России по версии РБК",
    },
    {
        id: "6",
        year: "2019",
        image: imgHistory2019,
        description:
            "БКС первым среди брокеров запустил услугу пополнения брокерского счета через Систему быстрых платежей\n" +
            "\n" +
            "Запуск Fintarget – маркетплейса\n" +
            "с инвестиционными стратегиями»\n" +
            "\n" +
            "Создан исследовательский финтех-центр\n" +
            "в партнерстве со «Сколково»",
    },
    {
        id: "7",
        year: "2020",
        image: imgHistory2020,
        description:
            "БКС победил в конкурсе «Элита фондового рынка»\n" +
            "в номинациях «Компания рынка акций»\n" +
            "и «Новый финансовый инструмент»\n" +
            "\n" +
            "БКС запустил первые на рынке именные Сберсертификаты",
    },
    {
        id: "8",
        year: "2021",
        image: imgHistory2021,
        description:
            "Приложение БКС Мир инвестиций заняло лидерские позиции в рейтинге цифровых сервисов Markswebb\n" +
            "\n" +
            "Веб-кабинет БКС Мир инвестиций признан лучшим\n" +
            "в премии Tagline Awards 2021\n" +
            "\n" +
            "БКС запустил 1-й биржевой паевый инвестиционный фонд (БПИФ) рублевых корпоративных облигаций",
    },
    {
        id: "9",
        year: "2022",
        image: imgHistory2022,
        description:
            "БКС лауреат премии «Финансовая элита России»\n" +
            "в номинации «Гран-при: Брокерская компания года»\n" +
            "\n" +
            "Премия «Investfunds Awards» в номинации «Лучшая брокерская компания для розничных инвесторов»\n" +
            "\n" +
            "Премия «Investment Leaders Award» в номинации «Лучшая аналитика для инвесторов»\n" +
            "\n" +
            "БКС первой предоставила доступ к торгам ценными бумагами гонконгских и китайских компаний\n" +
            "на Гонконгской бирже (HKEX)",
    },
    {
        id: "10",
        year: "2023",
        title: "Основание БКС в Новосибирске",
        image: imgHistory2023,
        description:
            "«Лучшая брокерская компания для розничных инвесторов» «Advisory — лучшая услуга по управлению активами для состоятельных клиентов»\n" +
            "\n" +
            "«Новые горизонты: инвестиционные решения в юанях» и «Инвестиционная стратегия года: «Лёгкий старт»\n" +
            "\n" +
            "«Инвестиционная компания года»\n" +
            "\n" +
            "БКС победил в конкурсе «Элита фондового рынка» в номинации «Компания рынка акций»\n" +
            "\n" +
            "«Лучший брокер для розничного инвестора»\n" +
            "и «Лучшая УК для розничного инвестора»",
    },
];
