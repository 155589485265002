import React from "react";
import { Bulb, Height, Racket, Team } from "../../shared/assets/svg";

import styles from "./VacancyMain.module.scss";

export const VacancyFooter = () => {
    return (
        <div className={styles.footer}>
            <h3>Преимущества работы в БКС</h3>
            <div className={styles.footer__list}>
                <div className={styles.footer__list_item}>
                    <Height />
                    <p>
                        Компания
                        <br />
                        динамичного роста
                    </p>
                </div>
                <div className={styles.footer__list_item}>
                    <Bulb />
                    <p>
                        Амбициозные
                        <br />и крутые задачи
                    </p>
                </div>
                <div className={styles.footer__list_item}>
                    <Racket />
                    <p>
                        Яркая корпоративная
                        <br />
                        жизнь
                    </p>
                </div>
                <div className={styles.footer__list_item}>
                    <Team />
                    <p>
                        Работа
                        <br />в команде
                        <br />
                        профессионалов
                    </p>
                </div>
            </div>
        </div>
    );
};
