import { Outlet, ScrollRestoration } from "react-router-dom";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

import styles from "./Layout.module.scss";
import { classNames } from "../../../../shared/lib/classNames";
import { Theme } from "../../types/theme";

interface LayoutProps {
    theme?: Theme;
}

export const Layout = ({ theme = "standard" }: LayoutProps) => {
    return (
        <div className={classNames({ cls: styles.main, additional: [styles[`main_${theme}`]] })}>
            <Header theme={theme} />
            <main>
                <Outlet />
                <ScrollRestoration />
            </main>
            <Footer theme={theme} />
        </div>
    );
};
