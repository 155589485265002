import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";

import { router } from "./routers/configRoutes";
import { StoreProvider } from "./store/StoreProvider";

const App = () => {
    return (
        <StoreProvider>
            <Suspense>
                <RouterProvider router={router} />
            </Suspense>
        </StoreProvider>
    );
};

export default App;
