export const stages = [
    {
        stage: "1",
        title: "Мы за клиента",
        description:
            "Принимая ключевые решения в бизнесе, мы ориентируемся на цели наших клиентов, поэтому они всегда получают то, что им действительно нужно.",
    },
    {
        stage: "2",
        title: "Работаем в команде",
        description: "От того, насколько эффективно мы взаимодействуем между собой, зависит наш общий результат.",
    },
    {
        stage: "3",
        title: "Создаем улучшения",
        description:
            "Мы меняемся, чтобы добиваться большего. Постоянно улучшаем и создаем новые продукты и сервисы. Не боимся экспериментировать, и это делает нас лидерами на рынке инвестиций.",
    },
    {
        stage: "4",
        title: "Отвечаем за результат",
        description:
            "Каждый в команде понимает, что его работа влияет на общий результат. Мы действуем рационально, системно и последовательно, чтобы глобальная цель компании была достигнута.",
    },
];
