import { classNames } from "../../lib/classNames";

import styles from "./ErrorValidate.module.scss";

interface ErrorValidateProps {
    className?: string;
    errorMessage?: string;
}
export const ErrorValidate = ({ className, errorMessage }: ErrorValidateProps) => {
    if (!errorMessage) return null;

    return (
        <div className={classNames({ cls: styles.wrapper, additional: [className] })} style={{ position: "relative" }}>
            <p className={styles.error}>{errorMessage}</p>
        </div>
    );
};
