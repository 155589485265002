/**
 * Возвращает склонение слова в зависимости от числительного
 * titles - массив заголовков [' вакансия', ' вакансии', ' вакансий']
 */
export default function declOfNum(number: number, titles: string[]): string {
    if (Number.isNaN(number)) return "";
    if (!Number.isInteger(number)) return titles[1];

    if (number < 0) {
        number = number * -1;
    }

    let cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
}

/**
 * Возвращает склонение слова в родительном падеже, в зависимости от числительного
 * titles - массив заголовков [' дня', ' дней']
 */
export function declOfNum2case(number: number, titles: string[]): string {
    if (Number.isNaN(number)) return "";
    if (!Number.isInteger(number)) return titles[0];

    return number % 10 === 1 && number % 100 !== 11 ? titles[0] : titles[1];
}
